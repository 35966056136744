import * as Actions from './product-conversion.actions';
import { IProductConversionState } from './product-conversion.model';

export const productConversionState: IProductConversionState = {
  addProductConversionLoaded: false,
  addProductConversionLoading: false,
  editProductConversionLoaded: false,
  editProductConversionLoading: false,
  productConversionLoaded: false,
  productConversionLoading: false,
  productConversionData: [],
};

export function productConversionReducer(
  state: any = productConversionState,
  action: Actions.ProductConversionActions,
): any {
  switch (action.type) {
    case Actions.PRODUCT_CONVERSION_DATA_LOADING:
      return {
        ...state,
        addProductConversionLoaded: false,
        addProductConversionLoading: true,
      };
    case Actions.PRODUCT_CONVERSION_DATA_LOADED:
      return {
        ...state,
        addProductConversionLoaded: true,
        addProductConversionLoading: false,
        productConversionData: action.data,
      };
    case Actions.ADD_PRODUCT_CONVERSION_LOADING:
      return {
        ...state,
        addProductConversionLoaded: false,
        addProductConversionLoading: true,
      };
    case Actions.ADD_PRODUCT_CONVERSION_LOADED:
      return {
        ...state,
        addProductConversionLoaded: true,
        addProductConversionLoading: false,
      };
    case Actions.EDIT_PRODUCT_CONVERSION_LOADING:
      return {
        ...state,
        editProductConversionLoaded: false,
        editProductConversionLoading: true,
      };
    case Actions.EDIT_PRODUCT_CONVERSION_LOADED:
      return {
        ...state,
        editProductConversionLoaded: true,
        editProductConversionLoading: false,
      };
    default:
      return state;
  }
}
