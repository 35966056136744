import { Action } from '@ngrx/store';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IUnitConversion, IUnitConversionBulk } from './product-conversion.model';

export const PRODUCT_CONVERSION_DATA_LOADING = '[PRODUCT_CONVERSION] PRODUCT CONVERSION DATA LOADING';
export const PRODUCT_CONVERSION_DATA_LOADED = '[PRODUCT_CONVERSION] PRODUCT CONVERSION DATA LOADED';
export const ADD_PRODUCT_CONVERSION_LOADING = '[PRODUCT_CONVERSION] ADD PRODUCT CONVERSION LOADING';
export const ADD_PRODUCT_CONVERSION_LOADED = '[PRODUCT_CONVERSION] ADD PRODUCT CONVERSION LOADED';
export const EDIT_PRODUCT_CONVERSION_LOADING = '[PRODUCT_CONVERSION] EDIT PRODUCT CONVERSION LOADING';
export const EDIT_PRODUCT_CONVERSION_LOADED = '[PRODUCT_CONVERSION] EDIT PRODUCT CONVERSION LOADED';
export const FETCH_ERROR = '[PRODUCT_CONVERSION] FETCH ERROR';

export class ProductConversionDataLoading implements Action {
  readonly type = PRODUCT_CONVERSION_DATA_LOADING;

  constructor(public productId: number) {}
}

export class ProductConversionDataLoaded implements Action {
  readonly type = PRODUCT_CONVERSION_DATA_LOADED;

  constructor(public data: IUnitConversion[]) {}
}

export class AddProductConversionLoading implements Action {
  readonly type = ADD_PRODUCT_CONVERSION_LOADING;

  constructor(public payload: IUnitConversion[]) {}
}

export class AddProductConversionLoaded implements Action {
  readonly type = ADD_PRODUCT_CONVERSION_LOADED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class EditProductConversionLoading implements Action {
  readonly type = EDIT_PRODUCT_CONVERSION_LOADING;

  constructor(public payload: IUnitConversionBulk[]) {}
}

export class EditProductConversionLoaded implements Action {
  readonly type = EDIT_PRODUCT_CONVERSION_LOADED;

  constructor(public response: GetManyResponseInterface<any>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ProductConversionActions =
  | ProductConversionDataLoading
  | ProductConversionDataLoaded
  | AddProductConversionLoading
  | AddProductConversionLoaded
  | EditProductConversionLoading
  | EditProductConversionLoaded
  | FetchError;
