import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { SchedulerComponent } from '../../scheduler/scheduler.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivityHistoryState } from '../../../store/activity-history/activity-history.reducer';
import { OeeAppState } from '../../../store/oee.reducer';
import {
  EZoomEvents,
  FixedAndCurrentVersionInterface,
  OverlappedActivitiesInterface,
  SelectedActivityEventDataInterface,
} from '../../../store/activity-history/activity-history.model';
import { mysqlTimestampFormatZeroSecond } from '../../helper/date';
import * as ActivityHistoryActions from '../../../store/activity-history/activity-history.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { activityColors } from '../../helper/app-helper';
import * as AppActions from '../../../store/app/actions';
import { HelperService } from '../../service/helper.service';
import { ELanguages } from '../../../../constants';
import { ComponentColors, CustomColorParameters } from '../../service/color/color.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { ColorService } from '../../service/color/color.service';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import * as _ from 'lodash';
import { IPredictedWorkOrderForSplit } from '../select-work-order/select-work-order.model';
import { MonitoringService } from '../../service/error-service/monitoring.service';

@OnDestroyDecorator
@Component({
  selector: 'app-overlapping-activity-modal',
  templateUrl: './overlapping-activity-modal.component.html',
  styleUrls: ['./overlapping-activity-modal.component.scss', '../../../../scss/bryntum.scss'],
})
export class OverlappingActivityModalComponent implements OnInit, OnDestroy {
  @ViewChild(SchedulerComponent, { static: false }) scheduler: SchedulerComponent;
  @Input() customColorParams: CustomColorParameters;
  @Input() closeAllModals: boolean = true;
  @Output() sendOverlapActivityData: EventEmitter<string> = new EventEmitter<string>();
  @Output() backToActivityEdit: EventEmitter<void> = new EventEmitter<void>();
  @Input() mainActivity: any;
  @Output() preventToModalOpening = new EventEmitter<boolean>();
  private readonly storeSubscriptions: Subscription[] = [];
  protected readonly EZoomEvents = EZoomEvents;
  private mainActivityIndexInFixedVersion: number;
  private mainActivityIndexInCurrentVersion: number;
  private overlappedOngoingActivityDataLoaded$: boolean = true;
  private overlappedActivityData$: OverlappedActivitiesInterface;
  public fixedVersion$: FixedAndCurrentVersionInterface[] = [];
  public currentVersion$: FixedAndCurrentVersionInterface[] = [];
  public mainActivity$: SelectedActivityEventDataInterface;
  public overlappingActivityInformationText: string = '';
  public hasFixedVersion: boolean = false;
  public startDateOverlap: Date;
  public endDateOverlap: Date;
  public viewPreset: string = 'minuteAndHour';
  public timeRangesObject: {}[] = [];
  public activitiesOfCurrentVersion: {}[] = [];
  public activitiesOfFixedVersion: {}[] = [];
  public resources = [
    {
      id: 1,
    },
  ];
  public timeRanges: object | boolean = {
    enableResizing: false,
    showHeaderElements: false,
  };
  public maxZoomLevel: number = 22;
  public minZoomLevel: number = 10;
  public currentZoomLevel: number = 17;
  public lineId$: number;
  private language$: ELanguages;

  eventRenderer = ({ eventRecord, renderData }) => {
    const iconClass =
      eventRecord.data['isFinalizedIndicator'] !== undefined && eventRecord.data['isFinalizedIndicator']
        ? 'fas fa-lock f-14'
        : 'd-none';

    if (eventRecord.data['isSentRecordIndicator'] !== undefined && eventRecord.data['isSentRecordIndicator']) {
      renderData.style += ' outline: 2px dashed #fff;';
      renderData.style += ' outline-offset: -3.5px; ';
    } else if (
      (eventRecord.data['isOngoingIndicator'] !== undefined && eventRecord.data['isOngoingIndicator']) ||
      (eventRecord.data['isFinalizedIndicator'] !== undefined && eventRecord.data['isFinalizedIndicator'])
    ) {
      renderData.style += ` background: repeating-linear-gradient(-55deg, ${eventRecord.data['eventColor']}, ${eventRecord.data['eventColor']} 10px, #fff 10px, #fff 13px) `;
    } else if (eventRecord.data['missingDataIndicator'] !== undefined && eventRecord.data['missingDataIndicator']) {
      renderData.style += ' border-color: #fefe05 !important; ';
      renderData.style += ' border-width: 5px; ';
      renderData.style += ' background-size:  20px 20px !important; ';
    }

    return `<div>
                <i class="${iconClass}"></i>
                <span style="cursor-pointer: none; color: #000000 !important; opacity: .8; font-size: 16px; font-weight: 600;">${eventRecord.data['name']}</span>
            </div>`;
  };

  constructor(
    private readonly translate: TranslateService,
    public readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
    private readonly ngbModal: NgbModal,
    private colorService: ColorService,
    private readonly monitoringService: MonitoringService,
  ) {}

  public determineTextByActivityOverlaps(): void {
    let hasEditableActivity: FixedAndCurrentVersionInterface;
    let isOverlappedWithFinalizedWorkOrder: FixedAndCurrentVersionInterface;
    let isOverlappedWithOngoingActivity: FixedAndCurrentVersionInterface;

    hasEditableActivity = this.currentVersion$.find(
      (activity) => activity.canBeOverwritten && !activity.isSentRecord && !activity.isOngoing,
    );
    isOverlappedWithFinalizedWorkOrder = this.currentVersion$.find(
      (activity) => !activity.canBeOverwritten && !activity.isOngoing && !activity.isSentRecord,
    );
    isOverlappedWithOngoingActivity = this.currentVersion$.find(
      (activity) => !activity.canBeOverwritten && !activity.isSentRecord && activity.isOngoing,
    );

    const parameterOfTranslation = {
      start: this.helperService.setUserDateTimeFormat(
        this.currentVersion$[this.mainActivityIndexInCurrentVersion].start,
        true, true, true
      ),
      end: this.helperService.setUserDateTimeFormat(
        this.currentVersion$[this.mainActivityIndexInCurrentVersion].end,
        true, true, true
      ),
      newGoodStart: this.helperService.setUserDateTimeFormat(
        this.fixedVersion$[this.mainActivityIndexInFixedVersion].start,
        true, true, true
      ),
      newGoodEnd: this.helperService.setUserDateTimeFormat(
        this.fixedVersion$[this.mainActivityIndexInFixedVersion].end,
        true, true, true
      ),
    };

    if (isOverlappedWithFinalizedWorkOrder && isOverlappedWithOngoingActivity) {
      this.overlappingActivityInformationText = this.translate.instant(
        'overlappingActivity.form.overlapWithOngoingActivityAndFinalizedWorkOrder',
        parameterOfTranslation,
      );
    } else if (isOverlappedWithFinalizedWorkOrder) {
      parameterOfTranslation.end = this.helperService.setUserDateTimeFormat(
        this.fixedVersion$[this.mainActivityIndexInCurrentVersion].start,
        true, true, true
      );

      this.overlappingActivityInformationText = this.translate.instant(
        'overlappingActivity.form.overlapActivityFinalizedWorkOrder',
        parameterOfTranslation,
      );
    } else if (isOverlappedWithOngoingActivity) {
      parameterOfTranslation.start = this.helperService.setUserDateTimeFormat(
        isOverlappedWithOngoingActivity.start,
        true, true, true
      );

      this.overlappingActivityInformationText = this.translate.instant(
        'overlappingActivity.form.overlapActivityWithOngoingActivity',
        parameterOfTranslation,
      );
    } else if (hasEditableActivity) {
      this.overlappingActivityInformationText = this.translate.instant(
        'overlappingActivity.form.overlapActivityQuestion'
      );
    }
  }

  private prepareActivities(
    currentOrFixedVersions: FixedAndCurrentVersionInterface[],
    editedCurrentOrFixedVersions: {}[],
    indexOfMainActivity: number,
    plannedDownTimeActivityColors?: ComponentColors,
  ): void {
    if (indexOfMainActivity !== -1) {
      currentOrFixedVersions[indexOfMainActivity].activity = {
        id: this.mainActivity$.activity.id,
        name: this.mainActivity$.activity.name,
        activityType: this.mainActivity$.activity.activityType,
      };
      const woNumber: string = !_.isNil(this.mainActivity$?.predictedWorkOrderForSplit)
        ? this.mainActivity$?.predictedWorkOrderForSplit?.predictedWorkOrderName
        : this.mainActivity$?.workOrder
        ? this.mainActivity$.workOrder.woNumber
        : null;
      currentOrFixedVersions[indexOfMainActivity].workOrder = this.mainActivity$.workOrder
        ? {
            id: this.mainActivity$.workOrder.id,
            woNumber,
          }
        : null;
    }

    if (currentOrFixedVersions.length) {
      for (const currentOrFixedVersion of currentOrFixedVersions) {
        const worKOrderIsFinalized =
          !currentOrFixedVersion.canBeOverwritten &&
          !currentOrFixedVersion.isSentRecord &&
          !currentOrFixedVersion.isOngoing;

        const workOrderText =
          currentOrFixedVersion.workOrder && currentOrFixedVersion.workOrder.woNumber
            ? currentOrFixedVersion.workOrder.woNumber
            : currentOrFixedVersion?.task?.meta?.withoutWorkOrder
            ? null
            : this.translate.instant('activityHistory.form.missingWorkOrder');

        editedCurrentOrFixedVersions.push({
          activityId: currentOrFixedVersion.activityId,
          startDate: moment(this.helperService.convertFromISOFormatToGivenTimezone(currentOrFixedVersion.start)).toDate(),
          startDateString: this.helperService.convertFromISOFormatToGivenTimezone(currentOrFixedVersion.start),
          endDate: currentOrFixedVersion.isOngoing
            ? moment().toDate()
            : moment(this.helperService.convertFromISOFormatToGivenTimezone(currentOrFixedVersion.end)).toDate(),
          endDateString: this.helperService.convertFromISOFormatToGivenTimezone(currentOrFixedVersion.end),
          activityTypeSpecific: currentOrFixedVersion.activity.activityType,
          isOngoingIndicator: currentOrFixedVersion.isOngoing,
          isFinalizedIndicator: worKOrderIsFinalized,
          isSentRecordIndicator: currentOrFixedVersion.isSentRecord,
          missingDataIndicator: false,
          resourceId: 1,
          eventColor:
            (currentOrFixedVersion.activity.activityType === ActivityTypes.DOWN_TIME_PLANNED
              ? plannedDownTimeActivityColors?.background
              : null) ?? activityColors(currentOrFixedVersion.activity.activityType),
          name:
            workOrderText === null
              ? '-'
              : `${workOrderText} - ${currentOrFixedVersion.activity.name}`,
        });
      }
    }
  }

  public acceptFixedVersion(buttonEvent: boolean = false): void {
    if (buttonEvent) {
      this.monitoringService.logEvent(
        { name: 'Overlapping Activities Modal' },
        { action: 'Apply Fix Version Button Clicked', lineId: this.lineId$ },
      );
    }

    this.store.dispatch(new AppActions.ShowLoader());
    const activityData = [];
    if (this.overlappedActivityData$.activityHistoriesToCreate.length) {
      for (const activity of this.overlappedActivityData$.activityHistoriesToCreate) {
        activityData.push({
          siteId: activity.siteId,
          lineId: activity.lineId,
          start: activity.start,
          end: activity.end,
          activityId: activity.activityId,
          taskId: activity.taskId,
          workOrderId: activity.workOrderId,
          description: activity.description,
          crewSize: activity.crewSize,
          userId: activity.userId,
          phaseId: activity.phaseId,
          comments: activity.comments,
          workOrderToSplitInfo: this.checkMainDataAndGetForWorkOrderSplit(activity),
        });
      }
    }

    if (this.overlappedActivityData$.activityHistoriesToUpdate.length) {
      for (const activity of this.overlappedActivityData$.activityHistoriesToUpdate) {
        activityData.push({
          id: activity.id,
          start: activity.start,
          end: activity.end,
          siteId: activity.siteId,
          lineId: activity.lineId,
          activityId: activity.activityId,
          taskId: activity.taskId,
          workOrderId: activity.workOrderId,
          description: activity.description,
          crewSize: activity.crewSize,
          userId: activity.userId,
          phaseId: activity.phaseId,
          workOrderToSplitInfo: this.checkMainDataAndGetForWorkOrderSplit(activity),
        });
      }
    }

    if (this.overlappedActivityData$.activityHistoriesToDelete.length) {
      for (const id of this.overlappedActivityData$.activityHistoriesToDelete) {
        activityData.push({
          id: Number(id),
          forDelete: true,
        });
      }
    }

    if (this.overlappedOngoingActivityDataLoaded$) {
      const ongoingActivityStartDate = this.fixedVersion$[this.mainActivityIndexInFixedVersion].start;
      this.acceptOverlap(false, ongoingActivityStartDate);
    }

    if (activityData.length) {
      this.store.dispatch(
        new ActivityHistoryActions.AcceptFixedVersionForActivityOverlap({
          activityHistories: activityData,
        }),
      );
    }

    this.preventToModalOpening.emit(false);

    if (this.closeAllModals) {
      this.closeAllModal();
    }
  }

  private checkMainDataAndGetForWorkOrderSplit(
    activity: SelectedActivityEventDataInterface,
  ): IPredictedWorkOrderForSplit | null {
    const { predictedWorkOrderForSplit } = this.mainActivity$ || {};

    if (predictedWorkOrderForSplit?.predictedWorkOrderParentId === activity?.workOrderId) {
      return predictedWorkOrderForSplit;
    }

    return null;
  }

  public closeAllModal(): void {
    this.ngbModal.dismissAll();
    this.store.dispatch(new ActivityHistoryActions.SetOverlapActivityParametersDefault());
  }

  public backToEdit(): void {
    this.backToActivityEdit.emit();
  }

  public acceptOverlap(buttonEvent: boolean = false, ongoingActivityStartDate: string = ''): void {
    if (buttonEvent) {
      this.monitoringService.logEvent(
        { name: 'Overlapping Activities Modal' },
        { action: 'Accept Override Button Clicked', lineId: this.lineId$ },
      );
    }

    this.sendOverlapActivityData.emit(ongoingActivityStartDate);

    if (this.closeAllModals) {
      this.closeAllModal();
    }

    this.backToEdit();
  }

  ngOnInit(): void {
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.language$ = state.language as ELanguages;
      }),
      this.store.select('activityHistoryStore').subscribe((state: ActivityHistoryState) => {
       this.lineId$ =
          _.get(state, 'overlappedActivityData.currentVersion.0.lineId') ||
          _.get(state, 'overlappedActivityData.currentVersion.1.lineId');
        this.overlappedOngoingActivityDataLoaded$ = state.overlapOngoingActivityDataLoaded;
        this.overlappedActivityData$ = HelperService.cloneDeep(state.overlappedActivityData);
        this.mainActivity$ = HelperService.cloneDeep(state.mainActivity);
        this.hasFixedVersion = state.overlappedActivityData.hasAnyChange && state.overlappedActivityData.isValid;
        this.fixedVersion$ = HelperService.cloneDeep(state.overlappedActivityData.fixedVersion);
        this.currentVersion$ = HelperService.cloneDeep(state.overlappedActivityData.currentVersion);

        const startDates = HelperService.cloneDeep(state.overlappedActivityData.currentVersion).map((currentVersion) =>
          moment(this.helperService.convertFromISOFormatToGivenTimezone(currentVersion.start)).toDate(),
        );
        const endDates = HelperService.cloneDeep(state.overlappedActivityData.currentVersion).map((currentVersion) =>
          currentVersion.end === '' ? null : moment(this.helperService.convertFromISOFormatToGivenTimezone(currentVersion.end)).toDate(),
        );

        this.startDateOverlap = moment(Math.min.apply(null, startDates)).toDate();
        this.endDateOverlap = moment(Math.max.apply(null, endDates)).toDate();

        this.mainActivityIndexInFixedVersion = HelperService.cloneDeep(state.overlappedActivityData.fixedVersion).findIndex(
          (mainActivity) => mainActivity.isSentRecord,
        );
        this.mainActivityIndexInCurrentVersion = HelperService.cloneDeep(state.overlappedActivityData.currentVersion).findIndex(
          (mainActivity) => mainActivity.isSentRecord,
        );

        const mainActivityCurrentStart =
          HelperService.cloneDeep(state.overlappedActivityData.currentVersion[this.mainActivityIndexInCurrentVersion]).start;
        const mainActivityCurrentEnd =
          HelperService.cloneDeep(state.overlappedActivityData.currentVersion[this.mainActivityIndexInCurrentVersion]).end;

        this.overlappingActivityInformationText = this.translate.instant('overlappingActivity.form.overlapWarning', {
          start: this.helperService.setUserDateTimeFormat(
            this.helperService.convertFromISOFormatToGivenTimezone(mainActivityCurrentStart),
            true,
            true,
            true,
          ),
          end: this.helperService.setUserDateTimeFormat(
            this.helperService.convertFromISOFormatToGivenTimezone(mainActivityCurrentEnd),
            true,
            true,
            true,
          ),
        });

        const plannedDownTimeActivityColors: ComponentColors | undefined = this.customColorParams
          ? this.colorService.pickComponentColors('plannedDownTimeActivityGantt', this.customColorParams)
          : undefined;

        this.prepareActivities(
          HelperService.cloneDeep(state.overlappedActivityData.currentVersion),
          this.activitiesOfCurrentVersion,
          this.mainActivityIndexInCurrentVersion,
          plannedDownTimeActivityColors,
        );

        if (this.hasFixedVersion) {
          this.prepareActivities(
            HelperService.cloneDeep(state.overlappedActivityData.fixedVersion),
            this.activitiesOfFixedVersion,
            this.mainActivityIndexInFixedVersion,
            plannedDownTimeActivityColors,
          );
          this.determineTextByActivityOverlaps();
        }
      }),
    );
  }

  public zoomInAndOut(zoom: EZoomEvents): void {
    if (zoom === EZoomEvents.ZOOM_IN) {
      this.currentZoomLevel = this.currentZoomLevel + 1;
    } else {
      this.currentZoomLevel = this.currentZoomLevel - 1;
    }
    this.scheduler.schedulerInstance.zoomToLevel(this.currentZoomLevel);
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
