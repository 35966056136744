import { GetFeedTokenInterface, NotificationList } from '../../shared/service/notification/notification.service';
import { GetCurrentUserResponseClientInterface, GetCurrentUserResponseDefaultSiteInterface } from '../app/model';
import { TDecimalSeparator, TThousandSeparator } from '../../../constants.model';

export const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const;

export enum EWeekday {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export interface FirebaseCredentials {
  collectionPath: string;
  firebase: IFirebaseConfig;
  user: {
    email: string;
    password: string;
  };
}

export interface IFirebaseConfig {
  apiKey: string;
  appId: string;
  authDomain: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
  vapidKey: string;
  customDomainUrl: string | null;
}

export interface IUserMenuPermissions {
  activeMenuItemIds: number[];
  landingMenuItemId?: number;
  landingPageUrl?: string;
}

export const defaultFirstWeekday: EWeekday = EWeekday.Monday;

// TODO client's number code is added to the store temporarily
export interface User {
  firstWeekday?: EWeekday | undefined;
  username?: string;
  userId?: string;
  name?: string;
  surname?: string;
  userLevelId?: string;
  age?: number;
  siteId?: number;
  defaultSite?: string;
  siteName?: string;
  lineId?: number;
  lineName?: string;
  language?: string;
  timezone?: string;
  email?: string;
  isVerifiedByAdmin?: boolean;
  isVerifiedByUser?: boolean;
  dateFormat?: string;
  dateTimeFormat?: string;
  timeFormat?: string;
  timeFormatHourAndMinute?: string;
  locale?: string;
  decimalSeparator?: TDecimalSeparator;
  thousandSeparator?: TThousandSeparator;
  clientCode?: string;
  clientNumber?: string;
  dataInterval?: number;
  clientOeeFormula?: string;
  isDigitalFormsActive?: boolean;
  isLaborTrackerActive?: boolean;
  isAssetTrackerActive?: boolean;
  isStationTrackerActive?: boolean;
  isOeeTrackerActive?: boolean;
  updateCurrentUserLoading?: boolean;
  updateCurrentUserLoaded?: boolean;
  isUserLoaded?: boolean;
  isUserLoading?: boolean;
  errors?: any[];
  isTokenRefreshed?: boolean;
  isFeedTokenLoading?: boolean;
  isFeedTokenLoaded?: boolean;
  notificationToken?: GetFeedTokenInterface;
  notificationsLoading?: boolean;
  notificationsLoaded?: boolean;
  notifications?: NotificationList;
  authorizedSites?: string;
  authorizedLines?: string;
  fullName?: string;
  expectedSpeed?: number;
  unitId?: number;
  decimalScaleLimit: number;
  siteDecimalScaleLimit: number;
  isNonVerifiedUserPassActive?: boolean;
  defaultSiteInfo?: GetCurrentUserResponseDefaultSiteInterface;
  menuPermissions?: IUserMenuPermissions;
  clientInfo?: GetCurrentUserResponseClientInterface;
  logbookAccessUrl: string;
  logbookAccess: number;
  inactivateUserWithoutValidation: number;
  dateTimeFormatWithSecond?: string;
  weekStartDay?: number;
  firebase?: FirebaseCredentials | null;
  devicePushNotificationToken?: string | null;
  appNotificationAccessUrl: string | null;
  level: any;
  oeeCalculateType: number;
}

export enum UserLevels {
  ADMIN = 1,
  EXECUTIVE = 2,
  SUPERVISOR = 4,
  LINE_LEADER = 16,
  OPERATOR = 32,
  PLANNER = 64,
}

export interface UserLevelInterface {
  id: UserLevels;
  name: string;
  parentLevelId?: number;
}
export type UserLevelKeyType = 'admin' | 'executive' | 'supervisor' | 'lineLeader' | 'operator' | 'planner';
export type UserLevelType = { [key in UserLevelKeyType]: UserLevelInterface };

export const userLevelObject: UserLevelType = {
  admin: { id: UserLevels.ADMIN, name: 'admin' },
  executive: { id: UserLevels.EXECUTIVE, name: 'executive' },
  supervisor: { id: UserLevels.SUPERVISOR, name: 'supervisor' },
  lineLeader: { id: UserLevels.LINE_LEADER, name: 'line leader' },
  operator: { id: UserLevels.OPERATOR, name: 'operator' },
  planner: { id: UserLevels.PLANNER, name: 'planner' },
};

export interface CustomDateFormat {
  parseInput: string;
  fullPickerInput: string;
  datePickerInput: string;
  timePickerInput: string;
  monthYearLabel: string;
  dateA11yLabel: string;
  monthYearA11yLabel: string;
}

export enum AdminLevels {
  GLOBAL_ADMIN = 'globalAdmin',
  SITE_ADMIN = 'siteAdmin',
}

export enum EIsIpRestrictionEnabled {
  NO = '0',
  SITE_DEFAULT = '1',
}

export type TCommonUserData = Pick<
  User,
  'siteId' | 'defaultSite' | 'timezone' | 'language' | 'locale' | 'dateTimeFormat' | 'firstWeekday'
>;
