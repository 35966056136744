import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  DateStringAddedDataInterface,
  ICurrentAndPreviousShift,
  ICurrentWorkOrder,
  IDateShifts,
  IExpectedSpeedAndUnit,
  IProductionReviewData,
  IProductionReviewDataInterface,
  IProductionReviewWorkOrderSummaryInterface,
} from './production-review.model';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  CommentUpdateDataInterface,
  CommentCreateDataInterface,
  ProductionReviewTargetCRUDInterface,
  ProductionReviewTargetDataInterface,
  ProductionReviewTargetSaveData,
  ProductionReviewTargetUpdateDataInterface,
  ProductionReviewCommentCRUDInterface,
} from './production-review-list-view.model';
import { dateStringToDateObject } from '../../shared/helper/date';
import { PRODUCT_ID_DESCRIPTION_MAX_LENGTH } from '../../../constants';
import * as _ from 'lodash';
import { WorkOrderNameIdPairInterface } from './work-order-name-id-pairs.model';
import { ProductionReviewListViewDataInterface } from './production-review-list-view.reducer';
import { WorkOrderSummaryDataInterface } from './production-review-wo-summary.model';
import { DecimalHelper } from '../../shared/helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class ProductionReviewService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getCurrentAndPreviousShift(lineId: number): Observable<BaseOneResponseInterface<ICurrentAndPreviousShift>> {
    return this.http.get<BaseOneResponseInterface<ICurrentAndPreviousShift>>(
      `${this.baseUrl}/lines/${lineId}/current-and-previous-shifts`,
    );
  }

  public getShiftsByDate(lineId: number, params: HttpParams): Observable<GetManyResponseInterface<IDateShifts>> {
    return this.http.get<GetManyResponseInterface<IDateShifts>>(`${this.baseUrl}/lines/${lineId}/shifts`, {
      params,
    });
  }

  public getProductionReviewWorkOrderSummary(
    lineId: number,
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IProductionReviewWorkOrderSummaryInterface>> {
    return this.http.get<GetManyResponseInterface<IProductionReviewWorkOrderSummaryInterface>>(
      `${this.baseUrl}/lines/${lineId}/production-review-work-order-summary`,
      {
        params,
      },
    );
  }

  public getProductionReviewData(
    lineId: number,
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IProductionReviewDataInterface>> {
    return this.http.get<GetManyResponseInterface<IProductionReviewDataInterface>>(
      `${this.baseUrl}/lines/${lineId}/production-review-data`,
      {
        params,
      },
    );
  }

  public createProductionReviewTarget(
    data: ProductionReviewTargetDataInterface,
  ): Observable<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>>(
      `${this.baseUrl}/production-review-targets`,
      data,
    );
  }

  public createProductionReviewComment(
    data: CommentCreateDataInterface,
  ): Observable<BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>>(
      `${this.baseUrl}/comments`,
      data,
    );
  }

  public updateProductionReviewComment(
    comment: CommentUpdateDataInterface,
  ): Observable<BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>> {
    const { id, ...updateData } = comment;
    return this.http.patch<BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>>(
      `${this.baseUrl}/comments/${id}`,
      updateData,
    );
  }

  public updateProductionReviewTarget(
    target: ProductionReviewTargetUpdateDataInterface,
  ): Observable<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>> {
    const { id, ...updateData } = target;
    return this.http.patch<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>>(
      `${this.baseUrl}/production-review-targets/${id}`,
      updateData,
    );
  }

  public deleteProductionReviewTarget(
    id: number,
  ): Observable<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>> {
    return this.http.delete<BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>>(
      `${this.baseUrl}/production-review-targets/${id}`,
    );
  }

  public bulkUpdateProductionReviewTargets(
    productionReviewTargets: ProductionReviewTargetUpdateDataInterface[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.baseUrl}/production-review-targets/bulk/edit`, {
      productionReviewTargets,
    });
  }

  public bulkSaveProductionReviewTarget(
    productionReviewTargets: ProductionReviewTargetSaveData[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.baseUrl}/production-review-targets/bulk/save`, {
      productionReviewTargets,
    });
  }

  public getExpectedSpeedAndUnit(lineId: number): Observable<BaseOneResponseInterface<IExpectedSpeedAndUnit>> {
    return this.http.get<BaseOneResponseInterface<IExpectedSpeedAndUnit>>(
      `${this.baseUrl}/lines/expected-speed-and-unit?lineId=${lineId}`,
    );
  }

  public getModifiedProductionReviewData(data: IProductionReviewDataInterface[]): IProductionReviewData {
    const workOrderNameIdPairs: WorkOrderNameIdPairInterface[] = [];
    const productionListViewData: ProductionReviewListViewDataInterface[] = [];
    const processedWorkOrders: number[] = [];

    let cumulativeTotal: string = '0';
    let currentWo: ICurrentWorkOrder = {
      woNumber: null,
      id: null,
    };

    for (let i = 0; i < Object.keys(data).length; i += 1) {
      const cumulativeParsedTotal: string = data[i].manualGoodCount;
      const isEditable: number = Number(data[i].editable);
      const workOrderTableId: number = data[i].workOrderTableId;
      const batchNumber: string = data[i].batchNumber;
      const isDisabled: boolean = Boolean(data[i].disabled);
      const runLineName: string = data[i].runLineName;

      cumulativeTotal = DecimalHelper.add1(
        cumulativeParsedTotal === null ? '0' : cumulativeParsedTotal,
        cumulativeTotal,
      );
      let hasSameStartEnd: boolean = false;
      let hasSameSensorCount: boolean = false;

      if (i !== 0 && data[i].start === data[i - 1].start) {
        hasSameStartEnd = true;
        hasSameSensorCount = true;
      }
      if (i + 1 < data.length && data[i].start === data[i + 1].start) {
        hasSameSensorCount = true;
      }

      const ganttData: DateStringAddedDataInterface[] = data[i].ganttData;
      const ganttDataLength: number = ganttData.length;

      for (let ganttActivityCounter = 0; ganttActivityCounter < ganttDataLength; ganttActivityCounter += 1) {
        const ganttActivityData: DateStringAddedDataInterface = ganttData[ganttActivityCounter];
        ganttData[ganttActivityCounter] = {
          ...ganttActivityData,
          startDateStr: dateStringToDateObject(ganttActivityData.activityStartT1).getTime() / 1000,
          endDateStr: dateStringToDateObject(ganttActivityData.activityEndT1).getTime() / 1000,
        };
      }
      productionListViewData.push({
        id: data[i].id,
        start: data[i].start,
        end: data[i].end,
        batch_number: batchNumber,
        manual_good_count: cumulativeParsedTotal,
        cumulative_total: cumulativeTotal,
        has_same_start_end: hasSameStartEnd,
        has_same_sensor_data: hasSameSensorCount,
        product_id: data[i].ganttData.length ? data[i].ganttData[0].type : data[i].productId,
        product_description: data[i].ganttData.length
          ? data[i].ganttData[0].productDescription
          : data[i].productDescription,
        unit_name: data[i].unitName,
        work_order_table_id: workOrderTableId,
        editable: isEditable,
        switchStatus: !isEditable,
        activityStart: data[i].activityStartT1,
        activityEnd: data[i].activityEndT1,
        gantt_data: data[i].ganttData,
        actualScrapCount: data[i].actualScrapCount,
        targetWorkOrderId: data[i].targetWorkOrderId,
        productionReviewTargetId: data[i].productionReviewTargetId,
        targetCount: data[i].targetCount,
        targetWorkOrder: data[i].targetWorkOrder,
        isApproved: data[i].isApproved,
        isOngoingHour: data[i].isOngoingHour,
        description: data[i].description,
        commentMessage: data[i].commentMessage,
        commentId: data[i].commentId,
        phaseId: data[i].phaseId,
        crewSize: data[i].crewSize,
        phaseName: data[i].phaseNames,
        canceled: data[i].canceled,
      });

      if (processedWorkOrders.indexOf(workOrderTableId) === -1 && workOrderTableId !== null) {
        workOrderNameIdPairs.push({
          runLineName,
          disabled: isDisabled,
          id: workOrderTableId,
          batch_number: batchNumber,
          editable: Boolean(isEditable),
          switchStatus: !isEditable,
          canceled: data[i].canceled,
          hasOngoingOeeCalculation: data[i].hasOngoingOeeCalculation,
        });
      }
      processedWorkOrders.push(workOrderTableId);

      if (currentWo.id === null && isEditable && isDisabled) {
        currentWo = {
          id: workOrderTableId,
          woNumber: batchNumber,
        };
      }
    }

    return {
      workOrderNameIdPairs,
      currentWo,
      shiftData: productionListViewData,
    };
  }

  public getModifiedProductionReviewWorkOrderSummaryData(
    data: IProductionReviewWorkOrderSummaryInterface[],
  ): WorkOrderSummaryDataInterface[] {
    const workOrderSummaryData: WorkOrderSummaryDataInterface[] = [];

    for (let i = 0; i < Object.keys(data).length; i += 1) {
      const productIdDescription: string = `${data[i].productId} - ${data[i].productDescription}`;

      workOrderSummaryData.push({
        work_order: data[i].workOrder,
        product_id_description:
          _.size(productIdDescription) <= PRODUCT_ID_DESCRIPTION_MAX_LENGTH
            ? productIdDescription
            : `${productIdDescription.substring(0, 60)}...`,
        shift_start_count: data[i].shiftStartCount,
        shift_production: data[i].shiftProduction,
        shift_end_count: data[i].shiftEndCount,
        remaining_by_shift_end: data[i].remainingByShiftEnd,
        final_work_order_count: Number(data[i].quantityOrdered),
        process_order: data[i].processOrder,
        job: data[i].job,
      });
    }

    return workOrderSummaryData;
  }
}
