import { Action } from '@ngrx/store';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { FETCH_ERROR } from '../tasks/tasks.actions';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IAddActivityOrderResponseInterface,
  IAddLayout,
  IAddLayoutResponse,
  IHomeMetricSet,
  IHomeMetricSetDataResponseInterface,
  ILayout, ILayoutCardTemplateData,
  ILayoutCardTemplateDataResponseInterface,
  ILayoutCardTemplateResponse,
  ILookup,
} from './home-page-display-settings.model';
import { IAddActivityOrderItemsData } from '../../../view/settings/home-page-display-settings/activity-order-card/activity-order-card.model';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { ITableQuery } from '../departments-lines-stations/stations/stations.model';
import { IUnitConversion } from '../product-conversion/product-conversion.model';

export const GET_LINES = '[HOME PAGE LAYOUT] GET LINES';
export const GET_LINES_COMPLETED = '[HOME PAGE LAYOUT] GET LINES COMPLETED';
export const ADD_LAYOUT = '[HOME PAGE LAYOUT] ADD LAYOUT DATA';
export const ADD_LAYOUT_COMPLETED = '[HOME PAGE LAYOUT] ADD LAYOUT COMPLETED';
export const EDIT_LAYOUT = '[HOME PAGE LAYOUT] EDIT LAYOUT DATA';
export const EDIT_LAYOUT_COMPLETED = '[HOME PAGE LAYOUT] EDIT LAYOUT COMPLETED';
export const LAYOUT_DATA_LOADING = '[HOME PAGE LAYOUT] LAYOUT DATA LOADING';
export const LAYOUT_DATA_LOADED = '[HOME PAGE LAYOUT] LAYOUT DATA LOADED';
export const ADD_HOME_METRIC_SET_LOADING = '[HOME PAGE LAYOUT] ADD HOME METRIC DATA LOADING';
export const ADD_HOME_METRIC_SET_LOADED = '[HOME PAGE LAYOUT] ADD HOME METRIC LOADED';
export const EDIT_HOME_METRIC_SET_LOADING = '[HOME PAGE LAYOUT] EDIT HOME METRIC DATA LOADING';
export const EDIT_HOME_METRIC_SET_LOADED = '[HOME PAGE LAYOUT] EDIT HOME METRIC LOADED';
export const GET_HOME_METRIC_SET = '[HOME PAGE LAYOUT] GET HOME METRIC SET';
export const GET_HOME_METRIC_SET_COMPLETED = '[HOME PAGE LAYOUT] GET HOME METRIC SET COMPLETED';
export const ADD_ACTIVITY_ORDER_ITEMS_DATA = '[HOME PAGE LAYOUT] ADD ACTIVITY ORDER ITEMS DATA';
export const ADD_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED = '[HOME PAGE LAYOUT] ADD ACTIVITY ORDER ITEMS DATA COMPLETED';
export const EDIT_ACTIVITY_ORDER_ITEMS_DATA = '[HOME PAGE LAYOUT] EDIT ACTIVITY ORDER ITEMS DATA';
export const EDIT_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED = '[HOME PAGE LAYOUT] EDIT ACTIVITY ORDER ITEMS DATA COMPLETED';
export const LAYOUT_ACTIVITIES_DATA_LOADING = '[HOME PAGE LAYOUT] LAYOUT ACTIVITIES DATA LOADING';
export const LAYOUT_ACTIVITIES_DATA_LOADED = '[HOME PAGE LAYOUT] LAYOUT ACTIVITIES DATA LOADED';
export const ADD_LAYOUT_CARD_TEMPLATE_DATA = '[HOME PAGE LAYOUT] ADD LAYOUT CARD TEMPLATE DATA';
export const ADD_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED = '[HOME PAGE LAYOUT] ADD LAYOUT CARD TEMPLATE DATA COMPLETED';
export const EDIT_LAYOUT_CARD_TEMPLATE_DATA = '[HOME PAGE LAYOUT] EDIT LAYOUT CARD TEMPLATE DATA';
export const EDIT_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED = '[HOME PAGE LAYOUT] EDIT LAYOUT CARD TEMPLATE DATA COMPLETED';
export const DELETE_LAYOUT = '[HOME PAGE LAYOUT] DELETE LAYOUT';
export const DELETE_LAYOUT_COMPLETED = '[HOME PAGE LAYOUT] DELETE LAYOUT COMPLETED';
export const GET_LAYOUT_CARD_TEMPLATE = '[HOME PAGE LAYOUT] GET LAYOUT CARD TEMPLATE';
export const GET_LAYOUT_CARD_TEMPLATE_COMPLETED = '[HOME PAGE LAYOUT] GET LAYOUT CARD TEMPLATE COMPLETED';
export const GET_PRODUCT_UNITS = '[HOME PAGE LAYOUT] GET PRODUCT UNITS';
export const GET_PRODUCT_UNITS_COMPLETED = '[HOME PAGE LAYOUT] GET PRODUCT UNITS COMPLETED';
export const GET_PRODUCT_UNIT_CONVERSIONS = '[HOME PAGE LAYOUT] GET PRODUCT UNIT CONVERSIONS';
export const GET_PRODUCT_UNIT_CONVERSIONS_COMPLETED = '[HOME PAGE LAYOUT] GET PRODUCT UNIT CONVERSIONS COMPLETED';

export class GetLines implements Action {
  readonly type = GET_LINES;

  constructor(public siteId: number) {}
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;

  constructor(public payload: GetManyResponseInterface<LineCRUDInterface>) {}
}

export class AddLayout implements Action {
  readonly type = ADD_LAYOUT;

  constructor(public layout: IAddLayout) {}
}

export class AddLayoutCompleted implements Action {
  readonly type = ADD_LAYOUT_COMPLETED;

  constructor(public payload: IAddLayoutResponse) {}
}

export class EditLayout implements Action {
  readonly type = EDIT_LAYOUT;

  constructor(public layout: IAddLayout, public id: number) {}
}

export class EditLayoutCompleted implements Action {
  readonly type = EDIT_LAYOUT_COMPLETED;

  constructor(public payload: IAddLayoutResponse) {}
}

export class LayoutDataLoading implements Action {
  readonly type = LAYOUT_DATA_LOADING;

  constructor(public tableQuery: ITableQuery) {}
}

export class LayoutDataLoaded implements Action {
  readonly type = LAYOUT_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ILayout>) {}
}

export class AddHomeMetricSet implements Action {
  readonly type = ADD_HOME_METRIC_SET_LOADING;

  constructor(public homeMetricSet: IHomeMetricSet[]) {}
}

export class AddHomeMetricSetLoaded implements Action {
  readonly type = ADD_HOME_METRIC_SET_LOADED;

  constructor(public payload: IHomeMetricSetDataResponseInterface) {}
}

export class EditHomeMetricSet implements Action {
  readonly type = EDIT_HOME_METRIC_SET_LOADING;

  constructor(public id: number, public homeMetricSets: IHomeMetricSet[]) {}
}

export class EditHomeMetricSetLoaded implements Action {
  readonly type = EDIT_HOME_METRIC_SET_LOADED;

  constructor(public payload: IHomeMetricSetDataResponseInterface) {}
}

export class GetHomeMetricSet implements Action {
  readonly type = GET_HOME_METRIC_SET;

  constructor(public layoutId: number) {}
}

export class GetHomeMetricSetCompleted implements Action {
  readonly type = GET_HOME_METRIC_SET_COMPLETED;

  constructor(public payload: IHomeMetricSetDataResponseInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export class AddActivityOrderItemsData implements Action {
  readonly type = ADD_ACTIVITY_ORDER_ITEMS_DATA;

  constructor(public activityOrderData: IAddActivityOrderItemsData[]) {}
}

export class AddActivityOrderItemsDataCompleted implements Action {
  readonly type = ADD_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED;

  constructor(public payload: IAddActivityOrderResponseInterface) {}
}

export class EditActivityOrderItemsData implements Action {
  readonly type = EDIT_ACTIVITY_ORDER_ITEMS_DATA;

  constructor(public activityOrderData: IAddActivityOrderItemsData[], public layoutId: number) {}
}

export class EditActivityOrderItemsDataCompleted implements Action {
  readonly type = EDIT_ACTIVITY_ORDER_ITEMS_DATA_COMPLETED;

  constructor(public payload: IAddActivityOrderResponseInterface) {}
}

export class LayoutActivitiesDataLoading implements Action {
  readonly type = LAYOUT_ACTIVITIES_DATA_LOADING;

  constructor(public lineIds: number[], public layoutId: number) {}
}

export class LayoutActivitiesDataLoaded implements Action {
  readonly type = LAYOUT_ACTIVITIES_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class AddLayoutCardTemplateData implements Action {
  readonly type = ADD_LAYOUT_CARD_TEMPLATE_DATA;

  constructor(public layoutCardTemplateData: ILayoutCardTemplateData) {}
}

export class AddLayoutCardTemplateDataCompleted implements Action {
  readonly type = ADD_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED;

  constructor(public payload: ILayoutCardTemplateDataResponseInterface) {}
}

export class EditLayoutCardTemplateData implements Action {
  readonly type = EDIT_LAYOUT_CARD_TEMPLATE_DATA;

  constructor(public id: number, public layoutCardTemplateData: ILayoutCardTemplateData) {}
}

export class EditLayoutCardTemplateDataCompleted implements Action {
  readonly type = EDIT_LAYOUT_CARD_TEMPLATE_DATA_COMPLETED;

  constructor(public payload: ILayoutCardTemplateDataResponseInterface) {}
}

export class DeleteLayout implements Action {
  readonly type = DELETE_LAYOUT;

  constructor(public layoutId: number) {}
}

export class DeleteLayoutCompleted implements Action {
  readonly type = DELETE_LAYOUT_COMPLETED;

  constructor(public payload: BaseCrudResponse) {}
}

export class GetLayoutCardTemplate implements Action {
  readonly type = GET_LAYOUT_CARD_TEMPLATE;

  constructor(public lineId: number) {}
}

export class GetLayoutCardTemplateCompleted implements Action {
  readonly type = GET_LAYOUT_CARD_TEMPLATE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ILayoutCardTemplateResponse[]>) {}
}

export class GetProductUnits implements Action {
  readonly type = GET_PRODUCT_UNITS;
}

export class GetProductUnitsCompleted implements Action {
  readonly type = GET_PRODUCT_UNITS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<ILookup>) {}
}

export class GetProductUnitConversions implements Action {
  readonly type = GET_PRODUCT_UNIT_CONVERSIONS;

  constructor(public productTableId: number, public baseUnitId: number) {}
}

export class GetProductUnitConversionsCompleted implements Action {
  readonly type = GET_PRODUCT_UNIT_CONVERSIONS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<IUnitConversion>) {}
}

export type HomePageLayoutActions =
  | GetLines
  | GetLinesCompleted
  | AddLayout
  | AddLayoutCompleted
  | EditLayout
  | EditLayoutCompleted
  | LayoutDataLoading
  | LayoutDataLoaded
  | AddHomeMetricSet
  | AddHomeMetricSetLoaded
  | EditHomeMetricSet
  | EditHomeMetricSetLoaded
  | GetHomeMetricSet
  | GetHomeMetricSetCompleted
  | AddActivityOrderItemsData
  | AddActivityOrderItemsDataCompleted
  | EditActivityOrderItemsData
  | EditActivityOrderItemsDataCompleted
  | LayoutActivitiesDataLoading
  | LayoutActivitiesDataLoaded
  | AddLayoutCardTemplateData
  | AddLayoutCardTemplateDataCompleted
  | EditLayoutCardTemplateData
  | EditLayoutCardTemplateDataCompleted
  | DeleteLayout
  | DeleteLayoutCompleted
  | GetLayoutCardTemplate
  | GetLayoutCardTemplateCompleted
  | GetProductUnits
  | GetProductUnitsCompleted
  | GetProductUnitConversions
  | GetProductUnitConversionsCompleted
  | FetchError;
