import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IUnitConversion, IUnitConversionBulk } from './product-conversion.model';

@Injectable({
  providedIn: 'root',
})
export class ProductConversionService {
  private readonly routes = {
    unitConversion: `${this.baseUrl}/unit-conversion`,
    bulkDelete: `${this.baseUrl}/unit-conversion/bulk/delete`,
    bulkSave: `${this.baseUrl}/unit-conversion/bulk/save`,
    bulkCreate: `${this.baseUrl}/unit-conversion/bulk/create`,
  };

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getProductConversion(
    productId: number,
    baseUnitId: number = null,
  ): Observable<GetManyResponseInterface<IUnitConversion>> {
    let httpParams: HttpParams = new HttpParams().set('s', JSON.stringify({ productId: { $eq: productId } }));

    if (baseUnitId !== null) {
      httpParams = httpParams.append('baseUnitId', JSON.stringify(baseUnitId));
    }

    return this.http.get<GetManyResponseInterface<IUnitConversion>>(this.routes.unitConversion, {
      params: httpParams,
    });
  }

  public addProductConversion(unitConversions: IUnitConversion[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.bulkCreate, { unitConversions });
  }

  public editProductConversion(payload: IUnitConversionBulk[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.routes.bulkSave, payload);
  }
}
