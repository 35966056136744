import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseOneResponseInterface } from '../../../model/interface/crud-response-interface.model';
import {
  FieldTypes,
  IAdvancedFilterDefaultsResponse,
  IFieldSelect,
  IFilter,
  IFilterOutput,
  IFilterSetAsDefaultData,
  InputTypes,
  IOperator,
  OperatorTypes,
  RawSqlOperators,
  SqlOperators,
  TargetEndpoints,
} from './advanced-filter.model';
import { User } from '../../../../store/user/model';
import * as _ from 'lodash';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../helper/date';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { advancedFilterFilterCardSubject } from '../../../../../constants';
import { HelperService } from '../../../service/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AdvancedFilterService {
  constructor(
    @Inject('API_BASE_URL')
    private readonly api: string,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private injector: Injector,
  ) {}

  protected filterCard$: FilterCardComponent;
  private filterCardSubscription: Subscription;

  public subscribeFilterCard(): void {
    this.filterCardSubscription = advancedFilterFilterCardSubject.subscribe((filterCard: any) => {
      this.filterCard$ = filterCard;
    });
  }

  public disposeFilterCardSubscription(): void {
    if (!this.filterCardSubscription) return;

    this.filterCardSubscription.unsubscribe();
  }

  private getValueForStringOperator(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    value: { [key: string]: any },
  ) {
    const isNumberFieldZero = fieldType === FieldTypes.number && value['$ne'] === '0';
    if (
      (operator === SqlOperators.$ne || operator === SqlOperators.$excl) &&
      fieldType !== FieldTypes.decimal &&
      !isNumberFieldZero
    ) {
      return { $or: [{ [path]: value }, { [path]: { $eq: '' } }, { [path]: { $isnull: true } }] };
    }

    if (
      (operator === SqlOperators.$ne || operator === SqlOperators.$excl) &&
      (fieldType === FieldTypes.decimal || isNumberFieldZero)
    ) {
      return { $or: [{ [path]: value }, { [path]: { $isnull: true } }] };
    }

    return { [path]: value };
  }

  private getValueForBooleanOperator(path: string, operator: SqlOperators) {
    if (operator === SqlOperators.$isnull) {
      return { $or: [{ [path]: { $isnull: true } }, { [path]: { $eq: '' } }] };
    }

    if (operator === SqlOperators.$notnull) {
      return { $and: [{ [path]: { $notnull: true } }, { [path]: { $ne: '' } }] };
    }

    return { [path]: { [operator]: true } };
  }

  private generateValueByOperator(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    operatorType: OperatorTypes,
    value: string,
  ): { [key: string]: any } {
    switch (operatorType) {
      case OperatorTypes.string:
        return this.getValueForStringOperator(path, fieldType, operator, { [operator]: value });

      case OperatorTypes.boolean:
        return this.getValueForBooleanOperator(path, operator);

      case OperatorTypes.range:
        return this.getValueForRangeOperator(path, fieldType, value);

      default:
        return { [path]: value };
    }
  }

  private getValueForRangeOperator(path: string, fieldType: FieldTypes, value: any) {
    if (fieldType === FieldTypes.date || fieldType === FieldTypes.dateTime) {
      return { [path]: { $between: [value.startDate, value.endDate] } };
    }

    return { [path]: value };
  }

  private getRawOperatorByOperator(operator: SqlOperators): RawSqlOperators {
    switch (operator) {
      case SqlOperators.$eq:
        return RawSqlOperators.EQUALS;

      case SqlOperators.$ne:
        return RawSqlOperators.NOT_EQUAL;

      case SqlOperators.$gt:
        return RawSqlOperators.GREATER_THAN;

      case SqlOperators.$gte:
        return RawSqlOperators.GREATER_THAN_OR_EQUAL;

      case SqlOperators.$lt:
        return RawSqlOperators.LESS_THAN;

      case SqlOperators.$lte:
        return RawSqlOperators.LESS_THAN_OR_EQUAL;

      case SqlOperators.$isnull:
        return RawSqlOperators.IS_NULL;

      case SqlOperators.$notnull:
        return RawSqlOperators.IS_NOT_NULL;

      case SqlOperators.$excl:
        return RawSqlOperators.NOT_LIKE;

      case SqlOperators.$cont:
        return RawSqlOperators.LIKE;

      case SqlOperators.$between:
        return RawSqlOperators.BETWEEN;

      default:
        return RawSqlOperators.EQUALS;
    }
  }

  private generateRawValue(
    path: string,
    fieldType: FieldTypes,
    operator: RawSqlOperators,
    operatorType: OperatorTypes,
    value: string,
    isDbPath: boolean = false,
  ): { [key: string]: any } {
    return { path, fieldType, operator, operatorType, value, ...(isDbPath ? { isDbPath } : {}) };
  }

  public generateQuery(
    path: string,
    fieldType: FieldTypes,
    operator: SqlOperators,
    operatorType: OperatorTypes,
    endpointType: TargetEndpoints,
    value: string,
    isDbPath: boolean = false,
  ): { [key: string]: any } {
    switch (endpointType) {
      case TargetEndpoints.NestJSCrud:
        return this.generateValueByOperator(path, fieldType, operator, operatorType, value);

      case TargetEndpoints.Custom:
        const rawOperator: RawSqlOperators = this.getRawOperatorByOperator(operator);
        return this.generateRawValue(path, fieldType, rawOperator, operatorType, value, isDbPath);

      default:
        throw new Error('Advanced Filter: Invalid target endpoint');
    }
  }

  public getDefaultFilters(userId: string): Observable<BaseOneResponseInterface<IAdvancedFilterDefaultsResponse>> {
    return this.http.get<BaseOneResponseInterface<IAdvancedFilterDefaultsResponse>>(
      `${this.api}/users/${userId}?fields=advancedFilterConfig`,
    );
  }

  public setAsDefault(
    userId: string,
    pageName: string,
    filters: IFilterSetAsDefaultData[],
  ): Observable<BaseOneResponseInterface<User>> {
    const normalizedFilters: IFilter[] = filters.map((filter: IFilter): IFilter => {
      return {
        ...filter,
        operators: undefined,
        value: this.prepareValue(filter),
      };
    });
    return this.http.patch<BaseOneResponseInterface<User>>(`${this.api}/users/${userId}`, {
      advancedFilterConfig: {
        [pageName]: normalizedFilters,
      },
    });
  }

  public getRequestQueryParameterName(endpointType: TargetEndpoints): string {
    switch (endpointType) {
      case TargetEndpoints.NestJSCrud:
        return 's';

      case TargetEndpoints.Custom:
        return 'advancedFilterParams';

      default:
        return 's';
    }
  }

  public prepareValue(filter: IFilter, forOutput: boolean = false): any {
    const getHelperService = this.injector.get(HelperService);
    if (filter.isFieldToFieldComparisonEnabled) {
      return forOutput ? { fieldToFieldCompare: filter.value[0].id } : filter.value;
    }

    if (filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime) {
      if (!_.isEmpty(filter.selectedOperator) && filter.selectedOperator[0].type === OperatorTypes.range) {
        return {
          startDate:
            filter.selectedFieldType === InputTypes.date
              ? moment(filter.value.startDate.valueOf()).format(mysqlDateFormat)
              : getHelperService.convertFromGivenTimezoneToUTCAsISOFormat(moment(filter.value.startDate.valueOf())),
          endDate:
            filter.selectedFieldType === InputTypes.date
              ? moment(filter.value.endDate.valueOf()).format(mysqlDateFormat)
              : getHelperService.convertFromGivenTimezoneToUTCAsISOFormat(moment(filter.value.endDate.valueOf())),
        };
      }

      if (!_.isEmpty(filter.selectedOperator) && filter.selectedOperator[0].type === OperatorTypes.string) {
        if (_.get(filter, 'value.startDate', null)) {
          return filter.selectedFieldType === InputTypes.date
            ? moment(filter.value.startDate.valueOf()).format(mysqlDateFormat)
            : getHelperService.convertFromGivenTimezoneToUTCAsISOFormat(moment(filter.value.startDate.valueOf()));
        }

        if (typeof filter.value === 'string') {
          return filter.value;
        }
      }
    }

    if (filter.selectedFieldType === InputTypes.decimal) {
      return this.decimalHelper.sanitizeString(filter.value);
    }

    return filter.value;
  }

  public prepareForOutput(filters: IFilter[]): IFilterOutput[] {
    return filters
      .filter((filter: IFilter) => filter.isActive && !filter.isHidden)
      .map((filter: IFilter): IFilterOutput => {
        const selectedField: IFieldSelect = _.head(filter.selectedField);
        const selectedOperator: IOperator = _.head(filter.selectedOperator);

        return {
          path: selectedField.path,
          type: selectedField.type,
          searchBy: _.get(selectedField, 'searchBy', undefined),
          operator: { name: selectedOperator.sql, type: selectedOperator.type },
          queryType: selectedField.queryType,
          value: this.prepareValue(filter, true),
          isFieldToFieldComparisonEnabled: filter.isFieldToFieldComparisonEnabled,
          ...(selectedField.isDbPath ? { isDbPath: selectedField.isDbPath } : {}),
        };
      });
  }

  public setFilterCard(filterCard: FilterCardComponent): void {
    advancedFilterFilterCardSubject.next(filterCard);
  }

  public unsetFilterCard(): void {
    this.filterCard$ = null;
  }

  public updateTable(): void {
    if (!this.filterCard$) {
      throw new Error('FilterCard is not set!');
    }

    this.filterCard$.onUpdateButtonClick();
  }
}
