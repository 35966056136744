import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ScenarioItemsDeployRequestInterface, SchedulerScenarioHorizonInterface } from './scheduler-service.model';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  ScenarioAdvancedFilterAddRequestInterface,
  ScenarioItemsResponseInterface,
  ScenarioWorkOrderRulesInterface,
  ISchedulerKpiMetricInfo,
  IKpiCardDataProperties,
  ScenarioResponseInterface,
  ESchedulerOptimizationType,
  ISchedulerAllOptimizations,
  IUpdateSchedulerOptimization,
  ICheckForDeployItemsResponse,
  IScenarioUncreatedWorkOrderResponse,
  IScenarioGanttAllItemsResponse,
  IScenarioUncreatedWorkOrder,
} from '../../../store/scheduler/scheduler.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import { ActivitiesService } from '../activities/activities.service';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { createQueryParams } from '../../helper/app-helper';
import { LineAvailabilityPlanDataInterface } from '../../../store/line-availability/line-availability.model';
import { IFilter } from '../../component/filter/advanced-filter/advanced-filter.model';
import { HelperService } from '../helper.service';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import { ScenarioBulkResponseDataInterface } from '../../../store/scheduler-scenario/scheduler-scenario.model';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { TranslateService } from '@ngx-translate/core';
import { ICheckForDeployItemsRequest } from '../../../view/scheduler/gantt-view/gantt-view.component.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly routes = {
    scenarios: '/scheduler/scenarios',
    scenarioItems: '/scheduler/scenario-items',
    scenarioItemsBulkCreate: '/scheduler/scenario-items/bulk/create',
    deploy: '/scheduler/scenario-items/deploy',
    scenarioShiftPlans: '/scheduler/shift-plans',
    scenarioWorkOrderRules: '/scheduler/scenarios/work-order-rules',
    optimizeScheduler: '/optimize-scheduler',
    getSchedulerOptimization: '/scheduler-optimization',
    updateSchedulerOptimization: '/update-scheduler-optimization',
    uncreatedWorkOrders: '/scheduler/scenario-uncreated-work-orders',
    uncreatedWorkOrdersBulkCreate: '/scheduler/scenario-uncreated-work-orders/bulk/create',
    uncreatedWorkOrdersBulkDelete: '/scheduler/scenario-uncreated-work-orders/bulk/delete',
  };

  public scenarioItemsLoaded: boolean = false;
  public scenarioItemsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public activityService: ActivitiesService,
    private readonly helperService: HelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    public translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public loadScenario(scenarioId: number): Observable<ScenarioResponseInterface> {
    const queryParams: HttpParams = new HttpParams()
      .append('join', 'schSetting')
      .append('join', 'schSetting.schSettingItem')
      .append('join', 'schSetting.schSettingLine')
      .append('join', 'schSetting.schSettingLine.line||title,lineType,siteId,activityIds,statusId,laborCapacity')
      .append('join', 'schSetting.schSettingLine.line.lineTypeName||id,lineType')
      .append(
        's',
        JSON.stringify({
          'schSetting.schSettingLine.line.lineTypeName.status': { $eq: true },
          'schSetting.schSettingLine.line.statusId': { $eq: 1 },
        }),
      )
      .append('join', 'site||decimalScaleLimit,preRunPhaseName,postRunPhaseName');
    return this.http.get<ScenarioResponseInterface>(`${this.api}${this.routes.scenarios}/${scenarioId}`, {
      params: queryParams,
    });
  }

  public loadScenarioItems(
    scenarioId: number,
    startDate: string = null,
    endDate: string = null,
    lineIds: number[] = [],
  ): Observable<ScenarioItemsResponseInterface> {
    let params: HttpParams = new HttpParams();

    params = params
      .set('limit', '15000')
      .append('join', 'childItems')
      .append('join', 'childItems.workOrderSchedule')
      .append('join', 'childItems.workOrderSchedule.product')
      .append('join', 'childItems.activity')
      .append('join', 'parentItem');

    const searchObj = {};

    _.set(searchObj, '$or', [{ scenarioId: { $eq: scenarioId } }, { scenarioId: { $isnull: true } }]);

    if (startDate !== null && endDate !== null) {
      _.set(searchObj, 'startDate.$lt', [moment(endDate).format(mysqlDateFormat)]);
      _.set(searchObj, 'endDate.$gt', [moment(startDate).format(mysqlDateFormat)]);
    }

    if (lineIds.length > 0) {
      _.set(searchObj, 'lineId.$in', lineIds);
    }

    params = params.set('s', JSON.stringify(searchObj));
    params = params.set('withActual', 'true');

    return this.http.get<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItems}`, {
      params,
    });
  }

  public saveScenarioItems(scenarioId: number, scenarioItems): Observable<ScenarioItemsResponseInterface> {
    return this.http.post<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItemsBulkCreate}`, {
      scenarioId,
      scenarioItems,
    });
  }

  public getDownTimeActivities(activityIds?: string[]): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    const searchObj = {};
    _.set(searchObj, 'activityType.$eq', 'downTimePlanned');
    _.set(searchObj, 'active.$eq', true);
    if (activityIds !== undefined) {
      _.set(searchObj, 'id.$in', activityIds);
    }

    let param: HttpParams = new HttpParams().set('limit', '1000');
    param = param.set('s', JSON.stringify(searchObj));

    return this.activityService.getActivities(param);
  }

  public deploy(
    horizon: SchedulerScenarioHorizonInterface,
    deployedItems: ScenarioItemsDeployRequestInterface[],
    siteId: number,
    uncreatedWorkOrders?: IScenarioUncreatedWorkOrder[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.api}${this.routes.deploy}`, {
      horizonStart: horizon.startDate.format(mysqlTimestampFormat),
      horizonEnd: horizon.endDate.format(mysqlTimestampFormat),
      lineItemGroups: deployedItems,
      siteId,
      uncreatedWorkOrders,
    });
  }

  public loadPlans(planIds: number[]): Observable<GetManyResponseInterface<LineAvailabilityPlanDataInterface>> {
    const httpOptions: { params: HttpParams } = createQueryParams(
      1,
      planIds.length,
      undefined,
      JSON.stringify({
        id: { $in: planIds },
      }),
    );
    httpOptions.params = httpOptions.params
      .append('join', 'schedulerShiftPlanItem')
      .append('join', 'schedulerShiftPlanItem.schedulerShift');

    return this.http.get<GetManyResponseInterface<LineAvailabilityPlanDataInterface>>(
      `${this.api}${this.routes.scenarioShiftPlans}`,
      httpOptions,
    );
  }

  public addScenarioWorkOrderRules(
    workOrders: ScenarioWorkOrderRulesInterface[],
  ): Observable<ScenarioBulkResponseDataInterface> {
    return this.http.post<ScenarioBulkResponseDataInterface>(
      `${this.api}${this.routes.scenarioWorkOrderRules}`,
      workOrders,
    );
  }

  public setAsDefault(
    advancedFilter: ScenarioAdvancedFilterAddRequestInterface,
  ): Observable<ScenarioResponseInterface> {
    const normalizedFilters: IFilter[] = advancedFilter.filters.map((filter: IFilter) => ({
      ...filter,
      value: this.advancedFilterService.prepareValue(filter),
    }));
    return this.http.patch<ScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${advancedFilter.scenarioId}`,
      {
        advancedFilterConfig: {
          [advancedFilter.pageName]: normalizedFilters,
        },
      },
    );
  }

  public getSchedulerKpiMetricInfo(id: number): Observable<BaseOneResponseInterface<ISchedulerKpiMetricInfo>> {
    return this.http.get<BaseOneResponseInterface<ISchedulerKpiMetricInfo>>(
      `${this.api}${this.routes.scenarios}/${id}/kpi-metrics`,
    );
  }

  public saveSchedulerKpiCardConfigs(scenarioId: number, config: any): Observable<BaseOneResponseInterface<void>> {
    return this.http.patch<BaseOneResponseInterface<void>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-configuration`,
      config,
    );
  }

  public saveSchedulerKpiCardData(
    scenarioId: number,
    kpiCardData: IKpiCardDataProperties,
  ): Observable<ScenarioResponseInterface> {
    return this.http.patch<ScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-scheduler-kpi-card-data`,
      kpiCardData,
    );
  }

  public optimizeScheduler(
    scenarioId: number,
    workOrderIds: number[],
    optimizationType: ESchedulerOptimizationType,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.optimizeScheduler}`,
      { workOrderIds, optimizationType },
    );
  }

  public getSchedulerOptimizations(scenarioId: number): Observable<ResponseInterface<ISchedulerAllOptimizations>> {
    return this.http.get<ResponseInterface<ISchedulerAllOptimizations>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.getSchedulerOptimization}`,
    );
  }

  public updateSchedulerOptimization(
    scenarioId: number,
    params: IUpdateSchedulerOptimization,
  ): Observable<ResponseInterface<boolean>> {
    return this.http.patch<ResponseInterface<boolean>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.updateSchedulerOptimization}`,
      {
        ...params,
      },
    );
  }

  public checkForDeployItems(
    checkForDeployItems: ICheckForDeployItemsRequest[],
  ): Observable<GetManyResponseInterface<ICheckForDeployItemsResponse>> {
    return this.http.post<GetManyResponseInterface<ICheckForDeployItemsResponse>>(
      `${this.api}${this.routes.scenarioItems}/check-for-deploy`,
      {
        checkForDeployItems,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public loadScenarioGanttAllItems(
    scenarioId: number,
    startDate: string = null,
    endDate: string = null,
    lineIds: number[] = [],
    isPhaseViewMode: boolean = false,
  ): Observable<IScenarioGanttAllItemsResponse> {
    const observables: {
      scenarioItems: Observable<ScenarioItemsResponseInterface>;
      uncreatedWorkOrders: Observable<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>>;
    } = {
      scenarioItems: this.loadScenarioItems(scenarioId, startDate, endDate, lineIds),
      uncreatedWorkOrders: !isPhaseViewMode
        ? this.loadUncreatedWorkOrders(scenarioId)
        : of({
            data: [],
            count: 0,
            page: 0,
            total: 0,
            pageCount: 0,
            success: true,
            date: moment().tz(this.timezone).format(mysqlTimestampFormat),
          }),
    };

    return forkJoin(observables);
  }

  public loadUncreatedWorkOrders(
    scenarioId: number,
  ): Observable<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>> {
    let params: HttpParams = new HttpParams();

    params = params
      .set('limit', '5000')
      .append('join', 'line')
      .append('join', 'workOrderSchedule')
      .append('join', 'workOrderSchedule.product')
      .append('join', 'workOrderSchedule.product.customer')
      .append('join', 'workOrderSchedule.site')
      .append('join', 'workOrderSchedule.lineType')
      .append('join', 'workOrderSchedule.job')
      .append(
        's',
        JSON.stringify({
          scenarioId: { $eq: scenarioId },
        }),
      );

    return this.http.get<GetManyResponseInterface<IScenarioUncreatedWorkOrderResponse>>(
      `${this.api}${this.routes.uncreatedWorkOrders}`,
      {
        params,
      },
    );
  }

  public saveUncreatedWorkOrders(
    scenarioId: number,
    uncreatedWorkOrders: IScenarioUncreatedWorkOrder[],
  ): Observable<ScenarioBulkResponseDataInterface> {
    return this.http.post<ScenarioBulkResponseDataInterface>(
      `${this.api}${this.routes.uncreatedWorkOrdersBulkCreate}`,
      {
        scenarioId,
        uncreatedWorkOrders,
      },
    );
  }

  public deleteUncreatedWorkOrders(uncreatedWorkOrderIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.uncreatedWorkOrdersBulkDelete}`, {
      body: { uncreatedWorkOrderIds },
    });
  }
}
