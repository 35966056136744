import { Action } from '@ngrx/store';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';

export const IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS = '[IOT_DEVICE_PORTAL] SET TABLE SETTINGS';

export class SetTableSettings implements Action {
  readonly type = IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export type IotDeviceReportActions = SetTableSettings;
