import { IIotDevice, IIotDeviceReportInfo } from '../../../shared/service/iot-device-report/iot-device-report.model';
import { ISelectForm, IStringInputForm } from '../../../shared/model/interface/scw-form.model';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { TranslateService } from '@ngx-translate/core';

export type TIotDeviceTableRow = (IIotDevice | Pick<IIotDevice, 'macAddress' | 'deviceSensors'>) & {
  definedInIotManagementNoSensor: boolean;
  notDefinedInIotManagement: boolean;
  deviceToCloudLagInMs: number | null;
  onlineStatus?: 'online' | 'offline' | 'unknown';
  lastHeartbeat?: string;
  lastMessageType?: string;
  ssid?: string;
  ip?: string;
};

export interface IIotDeviceReportKPI {
  onlineCount: number;
  offlineCount: number;
  unknownStatusCount: number;
  notDefinedInIotManagementCount: number;
  definedInIotManagementNoSensorCount: number;
}

export interface IIotDeviceReportInfoFormInputs {
  statusId: ISelectForm<DropdownOptionInterface<number>> & {
    defaultValue?: DropdownOptionInterface<number>[] | null;
  };
  comment: IStringInputForm & { defaultValue?: string | null };
}

export interface IIotDeviceReportInfoAddPayload {
  data: Partial<IIotDeviceReportInfo>;
}

export interface IIotDeviceReportInfoEditPayload extends IIotDeviceReportInfoAddPayload {
  id: number;
}

const defaultHeaders = [
  {
    value: 'macAddress',
    name: 'macAddress',
    selected: true,
    disabled: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'site',
    name: 'site',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'onlineStatus',
    name: 'onlineStatus',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'deviceSensors',
    name: 'deviceSensors',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'deviceTypeName',
    name: 'deviceTypeName',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'firmwareInfo.version',
    name: 'firmwareInfo',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'lastStatusAndComment.statusId',
    name: 'statusId',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'lastStatusAndComment.comment',
    name: 'comment',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'deviceToCloudLagInMs',
    name: 'deviceToCloudLagInMs',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'dfAssignedLine',
    name: 'dfAssignedLine',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'dfAssignedSite',
    name: 'dfAssignedSite',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'lastHeartbeat',
    name: 'lastHeartbeat',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'lastMessageType',
    name: 'lastMessageType',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'actions',
    name: 'actions',
    selected: true,
    sortable: false,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'ssid',
    name: 'ssid',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
  {
    value: 'ip',
    name: 'ip',
    selected: true,
    type: PageConfigurationTypes.TABLE,
  },
];
export const iotDeviceReportDefaultTableHeaders: (translate: TranslateService) => ITableHeader[] = (
  translate: TranslateService,
) => {
  return defaultHeaders.map((header) => ({
    ...header,
    name: translate.instant(`iotDeviceReport.tableHeaders.${header.name}`),
  }));
};
