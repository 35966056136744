import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import * as AppActions from '../../../store/app/actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import {
  ISelectWorkOrderModalConfigurations,
  IWorkOrderCardLayout,
  IWorkOrderCardLayoutView,
  WorkOrder,
  WorkOrderHeader,
} from '../../../view/home/work-order/work-order.model';
import { getCurrentDateTimeAsMoment, mysqlTimestampFormat } from '../../helper/date';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  IPredictedWorkOrderForSplit,
  IWorkOrderListItem,
  LinePathAssignmentMethods,
  OeePerformanceWorkOrderInterface,
  ProductInformationInterface,
  WorkOrderInformationFormInterface,
  WorkOrderLineChangeInterface,
  WoSelectGenericOutputInterface,
} from './select-work-order.model';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../service/helper.service';
import { InputLimit } from '../../model/enum/input-limit';
import { BehaviorSubject, combineLatest, Observable, Subscription, zip } from 'rxjs';
import { ofType } from '@ngrx/effects';
import * as HomeActions from '../../../store/home/home.actions';
import {
  CheckWorkOrderCompletedResponseInterface,
  GetWorkOrderNumbersRequestInterface,
  IChangeActivity,
  ILineInfoThatWorkOrder,
  IUpdateLineInformationCompleteResponse,
  WorkOrderAddEditResponseInterface,
  WorkOrderUpdateDataInterface,
} from '../../../store/home/home.model';
import { WorkOrderCreateRequestInterface } from '../../../store/work-order/work-order.model';
import * as WorkOrderActions from '../../../store/work-order/work-order.actions';
import { OeeAppState } from '../../../store/oee.reducer';
import {
  WorkOrderGroupType,
  WorkOrderScheduleInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { WizardComponent } from 'angular-archwizard';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { DECIMAL_DEFAULT_SCALE_LIMIT, smallModal, xllModalScrollable } from '../../../../constants';
import { EntityTranslatorService } from '../../service/entity-translator/entity-translator.service';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ActionTypes } from '../../model/enum/action-types';
import { EChartsOption } from 'echarts';
import { IClientSiteButtonListSection } from '../../../standalone/modals/client-side-button-list-modal/client-side-button-list.model';
import { filter, map, shareReplay } from 'rxjs/operators';
import { RadioSelectModalComponent } from '../../../standalone/modals/radio-select-modal/radio-select-modal.component';
import { Line } from '../../../store/line/model';
import { HomeActionTypes } from '../../../store/home/home.actions';
import { LinePathService } from '../../service/line-path/line-path.service';
import { ILinePath } from '../../../view/settings/product-configurations/line-path-product-configurations/line-path-product-configurations.model';
import { IBadge } from '../scw-mat-ui/scw-mat-button/scw-mat-button.model';
import { ColorService } from '../../service/color/color.service';
import { EColorPalette } from '../../service/color/color.model';

@OnDestroyDecorator
@Component({
  selector: 'app-select-work-order',
  templateUrl: './select-work-order.component.html',
  styleUrls: [
    './select-work-order.component.scss',
    '../../../../styles.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../../../../../node_modules/sweetalert2/src/sweetalert2.scss',
    '../../../view/home/work-order/work-order.component.scss',
  ],
})
export class SelectWorkOrderComponent implements OnInit, OnDestroy {
  @Input() workOrderHeader: WorkOrderHeader;
  @Input() configuration: ISelectWorkOrderModalConfigurations;
  @Input() showUnselectButton: boolean = false;
  @Input() homeStationId?: number;
  @Input() isOpenedFromHome: boolean = false;
  @Input() workOrderCardLayout: IWorkOrderCardLayout = null;

  @Output() onAddAndUpdateSubmit = new EventEmitter<WorkOrderLineChangeInterface>();
  @Output() selectWoLineConfirmed = new EventEmitter<WoSelectGenericOutputInterface>();
  @Output() closeThisModal = new EventEmitter<void>();
  @Output() completedWoOnAddConfirmed = new EventEmitter<WoSelectGenericOutputInterface>();
  @Output() onClickUnselectButton = new EventEmitter<WorkOrderHeader>();

  @ViewChild('work_order_running_another_line') workOrderRunningAnotherLineModalContent: ElementRef;
  @ViewChild('work_order_scheduled_another_line') workOrderScheduledAnotherLineModalContent: ElementRef;
  @ViewChild('already_completed_work_order_warning') alreadyCompletedWorkOrderWarningModalContent: ElementRef;
  @ViewChild('scheduled_work_order_exist') scheduledWorkOrderExistModalContent: ElementRef;
  @ViewChild('change_activity_for_multi_line') multiLineChangeActivityModalContent: ElementRef;

  public multiLineChangeActivityModalRef: NgbModalRef;
  public workOrderRunningAnotherLineModalRef: NgbModalRef;
  public workOrderScheduledAnotherLineModalRef: NgbModalRef;
  public alreadyCompletedWorkOrderWarningModalRef: NgbModalRef;
  public scheduledWorkOrderExistModalRef: NgbModalRef;
  public removeConfirmationModalRef: NgbModalRef;
  public selectLinePathModalRef: NgbModalRef;
  public selectLinePathAssignmentMethodModalRef: NgbModalRef;
  private newWorkOrderHeader: WorkOrderHeader;
  public searchProgress: boolean = false;
  public subscription: Subscription;
  public workOrderSearchText: string = '';
  public lazyLoadingPage: number = 1;
  public lazyLoadingProgress: boolean = false;
  public workOrderList: IWorkOrderListItem[] = [];
  public workOrderFilter: 'all' | 'line' = 'line';
  public selectedWorkOrder$: BehaviorSubject<{
    id: number;
    woNumber: string;
  }> = new BehaviorSubject<{ id: number; woNumber: string }>({ id: null, woNumber: null });
  public selectedWorkOrderData$ = this.selectedWorkOrder$.pipe(
    map((workOrder) => this.workOrderList.find((listWo) => listWo.id === workOrder.id)),
  );
  public oeePerformanceLastFiveWorkOrders: OeePerformanceWorkOrderInterface[] = [];
  public topFailureDowntimeOptions: EChartsOption;
  public oeePerformanceTrendOptions: EChartsOption;
  public workOrderInformationForm: Partial<WorkOrderInformationFormInterface> = {
    id: null,
    workOrderNo: '',
    actionType: '',
    woDueDate: '',
    quantityOrdered: null,
    productId: null,
    productRealId: '',
    productName: '',
    productDescription: '',
    versionId: null,
    versionName: '',
    productTableId: '',
    scheduledPreDuration: null,
    scheduledPostDuration: null,
    scheduledRunDuration: null,
    scheduledPreRunLaborHours: null,
    scheduledRunLaborHours: null,
    scheduledPostRunLaborHours: null,
    sequenceNumber: '',
    jobId: null,
    hasActivityHistory: false,
    hasOnGoingActivity: false,
    productIdDescription: '',
    matrixBasedPostRunDuration: '',
    quantityBasedRunDuration: '',
    matrixBasedPreRunDuration: '',
  };
  public productInformation: ProductInformationInterface = {
    billOfMaterial: {
      name: '',
      quantity: '',
      items: [],
    },
    customers: '',
    productUnit: '',
    productUnitValue: '',
    resources: {
      items: [],
      name: '',
      quantity: '',
    },
  };

  public selectLinePathAssignmentMethodModalData$: Observable<
    Pick<RadioSelectModalComponent, 'options' | 'confirmButtonConfiguration'>
  > = combineLatest({
    selectedWorkOrder: this.selectedWorkOrder$,
    currentWorkOrderOnLine: this.store.select('line', 'wOTableId'),
  }).pipe(
    map(({ selectedWorkOrder, currentWorkOrderOnLine }) => ({
      options: [
        {
          text: this.translate.instant('main.workOrder.selectLinePathAssignmentMethod.onlyInitiateCurrentLineOption'),
          value: LinePathAssignmentMethods.onlyInitiateCurrentLine,
          ...(selectedWorkOrder.id === currentWorkOrderOnLine
            ? {
                onSelectWarning: this.translate.instant(
                  'main.workOrder.selectLinePathAssignmentMethod.onlyInitiateCurrentLineOptionWarning',
                ),
              }
            : {}),
        },
        {
          text: this.translate.instant('main.workOrder.selectLinePathAssignmentMethod.initiateAllOption'),
          value: LinePathAssignmentMethods.initiateAll,
        },
      ],
      confirmButtonConfiguration: {
        type: 'danger',
        text: this.translate.instant('general.proceed'),
      },
    })),
  );
  private addEditWorkOrderTemplateRef: NgbModalRef;
  InputLimit = InputLimit;

  private checkWorkOrderCompletedActionSubscription: Subscription;
  private updateFinalizedWorkOrderCompletedActionSubscription: Subscription;
  private getWorkOrderCompletedActionSubscription: Subscription;
  private workOrderStatusCompletedActionSubscription: Subscription;
  private getVersionDetailCompletedActionSubscription: Subscription;
  private getProductPerformanceCompletedActionSubscription: Subscription;
  private getWorkOrderNumbersCompletedActionSubscription: Subscription;
  private createWorkOrderCompletedActionSubscription: Subscription;
  private updateWorkOrderCompletedActionSubscription: Subscription;
  private updateWorkOrderCompletedHomeActionSubscription: Subscription;
  private updateLineInformationCompletedHomeActionSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  public checkWorkOrderConfirmModalDetail: WorkOrderScheduleInterface | null;
  private temporaryNewWorkOrderName: string;
  private newWorkOrderModalRef: NgbModalRef;
  private openEditWorkOrderModalContentRef: TemplateRef<any>;
  public selectedLinePath: number | null = null;
  public createWorkOrderInProgress: boolean = false;
  public createWorkOrderInProgressCompleted$: boolean = false;
  public editWorkOrderInProgress: boolean = false;
  public searchInputEmpty: boolean = false;
  public workOrderFilterButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      text: this.translate.instant('general.all'),
      value: 'all',
    },
    {
      text: this.translate.instant('general.line'),
      value: 'line',
    },
  ];

  public clientCode$: string;

  private userStoreSubscription: Subscription;
  private homeStoreSubscription: Subscription;
  private workOrderStoreSubscription: Subscription;
  private lineStoreSubscription: Subscription;

  public successMessage: string;
  public workOrderStatusLoading$: boolean;
  public readonly isGetLineInfoThatSelectedWorkOrderDataLoading$ = this.store.select(
    'homeStore',
    'getLineInfoThatSelectedWorkOrderLoading',
  );
  public dateTimeFormat$: string;
  public timezone$: string = 'utc';
  public lineInfoThatSelectedWorkOrderData: ILineInfoThatWorkOrder | null = null;
  public showMultipleLineWarning: boolean = false;
  public scheduledLineDetail: string;
  public modalTitle: string = this.translate.instant('main.workOrder.addWorkOrder');
  public alreadyCompletedWorkOrderWarningModalText: string;
  public currentWorkOrderOnLine: number;
  public changeActivityPayload$: IChangeActivity;
  public dateNow: string;
  public isScheduledWorkOrderExist: boolean = false;
  public isScheduled: boolean = false;
  public isRecommended: boolean = false;
  public isOpenedOnStationHome: boolean;
  public withoutWorkOrder: boolean = false;
  public hasLineRunningWorkOrder: boolean = false;
  public searchString: string = '';
  public woCardLayoutDateFields: string[];
  public woCardLayoutDatetimeFields: string[];
  public woCardDurationFields: string[];
  public isUsedInSelectWorkOrderCardPropertySelected: boolean = false;
  public linePathWoSplitResults: number[] = [];
  public predictedWorkOrderForSplit: IPredictedWorkOrderForSplit | null = null;

  public linePathOptions: IClientSiteButtonListSection<
    ILineInfoThatWorkOrder['assignmentAvailableLinePaths'][number]
  >[] = [];
  public readonly isLinePathUtilized$: Observable<boolean> = this.store.select('homeStore', 'isLinePathUtilized');
  actionTypes = ActionTypes;
  public isSelectPathButtonDisabled$: Observable<boolean>;

  constructor(
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private http: HttpClient,
    private toast: ToastrService,
    public helperService: HelperService,
    private homeActions: ActionsSubject,
    private workOrderActions: ActionsSubject,
    private readonly ngbModal: NgbModal,
    private cd: ChangeDetectorRef,
    private readonly decimalHelper: DecimalHelper,
    private readonly translatorService: EntityTranslatorService,
    private readonly linePathService: LinePathService,
  ) {}

  ngOnInit(): void {
    this.isOpenedOnStationHome = this.helperService.isHomeRelatedComponentOpenedOnStation();
    this.hasLineRunningWorkOrder = this.workOrderHeader.id !== null;

    this.isSelectPathButtonDisabled$ = combineLatest([
      this.isGetLineInfoThatSelectedWorkOrderDataLoading$,
      this.selectedWorkOrder$,
      this.selectedWorkOrderData$,
    ]).pipe(
      map(([isGetLineInfoThatSelectedWorkOrderDataLoading, selectedWorkOrder, selectedWorkOrderData]) => {
        return (
          this.workOrderStatusLoading$ ||
          isGetLineInfoThatSelectedWorkOrderDataLoading ||
          selectedWorkOrder.id === null ||
          !this.lineInfoThatSelectedWorkOrderData ||
          !this.lineInfoThatSelectedWorkOrderData.assignmentAvailableLinePaths.length ||
          (selectedWorkOrderData.assignedLinePathId !== null &&
            !(
              this.lineInfoThatSelectedWorkOrderData.isWorkOrderRanOnAnotherLine &&
              this.lineInfoThatSelectedWorkOrderData.isWorkOrderTakeoverBySplitActive
            ))
        );
      }),
      shareReplay(1),
    );

    if (this.workOrderCardLayout) {
      this.formatWorkOrderCardLayoutInformation();
    }

    this.userStoreSubscription = this.store.select('user').subscribe((state) => {
      this.clientCode$ = state.clientCode;
      this.dateTimeFormat$ = state.dateTimeFormat;
      this.timezone$ = state.timezone;
    });

    this.homeStoreSubscription = this.store.select('homeStore').subscribe((state) => {
      this.workOrderStatusLoading$ = state.workOrderStatusLoading;
      this.withoutWorkOrder = !!state.line?.activityCard?.meta?.withoutWorkOrder;

      if (state.getLineInfoThatSelectedWorkOrderLoaded && this.showMultipleLineWarning) {
        this.predictedWorkOrderForSplit = null;

        this.lineInfoThatSelectedWorkOrderData = {
          ...state.getLineInfoThatSelectedWorkOrderData,
          assignmentAvailableLinePaths: this.linePathService.getFormattedAndSortedLinePathOptions(
            state.getLineInfoThatSelectedWorkOrderData.assignmentAvailableLinePaths,
          ),
        };
        this.linePathOptions = this.getLinePathOptions();
        const controlRunningOnAnotherLine = state.getLineInfoThatSelectedWorkOrderData.isWorkOrderRanOnAnotherLine;

        if (
          this.isScheduledWorkOrderExist &&
          !(this.selectedWorkOrder$.value.woNumber && controlRunningOnAnotherLine) &&
          !this.isRecommended &&
          !this.isScheduled
        ) {
          this.scheduledWorkOrderExistModalRef = this.ngbModal.open(this.scheduledWorkOrderExistModalContent, {
            keyboard: false,
            backdrop: 'static',
            windowClass: 'scw-modal-sm',
          });
        }

        if (this.selectedWorkOrder$.value.woNumber && controlRunningOnAnotherLine) {
          this.workOrderRunningAnotherLineModalRef = this.ngbModal.open(this.workOrderRunningAnotherLineModalContent, {
            keyboard: false,
            backdrop: 'static',
            windowClass: 'scw-modal-sm',
          });
        }
        this.showMultipleLineWarning = false;
      }
    });

    this.lineStoreSubscription = this.store.select('line').subscribe((state: Line) => {
      this.changeActivityPayload$ = {
        siteId: this.configuration.siteId,
        lineId: this.configuration.lineId,
        activityId: state.activityId,
        taskId: state.taskId,
      };

      this.currentWorkOrderOnLine = state.wOTableId;
    });

    if (this.workOrderHeader.productId === '' && this.workOrderHeader.id) {
      this.store.dispatch(new WorkOrderActions.GetWorkOrder(this.workOrderHeader.id));
    }

    this.workOrderStoreSubscription = this.store.select('workOrder').subscribe((state) => {
      this.createWorkOrderInProgressCompleted$ = state.createWorkOrderCompleted || state.errors.length !== 0;
    });

    this.workOrderActions
      .pipe(ofType(WorkOrderActions.WorkOrderActionTypes.GetWorkOrderCompleted))
      .subscribe((payload: WorkOrderActions.GetWorkOrderCompleted) => {
        const responseData: WorkOrderScheduleInterface = HelperService.cloneDeep(payload.response.data);
        this.translatorService.translate(responseData);

        this.workOrderHeader = {
          id: responseData.id,
          woNumber: responseData.woNumber,
          productId: responseData.product.productId,
          productName: responseData.productName,
          productDescription: responseData.description,
          quantityOrdered: responseData.quantityOrdered,
        };
        this.newWorkOrderHeader = this.workOrderHeader;
      });

    this.getWorkOrders();

    this.newWorkOrderHeader = this.workOrderHeader;

    this.checkWorkOrderCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.CheckWorkOrderCompleted))
      .subscribe((response: CheckWorkOrderCompletedResponseInterface) => {
        const data: WorkOrderScheduleInterface[] = response.payload;
        const firstFoundWorkOrder: WorkOrderScheduleInterface | null = _.get(data, '0', null);

        if (firstFoundWorkOrder === null) {
          this.workOrderInformationForm.workOrderNo = this.temporaryNewWorkOrderName;
          this.addEditWorkOrderTemplateRef = this.ngbModal.open(this.newWorkOrderModalRef, {
            windowClass: 'scw-modal-xl',
            keyboard: false,
            backdrop: 'static',
          });
        } else if (firstFoundWorkOrder.completed) {
          this.checkWorkOrderConfirmModal(firstFoundWorkOrder);
        } else {
          this.helperService.showToastMessage(
            false,
            this.translate.instant('general.failed'),
            this.translate.instant('workOrderCards.messages.workOrderAlreadyExists'),
          );
        }
      });

    this.updateFinalizedWorkOrderCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.UpdateWorkOrderCompleted))
      .subscribe((response) => {
        this.translatorService.translate(response['payload']);
        if (response['payload']['completed'] === 0) {
          const productId = response['payload']['productId'];
          const woNumber = response['payload']['batchClose']['WoNumber'];
          const productDescription = response['payload']['productDescription'];
          const productName = response['payload']['productName'];
          this.newWorkOrderHeader = {
            productId,
            woNumber,
            productDescription,
            productName,
            quantityOrdered: response['payload']['batchClose']['QuantityOrdered'],
            id: +response['payload']['batchClose']['Id'],
          };
          this.selectedWorkOrder$.next({
            woNumber: this.checkWorkOrderConfirmModalDetail['woNumber'],
            id: this.checkWorkOrderConfirmModalDetail['id'],
          });
          this.completedWoOnAddConfirmed.emit({
            newWorkOrderHeader: this.newWorkOrderHeader,
            workOrderProductText: this.getWorkOrderProductText(productId, productDescription, woNumber),
          });
        }
      });

    this.getWorkOrderCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetWorkOrderCompleted))
      .subscribe((response) => {
        const responseData = HelperService.cloneDeep(response['payload']);
        this.translatorService.translate(responseData);

        if (response['success'] !== undefined && response['success'] === false) {
          this.toast.error(
            this.translate.instant('workOrderCards.messages.notAuthorizedToEditWorkOrder'),
            this.translate.instant('general.failed'),
            {
              closeButton: true,
              progressBar: true,
              positionClass: 'toast-bottom-right',
            },
          );
        } else {
          this.workOrderInformationForm.workOrderNo = response['payload']['woNumber'];
          this.workOrderInformationForm.id = response['payload']['id'];
          this.workOrderInformationForm.quantityOrdered = response['payload']['quantityOrdered'];
          this.workOrderInformationForm.woDueDate = response['payload']['woDueDate'];
          this.workOrderInformationForm.productId = response['payload']['productId'];
          this.workOrderInformationForm.productTableId = response['payload']['productId'];
          this.workOrderInformationForm.productRealId = response['payload']['productRealId'];
          this.workOrderInformationForm.productName = responseData['productName'];
          this.workOrderInformationForm.productDescription = responseData['description'];
          // eslint-disable-next-line max-len
          this.workOrderInformationForm.productIdDescription = `${response['payload']['productName']} - ${response['payload']['description']}`;
          this.workOrderInformationForm.versionName = response['payload']['versionName'];
          this.workOrderInformationForm.sequenceNumber = response['payload']['sequenceNumber'];
          this.workOrderInformationForm.jobId = response['payload']['jobId'];
          this.workOrderInformationForm.job = response['payload']['job'];

          this.workOrderInformationForm.scheduledPreDuration = response['payload']['scheduledPreDuration'];
          this.workOrderInformationForm.scheduledPostDuration = response['payload']['scheduledPostDuration'];
          this.workOrderInformationForm.scheduledRunDuration = response['payload']['scheduledRunDuration'];
          this.workOrderInformationForm.scheduledPreRunLaborHours = response['payload']['scheduledPreRunLaborHours'];
          this.workOrderInformationForm.scheduledRunLaborHours = response['payload']['scheduledRunLaborHours'];
          this.workOrderInformationForm.scheduledPostRunLaborHours = response['payload']['scheduledPostRunLaborHours'];
          this.workOrderInformationForm.hasActivityHistory = response['payload']['hasActivityHistory'];
          this.workOrderInformationForm.hasOnGoingActivity = response['payload']['hasOnGoingActivity'];
          this.workOrderInformationForm.hasParentWorkOrder = response['payload']['hasParentWorkOrder'];
          this.workOrderInformationForm.hasChildWorkOrder = response['payload']['hasChildWorkOrder'];
          this.workOrderInformationForm.workOrderSplitNumber = response['payload']['workOrderSplitNumber'];

          this.workOrderInformationForm.processOrder = response['payload']['processOrder'];
          this.workOrderInformationForm.operationNumber = response['payload']['operationNumber'];
          this.workOrderInformationForm.editableFields = response['payload']['editableFields'];

          this.workOrderInformationForm.productVersionID = _.get(response, 'payload.productVersion', null);
          this.workOrderInformationForm.productVersionName = _.get(response, 'payload.productVersionInfo.name', null);

          this.workOrderInformationForm.matrixBasedPostRunDuration = _.get(
            response,
            'payload.matrixBasedPostRunDuration',
            null,
          );
          this.workOrderInformationForm.quantityBasedRunDuration = _.get(
            response,
            'payload.quantityBasedRunDuration',
            null,
          );
          this.workOrderInformationForm.matrixBasedPreRunDuration = _.get(
            response,
            'payload.matrixBasedPreRunDuration',
            null,
          );

          this.addEditWorkOrderTemplateRef = this.ngbModal.open(this.openEditWorkOrderModalContentRef, {
            windowClass: 'scw-modal-xl',
            keyboard: false,
            backdrop: 'static',
          });
        }
      });

    this.workOrderStatusCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.WorkOrderStatusCompleted))
      .subscribe((payload: HomeActions.WorkOrderStatusCompleted) => {
        const response = payload.payload;

        this.newWorkOrderHeader = {
          woNumber: this.selectedWorkOrder$.value.woNumber,
          productId: response.product.productId,
          productName: response.product.productId,
          quantityOrdered: response.quantityOrdered,
          id: response.id,
          productDescription: response.product.description,
        };

        const lineConfirm =
          response.scheduledLine === null ||
          this.configuration.lineId === response.scheduledLine ||
          response.hasActivityHistory ||
          response.hasOnGoingActivity;

        if (lineConfirm && !response.completed) {
          const woNumber: string =
            this.predictedWorkOrderForSplit?.predictedWorkOrderName ?? this.workOrderHeader.woNumber;

          this.selectWoLineConfirmed.emit({
            newWorkOrderHeader: this.newWorkOrderHeader,
            workOrderProductText: this.getWorkOrderProductText(null, null, woNumber),
            predictedWorkOrderSplitInfo: this.predictedWorkOrderForSplit,
          });
        } else {
          this.scheduledLineDetail = response.scheduledLineDetail.title;
          this.workOrderScheduledAnotherLineModalRef = this.ngbModal.open(
            this.workOrderScheduledAnotherLineModalContent,
            {
              keyboard: false,
              backdrop: 'static',
              windowClass: 'scw-modal-sm',
            },
          );
        }
      });

    this.getVersionDetailCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetVersionDetailCompleted))
      .subscribe((payload) => {
        const response = payload['payload'];
        if (response['success']) {
          this.productInformation = response['data'];

          this.productInformation.productUnitValue = this.translate.instant(
            `main.workOrder.${this.productInformation.productUnit}`,
          );

          this.productInformation.resources.quantity = this.decimalHelper.toFixedValue(
            this.productInformation.resources.quantity,
          );

          this.productInformation.resources.items.map((item) => {
            item.hours = this.decimalHelper.toFixedValue(item.hours);
          });

          this.productInformation.billOfMaterial.quantity = this.decimalHelper.toFixedValue(
            this.productInformation.billOfMaterial.quantity,
          );

          for (let i = 0; i < this.productInformation.billOfMaterial.items.length; i = i + 1) {
            this.productInformation.billOfMaterial.items[i].itemUnitValue = this.translate.instant(
              `main.workOrder.${this.productInformation.billOfMaterial.items[i].componentUnit}`,
            );
            this.productInformation.billOfMaterial.items[i].componentProductQuantity = this.decimalHelper.toFixedValue(
              this.productInformation.billOfMaterial.items[i].componentProductQuantity,
            );
          }
        }
      });

    this.getProductPerformanceCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetProductPerformanceCompleted))
      .subscribe((payload) => {
        const response = payload['payload'];
        if (response['success']) {
          const performanceTrendWorkOrders = [];
          const performanceTrendWorkOrdersOee = [];
          const productPerformanceTask = [];
          const productPerformanceTaskDuration = [];
          this.oeePerformanceLastFiveWorkOrders = [];

          for (let i = 0; i < response['data']['performanceTrend']['length']; i = i + 1) {
            performanceTrendWorkOrders.push(response['data']['performanceTrend'][i]['woNumber']);
            performanceTrendWorkOrdersOee.push(response['data']['performanceTrend'][i]['oeeOverallOee'] * 100);
          }

          for (let i: number = response['data']['tasks']['length']; i > 0; i = i - 1) {
            productPerformanceTask.push(response['data']['tasks'][i - 1]['taskName']);
            productPerformanceTaskDuration.push((response['data']['tasks'][i - 1]['duration'] / 3600).toFixed(2));
          }

          for (let i = 0; i < response['data']['lastFiveWorkOrder']['length']; i = i + 1) {
            let overallOee: string = this.decimalHelper.multiply(
              response['data']['lastFiveWorkOrder'][i]['oeeOverallOee'],
              '100',
            );
            overallOee = this.decimalHelper.toFixedValue(overallOee, DECIMAL_DEFAULT_SCALE_LIMIT);

            const size: string = this.decimalHelper.toFixedValue(
              response['data']['lastFiveWorkOrder'][i]['quantityOrdered'],
            );

            this.oeePerformanceLastFiveWorkOrders.push({
              overallOee,
              size,
              name: response['data']['lastFiveWorkOrder'][i]['woNumber'],
              productName: response['data']['lastFiveWorkOrder'][i]['itemDescription'],
              siteName: response['data']['lastFiveWorkOrder'][i]['siteName'],
              lineName: response['data']['lastFiveWorkOrder'][i]['actualLine'],
            });
          }

          this.topFailureDowntimeOptions = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            grid: {
              left: '10px',
              right: '10px',
              bottom: '10px',
              top: '10px',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'value',
                axisLabel: {
                  formatter: '{value}',
                },
                name: 'Hours',
                nameGap: 20,
                nameLocation: 'middle',
              },
            ],
            yAxis: [
              {
                type: 'category',
                data: productPerformanceTask,
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            series: [
              {
                name: this.translate.instant('main.workOrder.hours'),
                type: 'bar',
                data: productPerformanceTaskDuration,
                color: '#FF726B',
              },
            ],
          };

          this.oeePerformanceTrendOptions = {
            grid: {
              left: '10px',
              right: '10px',
              bottom: '10px',
              top: '10px',
              containLabel: true,
            },
            xAxis: {
              type: 'category',
              data: performanceTrendWorkOrders,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                rotate: 45,
              },
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                data: performanceTrendWorkOrdersOee,
                type: 'bar',
                color: '#70AFD7',
              },
            ],
          };
        }
      });

    this.getWorkOrderNumbersCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetWorkOrderNumbersCompleted))
      .subscribe((response: HomeActions.GetWorkOrderNumbersCompleted) => {
        const responseData: WorkOrderScheduleInterface[] = HelperService.cloneDeep(response.data);
        responseData.forEach((workOrderSchedule: WorkOrderScheduleInterface) => {
          this.translatorService.translate(workOrderSchedule);
        });

        if (response.dispatchedBy === 'searchRequest') {
          this.workOrderList = [];
          this.predictedWorkOrderForSplit = null;
          this.createWorkOrderList(responseData);
          this.searchProgress = false;
        }

        if (response.dispatchedBy === 'getWorkOrders') {
          this.workOrderList = [];
          this.predictedWorkOrderForSplit = null;
          this.createWorkOrderList(responseData);
        }

        if (response.dispatchedBy === 'onScroll') {
          this.createWorkOrderList(responseData, response.dispatchedBy);
          this.lazyLoadingProgress = false;
        }
      });

    this.createWorkOrderCompletedActionSubscription = this.workOrderActions
      .pipe(ofType(WorkOrderActions.WorkOrderActionTypes.CreateWorkOrderCompleted))
      .subscribe((payload: WorkOrderAddEditResponseInterface) => {
        this.emitCreateAndUpdateWorkOrderResponse(payload);
        if (payload.response.productId === null) {
          this.closeWorkOrderInformationModal();
        }
      });

    this.updateWorkOrderCompletedActionSubscription = this.workOrderActions
      .pipe(ofType(WorkOrderActions.WorkOrderActionTypes.UpdateWorkOrderCompleted))
      .subscribe((payload: WorkOrderAddEditResponseInterface) => {
        this.emitCreateAndUpdateWorkOrderResponse(payload);
        this.addModalClosed();
      });

    this.updateWorkOrderCompletedHomeActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.UpdateWorkOrderCompleted))
      .subscribe(() => {
        this.searchRequest(this.workOrderSearchText);
        this.alreadyCompletedWorkOrderWarningModalRef.close();
      });

    this.updateLineInformationCompletedHomeActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.UpdateLineInformationCompleted))
      .subscribe((payload: IUpdateLineInformationCompleteResponse) => {
        if (!payload.response.success) {
          return;
        }

        this.toast.success(
          this.translate.instant('general.changesSavedSuccessfully'),
          this.translate.instant('general.success'),
          {
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-right',
          },
        );

        if (this.removeConfirmationModalRef) {
          this.removeConfirmationModalRef.close();
        }

        this.closeThisModal.emit();
      });

    const isWorkOrderLoadCompleted$: Observable<unknown> = this.homeActions.pipe(
      ofType(HomeActions.HomeActionTypes.GetWorkOrderNumbersCompleted),
      filter((payload: HomeActions.GetWorkOrderNumbersCompleted) => payload.dispatchedBy === 'getWorkOrders'),
    );
    const isModalLoadedSources$: Observable<unknown> = this.isOpenedFromHome
      ? zip([
          this.homeActions.pipe(ofType(HomeActions.HomeActionTypes.GetIsLinePathUtilizedCompleted)),
          isWorkOrderLoadCompleted$,
        ])
      : isWorkOrderLoadCompleted$;

    this.subscriptions.push(
      isModalLoadedSources$.subscribe(() => {
        this.store.dispatch(new AppActions.HideLoader());
      }),

      this.homeActions
        .pipe(ofType(HomeActions.HomeActionTypes.AssignWoLinePathJobCompleted))
        .subscribe((payload: HomeActions.AssignWoLinePathJobCompleted) => {
          this.selectLinePathAssignmentMethodModalRef?.close();
          this.selectLinePathModalRef?.close();
          this.getWorkOrders();

          if (payload.method === LinePathAssignmentMethods.initiateAll) {
            this.linePathWoSplitResults = payload.createdWorkOrders;
            this.openMultiLineChangeActivityModal();
          } else {
            this.finalizeWOAndChangeActivityWithNewWO(payload.createdWorkOrders[0]);
            this.closeThisModal.emit();
          }
        }),
      this.homeActions.pipe(ofType(HomeActionTypes.MultipleChangeActivityDone)).subscribe(() => {
        this.closeThisModal.emit();
      }),
    );
  }

  private getLinePathOptions(): SelectWorkOrderComponent['linePathOptions'] {
    const { scheduledLinePathId } = this.workOrderList.find(
      (workOrder) => workOrder.id === this.selectedWorkOrder$.value.id,
    );
    return this.lineInfoThatSelectedWorkOrderData.assignmentAvailableLinePaths.reduce(
      (reducePayload, linePath) => {
        const index: number = scheduledLinePathId === linePath.id ? 0 : 1;
        reducePayload[index].items.push(linePath);
        return reducePayload;
      },
      [
        {
          header: this.translate.instant('main.workOrder.workOrderGroup.scheduled'),
          items: [],
        },
        {
          header: this.translate.instant('main.workOrder.workOrderGroup.notScheduled'),
          items: [],
        },
      ] as SelectWorkOrderComponent['linePathOptions'],
    );
  }

  public scheduledAnotherLineSelectAnyway(): void {
    this.selectWoLineConfirmed.emit({
      newWorkOrderHeader: this.newWorkOrderHeader,
      workOrderProductText: this.getWorkOrderProductText(),
    });
    this.workOrderScheduledAnotherLineModalRef.dismiss();
  }

  emitCreateAndUpdateWorkOrderResponse(payload: WorkOrderAddEditResponseInterface) {
    const productId = this.workOrderInformationForm.productName;
    const productDescription = this.workOrderInformationForm.productDescription;
    const woNumber = this.workOrderInformationForm.workOrderNo;
    const productName = this.workOrderInformationForm.productName;
    this.newWorkOrderHeader = {
      productId,
      productName,
      productDescription,
      woNumber,
      id: +payload.response.id,
      quantityOrdered: this.workOrderInformationForm.quantityOrdered,
    };
    this.onAddAndUpdateSubmit.emit({
      resp: payload.response,
      workOrderInformationForm: this.workOrderInformationForm,
      workOrderModal: this.addEditWorkOrderTemplateRef,
      genericOutput: {
        workOrderProductText: this.getWorkOrderProductText(productId, productDescription, woNumber),
        newWorkOrderHeader: this.newWorkOrderHeader,
      },
    });
    this.toast.success(this.translate.instant(this.successMessage), this.translate.instant('general.success'), {
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    });
    this.successMessage = null;
  }

  getWorkOrders(): void {
    this.workOrderSearchText = '';
    this.lazyLoadingPage = 1;
    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(
      new HomeActions.GetWorkOrderNumbers(
        ...HelperService.argumentClone(
          {
            workOrderFilter: this.workOrderFilter,
            dispatchedBy: 'getWorkOrders',
            page: this.lazyLoadingPage,
            selectWorkOrderModalConfigurations: this.configuration,
            workOrderListOrderBy: true,
          },
          this.homeStationId,
        ),
      ),
    );

    if (this.isOpenedFromHome) {
      this.store.dispatch(new HomeActions.GetIsLinePathUtilized(this.configuration.siteId));
    }
  }

  public searchRequest(searchString: string): void {
    if (searchString.length !== 0 && searchString.length < 3) {
      return;
    }

    this.searchProgress = true;

    const params: GetWorkOrderNumbersRequestInterface = {
      workOrderFilter: this.workOrderFilter,
      dispatchedBy: 'searchRequest',
      selectWorkOrderModalConfigurations: this.configuration,
      workOrderListOrderBy: true,
    };

    if (searchString) {
      params.search = searchString;
    }
    this.lazyLoadingPage = 1;
    params.page = this.lazyLoadingPage;
    this.searchString = searchString;

    const searchFields: string[] = [];

    if (this.workOrderCardLayout) {
      for (const value of Object.values(this.workOrderCardLayout)) {
        if (value.isUsedInSelectWorkOrderCard) {
          searchFields.push(value.field);
        }
      }

      params.searchFields = searchFields;
    }

    this.store.dispatch(
      new HomeActions.GetWorkOrderNumbers(...HelperService.argumentClone({ ...params }, this.homeStationId)),
    );
  }

  onScroll(): void {
    this.lazyLoadingPage = this.lazyLoadingPage + 1;
    this.lazyLoadingProgress = true;
    const params: GetWorkOrderNumbersRequestInterface = {
      workOrderFilter: this.workOrderFilter,
      dispatchedBy: 'onScroll',
      selectWorkOrderModalConfigurations: this.configuration,
      page: this.lazyLoadingPage,
      workOrderListOrderBy: true,
    };

    if (this.searchString) {
      params.search = this.searchString;
    }

    this.store.dispatch(new HomeActions.GetWorkOrderNumbers({ ...params }, this.homeStationId));
  }

  createWorkOrderList(data: WorkOrderScheduleInterface[], dispatchedBy: string = ''): void {
    this.dateNow = getCurrentDateTimeAsMoment(this.timezone$).format(mysqlTimestampFormat);
    if (dispatchedBy !== 'onScroll') {
      this.predictedWorkOrderForSplit = null;
      this.selectedWorkOrder$.next({
        id: null,
        woNumber: null,
      });
    }

    if (!Array.isArray(data)) {
      return;
    }

    let countOfRecommendedWorkOrder: number = 0;

    for (const entry of data) {
      const formattedScheduledRunDate: string = _moment(entry.scheduledRunDate).format(mysqlTimestampFormat);
      const formattedScheduledEndDate: string = _moment(entry.scheduledEndDate).format(mysqlTimestampFormat);
      const isDateBetween: boolean = _moment(this.dateNow).isBetween(
        _moment(formattedScheduledRunDate),
        _moment(formattedScheduledEndDate),
      );
      if ((entry.workOrderGroup === 'scheduled' || entry.workOrderGroup === 'recommended') && isDateBetween) {
        this.isScheduledWorkOrderExist = true;
      }
      let workOrderButtonClass = 'btn-primary';

      if (entry.scheduledRunDate !== null && entry.scheduledEndDate !== null && entry.scheduledLine !== null) {
        workOrderButtonClass = 'deployed';
      } else if (entry.scheduledLine !== null) {
        workOrderButtonClass = 'deployed-only-scheduled-line';
      }

      countOfRecommendedWorkOrder += Number(entry.workOrderGroup === 'recommended');

      let workOrderLayoutView: IWorkOrderCardLayoutView = null;
      const workOrderGroupWithoutRecommended: WorkOrderGroupType =
        entry.scheduledRunDate !== null && entry.scheduledEndDate !== null && entry.scheduledLine !== null
          ? 'scheduled'
          : 'notScheduled';

      const workOrderListValue: WorkOrder = {
        id: +entry.id,
        woNumber: entry.woNumber,
        scheduledStartDateFromScheduler: null,
        workOrderButton: workOrderButtonClass,
        productId: String(entry.productId),
        productDescription: entry.description,
        quantityOrdered: entry.quantityOrdered,
        productName: entry.productName,
        scheduledRunDate: entry.scheduledRunDate,
        scheduledEndDate: entry.scheduledEndDate,
        scheduledLine: entry.scheduledLine,
        siteId: entry.siteId,
        workOrderGroup:
          entry.workOrderGroup === 'recommended' && countOfRecommendedWorkOrder > 8
            ? workOrderGroupWithoutRecommended
            : entry.workOrderGroup,
        isLate: entry.isLate,
        productionStage: entry.productionStage,
        sequenceNumber: entry.sequenceNumber,
        jobNumber: entry.job?.jobName || null,
        woDueDate: entry.woDueDate,
        releaseDate: entry.releaseDate,
        scheduledPreDuration: entry.scheduledPreDuration,
        scheduledRunDuration: entry.scheduledRunDuration,
        scheduledPostDuration: entry.scheduledPostDuration,
        scheduledPreRunLaborHours: entry.scheduledPreRunLaborHours,
        scheduledRunLaborHours: entry.scheduledRunLaborHours,
        scheduledPostRunLaborHours: entry.scheduledPostRunLaborHours,
        workOrderCardLayout: workOrderLayoutView,
        operationNumber: entry.operationNumber,
        processOrder: entry.processOrder,
        scheduledLinePathId: entry.scheduledLinePathId,
        assignedLinePathId: entry.assignedLinePathId,
        assignedLineIdWithinLinePath: entry.assignedLineIdWithinLinePath,
      };

      if (this.workOrderCardLayout) {
        workOrderLayoutView = {
          textTop: this.workOrderCardLayout?.textTop?.isUsedInSelectWorkOrderCard
            ? this.formatValue(
                this.workOrderCardLayout.textTop.field,
                workOrderListValue[this.workOrderCardLayout.textTop.field],
              )
            : null,
          textMiddle: this.workOrderCardLayout?.textMiddle?.isUsedInSelectWorkOrderCard
            ? this.formatValue(
                this.workOrderCardLayout.textMiddle.field,
                workOrderListValue[this.workOrderCardLayout.textMiddle.field],
              )
            : null,
          textBottom: this.workOrderCardLayout?.textBottom?.isUsedInSelectWorkOrderCard
            ? this.formatValue(
                this.workOrderCardLayout.textBottom.field,
                workOrderListValue[this.workOrderCardLayout.textBottom.field],
              )
            : null,
        };
      }

      let badge: IBadge | null = null;

      if (workOrderListValue.workOrderGroup === 'recommended') {
        if (workOrderListValue.assignedLineIdWithinLinePath === this.configuration.lineId) {
          const [color] = ColorService.getColorDataFromColorPalette([EColorPalette.BLUE_2]);
          badge = {
            text: this.translate.instant('general.assigned'),
            textColor: '#FFFFFF',
            backgroundColor: color.backgroundColor,
          };
        } else if (workOrderListValue.isLate) {
          const [color] = ColorService.getColorDataFromColorPalette([EColorPalette.RED_3]);
          badge = {
            text: this.translate.instant('general.late'),
            textColor: '#FFFFFF',
            backgroundColor: color.backgroundColor,
          };
        }
      }

      this.workOrderList.push({
        ...workOrderListValue,
        badge,
        workOrderCardLayout: workOrderLayoutView,
      });
    }

    this.sortByWorkOrderGroup();
  }

  public sortByWorkOrderGroup(): void {
    const groupIds = {
      recommended: 1,
      scheduled: 2,
      notScheduled: 3,
    };

    this.workOrderList = this.workOrderList.map((workOrder) => {
      return {
        ...workOrder,
        groupOrder: _.get(groupIds, workOrder.workOrderGroup, 4),
      };
    });

    this.workOrderList = _.orderBy(this.workOrderList, ['groupOrder']);
  }

  public lineFilterChange(event: 'all' | 'line'): void {
    this.workOrderFilter = event;
    this.searchRequest(this.workOrderSearchText);
  }

  checkWorkOrderConfirmModal(detail): void {
    this.alreadyCompletedWorkOrderWarningModalText = this.translate.instant('main.workOrder.questions.completed');
    if (detail['scheduledLine'] !== null && this.configuration.lineId !== detail['scheduledLine']) {
      this.alreadyCompletedWorkOrderWarningModalText = this.translate.instant(
        'main.workOrder.questions.completedAndDifferentLine',
        {
          differentLine: detail['scheduledLineDetail']['title'],
          workOrderNumber: detail['woNumber'],
        },
      );
    }

    this.checkWorkOrderConfirmModalDetail = detail;

    this.alreadyCompletedWorkOrderWarningModalRef = this.ngbModal.open(
      this.alreadyCompletedWorkOrderWarningModalContent,
      {
        keyboard: false,
        backdrop: 'static',
        windowClass: 'scw-modal-sm',
      },
    );
  }

  workOrderCreateModal(content): void {
    const workOrderName: string = this.workOrderSearchText.trim();

    this.searchInputEmpty = false;
    this.store.dispatch(new AppActions.ShowLoader());
    this.temporaryNewWorkOrderName = workOrderName;
    this.newWorkOrderModalRef = content;

    setTimeout(() => {
      this.store.dispatch(
        new HomeActions.CheckWorkOrder({
          workOrder: workOrderName,
          lineId: this.configuration.lineId,
          siteId: this.configuration.siteId,
        }),
      );
    }, 400);
  }

  public openEditWorkOrderModal(content: TemplateRef<any>, workOrderTableId: number): void {
    this.store.dispatch(new AppActions.ShowLoader());

    this.openEditWorkOrderModalContentRef = content;

    setTimeout(() => {
      this.store.dispatch(
        new HomeActions.GetWorkOrder({
          workOrderTableId: String(workOrderTableId),
        }),
      );
    }, 200);
  }

  getWorkOrderInformation(data: Partial<WorkOrderInformationFormInterface>): void {
    this.workOrderInformationForm = data;

    if (this.workOrderInformationForm.actionType === 'edit') {
      this.updateWorkOrder();
    } else {
      this.productInformation = {
        billOfMaterial: {
          name: '',
          quantity: '',
          items: [],
        },
        customers: '',
        productUnit: '',
        productUnitValue: '',
        resources: {
          items: [],
          name: '',
          quantity: '',
        },
      };

      this.store.dispatch(new AppActions.ShowLoader());
      if (this.workOrderInformationForm.versionId) {
        this.store.dispatch(
          new HomeActions.GetVersionDetail({
            versionId: this.workOrderInformationForm.versionId,
          }),
        );
      }

      this.store.dispatch(
        new HomeActions.GetProductPerformance({
          id: this.workOrderInformationForm.productId.toString(),
          siteId: this.configuration.siteId.toString(),
          lineId: this.configuration.lineId.toString(),
        }),
      );

      this.createWorkOrder();
    }
  }

  workOrderLineConfirm(): void {
    if (this.selectedWorkOrder$.value.id !== null) {
      this.store.dispatch(new AppActions.ShowLoader());
      this.store.dispatch(
        new HomeActions.WorkOrderStatus({
          id: this.selectedWorkOrder$.value.id,
        }),
      );
    }
  }

  changeSelectedWorkOrder(workOrder): void {
    this.isScheduled = false;
    this.isRecommended = false;

    this.store.dispatch(
      new HomeActions.GetLineInfoThatWorkOrderLoading(workOrder.id, this.configuration.lineId, {
        includeAssignmentAvailableLinePaths: true,
      }),
    );
    this.showMultipleLineWarning = true;

    this.selectedWorkOrder$.next({
      woNumber: workOrder.woNumber,
      id: +workOrder.id,
    });

    if (workOrder.workOrderGroup === 'scheduled') {
      this.isScheduled = true;
    }

    if (workOrder.workOrderGroup === 'recommended') {
      this.isRecommended = true;
    }

    this.workOrderHeader = {
      id: +workOrder.id,
      woNumber: workOrder.woNumber,
      productId: workOrder.productId,
      quantityOrdered: workOrder.quantityOrdered,
      productDescription: workOrder.productDescription,
      productName: workOrder.productName,
    };
  }

  addModalClosed(): void {
    this.addEditWorkOrderTemplateRef.dismiss();
  }

  createWorkOrder(): void {
    if (this.createWorkOrderInProgress && !this.createWorkOrderInProgressCompleted$) {
      return;
    }
    this.createWorkOrderInProgress = true;
    this.store.dispatch(new AppActions.ShowLoader());
    this.store
      .select('user')
      .subscribe((state) => {

        const siteId = this.configuration && this.configuration.siteId ? this.configuration.siteId : state.siteId;
        const data: WorkOrderCreateRequestInterface = {
          siteId,
          woDueDate: this.workOrderInformationForm.woDueDate as string ?? null,
          woNumber: this.workOrderInformationForm.workOrderNo,
          productId: +this.workOrderInformationForm.productId,
          quantityOrdered: this.decimalHelper.sanitizeString(this.workOrderInformationForm.quantityOrdered),
          scheduledPreDuration: this.workOrderInformationForm.scheduledPreDuration,
          scheduledPostDuration: this.workOrderInformationForm.scheduledPostDuration,
          scheduledRunDuration: this.workOrderInformationForm.scheduledRunDuration,
          scheduledPreRunLaborHours: this.workOrderInformationForm.scheduledPreRunLaborHours,
          scheduledRunLaborHours: this.workOrderInformationForm.scheduledRunLaborHours,
          scheduledPostRunLaborHours: this.workOrderInformationForm.scheduledPostRunLaborHours,
          sequenceNumber:
            this.workOrderInformationForm.sequenceNumber === null
              ? null
              : Number(this.workOrderInformationForm.sequenceNumber),
          jobId: this.workOrderInformationForm.jobId ?? null,
          processOrder: this.workOrderInformationForm.processOrder,
          operationNumber: this.workOrderInformationForm.operationNumber,
          matrixBasedPostRunDuration: this.workOrderInformationForm.matrixBasedPostRunDuration,
          quantityBasedRunDuration: this.workOrderInformationForm.quantityBasedRunDuration,
          matrixBasedPreRunDuration: this.workOrderInformationForm.matrixBasedPreRunDuration,
        };
        this.successMessage = 'main.workOrder.messages.workOrderCreatedSuccess';
        this.store.dispatch(new WorkOrderActions.CreateWorkOrder(data));
      })
      .unsubscribe();
  }

  updateWorkOrder(): void {
    if (this.editWorkOrderInProgress) {
      return;
    }

    this.editWorkOrderInProgress = true;
    this.store.dispatch(new AppActions.ShowLoader());

    const data: WorkOrderUpdateDataInterface = {
      productId: +this.workOrderInformationForm.productId,
      quantityOrdered: this.decimalHelper.sanitizeString(this.workOrderInformationForm.quantityOrdered),
      woDueDate: this.workOrderInformationForm.woDueDate as string ?? null,
      scheduledPreDuration: this.workOrderInformationForm.scheduledPreDuration,
      scheduledPostDuration: this.workOrderInformationForm.scheduledPostDuration,
      scheduledRunDuration: this.workOrderInformationForm.scheduledRunDuration,
      scheduledPreRunLaborHours: this.workOrderInformationForm.scheduledPreRunLaborHours,
      scheduledRunLaborHours: this.workOrderInformationForm.scheduledRunLaborHours,
      scheduledPostRunLaborHours: this.workOrderInformationForm.scheduledPostRunLaborHours,
      sequenceNumber:
        this.workOrderInformationForm.sequenceNumber === null
          ? null
          : Number(this.workOrderInformationForm.sequenceNumber),
      jobId: this.workOrderInformationForm.jobId,
      woNumber: this.workOrderInformationForm.workOrderNo,
      operationNumber: this.workOrderInformationForm.operationNumber,
      processOrder: this.workOrderInformationForm.processOrder,
      productVersion: this.workOrderInformationForm.productVersionID,
    };

    this.successMessage = 'main.workOrder.messages.workOrderUpdatedSuccess';
    this.store.dispatch(
      new WorkOrderActions.UpdateWorkOrder(
        _.pick(
          data,
          Array.isArray(this.workOrderInformationForm.editableFields)
            ? this.workOrderInformationForm.editableFields
            : this.workOrderInformationForm.editableFields.split(','),
        ),
        this.workOrderInformationForm.id,
      ),
    );
  }

  getWorkOrderProductText(
    productId: string = null,
    productDescription: string = null,
    woNumber: string = null,
  ): string {
    const id = productId ? productId : this.workOrderHeader.productName;
    const workOrder = woNumber ? woNumber : this.workOrderHeader.woNumber;
    const description = productDescription ? productDescription : this.workOrderHeader.productDescription;
    const productText = id !== undefined ? ` - ${id} ${description}` : '';
    return `${workOrder}${productText}`;
  }

  public backButtonClicked(): void {
    this.createWorkOrderInProgress = false;
  }

  public unselectButtonClicked(): void {
    this.onClickUnselectButton.emit(this.workOrderHeader);
  }

  public alreadyCompletedWorkOrderRerun(): void {
    this.store.dispatch(
      new HomeActions.UpdateWorkOrder({ completed: false }, this.checkWorkOrderConfirmModalDetail['id']),
    );
  }

  public backToSelection(calledFrom: string = 'runningAnotherLineModal'): void {
    this.selectedWorkOrder$.next({
      woNumber: null,
      id: null,
    });
    this.isScheduled = false;
    this.isRecommended = false;
    this.workOrderHeader = this.newWorkOrderHeader;
    this.predictedWorkOrderForSplit = null;

    if (calledFrom === 'scheduledAnotherLineModal') {
      this.scheduledWorkOrderExistModalRef.close();
    } else {
      this.workOrderRunningAnotherLineModalRef.close();
    }
  }

  public openLinePathSelectionModal(content: TemplateRef<any>): void {
    this.selectLinePathModalRef = this.ngbModal.open(content, xllModalScrollable);
  }

  private finalizeWOAndChangeActivityWithNewWO(newWorkOrderId: number): void {
    if (newWorkOrderId === this.currentWorkOrderOnLine) {
      this.closeThisModal.emit();
      return;
    }

    this.store.dispatch(new AppActions.ShowLoader());

    if (!this.currentWorkOrderOnLine || !this.changeActivityPayload$.activityId) {
      this.store.dispatch(
        new HomeActions.UpdateCurrentActivity({
          workOrderId: newWorkOrderId,
        }),
      );
      return;
    }

    this.store.dispatch(
      new HomeActions.ChangeActivity({
        ...this.changeActivityPayload$,
        finalizeWorkOrder: true,
        workOrderId: newWorkOrderId,
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.checkWorkOrderCompletedActionSubscription) {
      this.checkWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.updateFinalizedWorkOrderCompletedActionSubscription) {
      this.updateFinalizedWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.getWorkOrderCompletedActionSubscription) {
      this.getWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.workOrderStatusCompletedActionSubscription) {
      this.workOrderStatusCompletedActionSubscription.unsubscribe();
    }

    if (this.getVersionDetailCompletedActionSubscription) {
      this.getVersionDetailCompletedActionSubscription.unsubscribe();
    }

    if (this.getProductPerformanceCompletedActionSubscription) {
      this.getProductPerformanceCompletedActionSubscription.unsubscribe();
    }

    if (this.getWorkOrderNumbersCompletedActionSubscription) {
      this.getWorkOrderNumbersCompletedActionSubscription.unsubscribe();
    }

    if (this.createWorkOrderCompletedActionSubscription) {
      this.createWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.updateWorkOrderCompletedActionSubscription) {
      this.updateWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }

    if (this.homeStoreSubscription) {
      this.homeStoreSubscription.unsubscribe();
    }

    if (this.workOrderStoreSubscription) {
      this.workOrderStoreSubscription.unsubscribe();
    }

    if (this.updateWorkOrderCompletedHomeActionSubscription) {
      this.updateWorkOrderCompletedHomeActionSubscription.unsubscribe();
    }

    if (this.lineStoreSubscription) {
      this.lineStoreSubscription.unsubscribe();
    }

    if (this.updateLineInformationCompletedHomeActionSubscription) {
      this.updateLineInformationCompletedHomeActionSubscription.unsubscribe();
    }

    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  enterSecondStep(step: string) {
    switch (step) {
      case 'productPerformance': {
        this.modalTitle = this.translate.instant('main.workOrder.productPerformance');
        break;
      }
      case 'productInformation': {
        this.modalTitle = this.translate.instant('main.workOrder.productInformation');
        break;
      }
      default: {
        this.modalTitle = this.translate.instant('main.workOrder.addWorkOrder');
        break;
      }
    }
  }

  public goToNextStepWizard(wizard: WizardComponent): void {
    wizard.goToNextStep();
  }

  public closeWorkOrderInformationModal(): void {
    this.closeThisModal.emit();
    this.addEditWorkOrderTemplateRef.close();
  }

  public removeWorkOrder(): void {
    this.store.dispatch(
      new HomeActions.UpdateLineInformation(this.configuration.lineId, {
        workOrderId: null,
        isRemoveWorkOrder: true,
      }),
    );
  }

  public confirmationModal(content: TemplateRef<any>): void {
    this.removeConfirmationModalRef = this.ngbModal.open(content, smallModal);
  }

  private formatWorkOrderCardLayoutInformation(): void {
    const workOrderLayoutColumns: string[] = ['textTop', 'textMiddle', 'textBottom'];
    this.woCardLayoutDateFields = ['releaseDate', 'scheduledRunDate', 'scheduledEndDate'];
    this.woCardLayoutDatetimeFields = ['woDueDate'];
    this.woCardDurationFields = [
      'scheduledPreDuration',
      'scheduledRunDuration',
      'scheduledPostDuration',
      'scheduledPreRunLaborHours',
      'scheduledRunLaborHours',
      'scheduledPostRunLaborHours',
      'quantityOrdered',
    ];
    const productFields: string[] = ['productId'];

    workOrderLayoutColumns.forEach((column: string) => {
      if (this.workOrderCardLayout[column]?.isUsedInSelectWorkOrderCard) {
        this.isUsedInSelectWorkOrderCardPropertySelected = true;
      }

      if (productFields.includes(this.workOrderCardLayout[column]?.field)) {
        this.workOrderCardLayout[column].field = 'productName';
      }
    });
  }

  public formatValue(type: string, value: string): string {
    let formattedValue: string = value;

    if (value === null) {
      formattedValue = '--';
    } else if (this.woCardDurationFields.includes(type)) {
      formattedValue = this.decimalHelper.toFixedValue(value, DECIMAL_DEFAULT_SCALE_LIMIT);
    } else if (this.woCardLayoutDateFields.includes(type)) {
      formattedValue = this.helperService.setUserDateTimeFormat(value, false);
    } else if (this.woCardLayoutDatetimeFields.includes(type)) {
      formattedValue = this.helperService.setUserDateTimeFormat(value, true);
    }

    return formattedValue;
  }

  public openSelectLinePathAssignmentMethodModal(
    selectedLinePath: Pick<ILinePath, 'id' | 'name'>,
    selectLinePathAssignmentMethodModal: TemplateRef<any>,
  ): void {
    this.selectedLinePath = selectedLinePath.id;
    this.selectLinePathAssignmentMethodModalRef = this.ngbModal.open(selectLinePathAssignmentMethodModal, smallModal);
  }

  public assignLinePathOnWorkOrder(selectedMethod: LinePathAssignmentMethods): void {
    this.store.dispatch(
      new HomeActions.AssignWoLinePathJob(
        this.selectedWorkOrder$.value.id,
        this.selectedLinePath,
        this.configuration.lineId,
        selectedMethod,
      ),
    );
  }

  public onClickSelectAnyway(): void {
    if (!this.isOpenedFromHome) {
      this.predictedWorkOrderForSplit = {
        ...this.predictedWorkOrderForSplit,
        predictedWorkOrderName: this.lineInfoThatSelectedWorkOrderData.predictedWorkOrderNumber,
        predictedWorkOrderParentId: this.lineInfoThatSelectedWorkOrderData.selectedWorkOrderId,
      };
    }

    this.workOrderRunningAnotherLineModalRef.close();
  }

  public openMultiLineChangeActivityModal(): void {
    this.multiLineChangeActivityModalRef = this.ngbModal.open(
      this.multiLineChangeActivityModalContent,
      xllModalScrollable,
    );
  }
}
