import { Action } from '@ngrx/store';
import { ILines, LineExcelInterface } from './lines.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { LineAvailabilityPlanDataInterface } from '../../../line-availability/line-availability.model';
import { HttpParams } from '@angular/common/http';
import {
  IBulkEditLine,
  IFormattedLineForm,
  ILineCameraList,
  ITableQuery,
} from '../../../../view/settings/departments-lines-stations/lines/lines.model';
import { DownloadExcelFiltersInterface } from '../../../../shared/service/excel/excel-helper.service';
import { LineCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { BaseOneResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';
import { ILayout } from '../../home-page-display-settings/home-page-display-settings.model';

export const LINES_DATA_LOAD = '[LINES] LINE DATA LOAD';
export const LINES_DATA_LOADED = '[LINES] LINE DATA LOADED';
export const SHIFT_PLAN_DATA_LOAD = '[LINES] SHIFT PLAN DATA LOAD';
export const SHIFT_PLAN_DATA_LOADED = '[LINES] SHIFT PLAN DATA LOADED';
export const DOWNLOAD_LINES_EXCEL = '[LINES] DOWNLOAD LINES EXCEL';
export const DOWNLOAD_LINES_EXCEL_COMPLETED = '[LINES] DOWNLOAD LINES EXCEL COMPLETED';
export const UPLOAD_LINES_EXCEL = '[LINES] UPLOAD LINES EXCEL';
export const UPLOAD_LINES_EXCEL_COMPLETED = '[LINES] UPLOAD LINES EXCEL COMPLETED';
export const LINES_COUNT_LOAD = '[LINES] LINES COUNT LOAD';
export const LINES_COUNT_LOADED = '[LINES] LINES COUNT LOADED';
export const ADD_LINE = '[LINES] ADD LINE';
export const ADD_LINE_COMPLETED = '[LINES] ADD LINE COMPLETED';
export const EDIT_LINE = '[LINES] EDIT LINE';
export const EDIT_LINE_COMPLETED = '[LINES] EDIT LINE COMPLETED';
export const DELETE_LINE = '[LINES] DELETE LINE';
export const DELETE_LINE_COMPLETED = '[LINES] DELETE LINE COMPLETED';
export const BULK_DELETE_LINES = '[LINES] BULK DELETE LINES';
export const BULK_DELETE_LINES_COMPLETED = '[LINES] BULK DELETE LINES COMPLETED';
export const BULK_EDIT_LINES = '[LINES] BULK EDIT LINES';
export const BULK_EDIT_LINES_COMPLETED = '[LINES] BULK EDIT LINES COMPLETED';
export const LAYOUT_DATA_LOAD = '[LINES] LAYOUT DATA LOAD';
export const LAYOUT_DATA_LOADED = '[LINES] LAYOUT DATA LOADED';
export const FETCH_ERROR = '[LINES] FETCH ERROR';

export class LinesDataLoad implements Action {
  readonly type = LINES_DATA_LOAD;

  constructor(public tableQuery: ITableQuery) {}
}

export class LinesDataLoaded implements Action {
  readonly type = LINES_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<ILines>) {}
}

export class ShiftPlanDataLoad implements Action {
  readonly type = SHIFT_PLAN_DATA_LOAD;

  constructor(public httpParams: HttpParams) {}
}

export class ShiftPlanDataLoaded implements Action {
  readonly type = SHIFT_PLAN_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<LineAvailabilityPlanDataInterface>) {}
}

export class LayoutDataLoad implements Action {
  readonly type = LAYOUT_DATA_LOAD;

  constructor(public httpParams: HttpParams) {}
}

export class LayoutDataLoaded implements Action {
  readonly type = LAYOUT_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<ILayout>) {}
}

export class DownloadLinesExcel implements Action {
  readonly type = DOWNLOAD_LINES_EXCEL;
  constructor(public withData: boolean = false, public filters: DownloadExcelFiltersInterface) {}
}

export class DownloadLinesExcelCompleted implements Action {
  readonly type = DOWNLOAD_LINES_EXCEL_COMPLETED;
}

export class UploadLinesExcel implements Action {
  readonly type = UPLOAD_LINES_EXCEL;
  constructor(public lines: LineCRUDInterface[]) {}
}

export class UploadLinesExcelCompleted implements Action {
  readonly type = UPLOAD_LINES_EXCEL_COMPLETED;
  constructor(public payload: (LineExcelInterface & { errorMessages?: string })[]) {}
}

export class LinesCountLoad implements Action {
  readonly type = LINES_COUNT_LOAD;

  constructor(public siteId: number, public limit?: number) {}
}

export class LinesCountLoaded implements Action {
  readonly type = LINES_COUNT_LOADED;

  constructor(public payload: ILines[]) {}
}

export class AddLine implements Action {
  readonly type = ADD_LINE;

  constructor(public line: IFormattedLineForm) {}
}
export class AddLineCompleted implements Action {
  readonly type = ADD_LINE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<LineCRUDInterface>) {}
}

export class EditLine implements Action {
  readonly type = EDIT_LINE;

  constructor(public line: IFormattedLineForm | ILineCameraList, public id: number) {}
}
export class EditLineCompleted implements Action {
  readonly type = EDIT_LINE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<LineCRUDInterface>) {}
}

export class DeleteLine implements Action {
  readonly type = DELETE_LINE;

  constructor(public id: number) {}
}
export class DeleteLineCompleted implements Action {
  readonly type = DELETE_LINE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<LineCRUDInterface>) {}
}

export class DeleteLines implements Action {
  readonly type = BULK_DELETE_LINES;

  constructor(public ids: number[]) {}
}
export class DeleteLinesCompleted implements Action {
  readonly type = BULK_DELETE_LINES_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<LineCRUDInterface>) {}
}

export class EditLines implements Action {
  readonly type = BULK_EDIT_LINES;

  constructor(public lines: IBulkEditLine[]) {}
}
export class EditLinesCompleted implements Action {
  readonly type = BULK_EDIT_LINES_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<LineCRUDInterface>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type LinesActions =
  | LinesDataLoad
  | LinesDataLoaded
  | ShiftPlanDataLoad
  | ShiftPlanDataLoaded
  | LayoutDataLoad
  | LayoutDataLoaded
  | DownloadLinesExcel
  | DownloadLinesExcelCompleted
  | UploadLinesExcel
  | UploadLinesExcelCompleted
  | LinesCountLoad
  | LinesCountLoaded
  | AddLine
  | AddLineCompleted
  | EditLine
  | EditLineCompleted
  | DeleteLine
  | DeleteLineCompleted
  | DeleteLines
  | DeleteLinesCompleted
  | EditLines
  | EditLinesCompleted
  | FetchError;
