import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductConversionService } from './product-conversion.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './product-conversion.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IUnitConversion } from './product-conversion.model';

@Injectable()
export class ProductConversionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductConversionService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getProductConversionData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_CONVERSION_DATA_LOADING),
      switchMap((objectData: ObjectActions.ProductConversionDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getProductConversion(objectData.productId).pipe(
          switchMap((response: GetManyResponseInterface<IUnitConversion>) => {
            return of(new ObjectActions.ProductConversionDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addProductConversion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_PRODUCT_CONVERSION_LOADING),
      switchMap((objectData: ObjectActions.AddProductConversionLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addProductConversion(objectData.payload).pipe(
          switchMap((response) => {
            return of(new ObjectActions.AddProductConversionLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editProductConversion = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_PRODUCT_CONVERSION_LOADING),
      switchMap((objectData: ObjectActions.EditProductConversionLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editProductConversion(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.EditProductConversionLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
