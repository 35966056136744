import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';
import { WorkOrder, WorkOrderHeader } from '../../../view/home/work-order/work-order.model';
import { GetWorkOrderNumbersDataResponseInterface } from '../../../store/home/home.model';
import { ScwMatSelectRule } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';
import {
  ScwMatDatepickerReturnInterface,
  ScwMatDatepickerRule,
} from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { ScwMatPickerReturnInterface } from '../scw-mat-ui/scw-mat-picker/scw-mat-picker.model';
import { IJob } from '../../../store/settings/jobs/jobs.model';
import { DropdownOptionsInterface } from '../../../view/settings/product-settings/products-new-design/products-new-design.model';
import { IWorkOrderSiteDropdown } from '../../../store/work-order-schedule/work-order-schedule.model';
import { IBadge } from '../scw-mat-ui/scw-mat-button/scw-mat-button.model';

interface WoInformationFormBase {
  id?: number;
  workOrderNo: string;
  actionType: string;
  woDueDate?: string | Moment | Date | null;
  quantityOrdered: string;
  productId: number;
  productRealId?: string;
  productName: string;
  productDescription: string;
  versionId: number | string;
  versionName: string;
  productTableId: string;
  workOrderDueDateForNative?: Moment | string;
  siteId?: number;
  productionStage?: string;
  jobId?: number;
  jobSelect?: [Partial<IJob>];
  releaseDate?: Moment | string;
  releaseDateForNative?: string;
  scheduledStartDate?: Moment | string;
  scheduledStartDateForNative?: string;
  scheduledEndDate?: Moment | string;
  scheduledEndDateForNative?: Moment | string;
  scheduledLineId?: string;
  sequenceNumber?: string;
  completed?: number;
  editableFields?: string | string[];
  hasActivityHistory?: boolean;
  hasOnGoingActivity?: boolean;
  productIdDescription?: string;
  operationNumber: string;
  processOrder: string;
  hasParentWorkOrder?: boolean;
  hasChildWorkOrder?: boolean;
  workOrderSplitNumber?: {
    id: number;
    parentWorkOrder: {
      id: number;
      woNumber: string;
    };
  };
  productVersionID?: number | null;
  productVersionName?: string | null;
  job?: Partial<IJob>;
}

export interface WorkOrderInformationFormInterface extends WoInformationFormBase {
  scheduledPreDuration: string | null;
  scheduledPostDuration: string | null;
  scheduledRunDuration: string | null;
  scheduledPreRunLaborHours: string | null;
  scheduledRunLaborHours: string | null;
  scheduledPostRunLaborHours: string | null;
  matrixBasedPostRunDuration: string | null;
  quantityBasedRunDuration: string | null;
  matrixBasedPreRunDuration: string | null;
}

export interface WoInformationFormSubmitInterface extends WoInformationFormBase {
  scheduledPreDuration: string | null;
  scheduledPostDuration: string | null;
  scheduledRunDuration: string | null;
  scheduledPreRunLaborHours: string | null;
  scheduledRunLaborHours: string | null;
  scheduledPostRunLaborHours: string | null;
  matrixBasedPostRunDuration: string | null;
  quantityBasedRunDuration: string | null;
  matrixBasedPreRunDuration: string | null;
  productInfo?: ScwMatPickerReturnInterface;
  productVersionInfo?: ScwMatPickerReturnInterface;
  formattedWoDueDate?: ScwMatDatepickerReturnInterface;
  formattedWorkOrderDueDateForNative?: ScwMatDatepickerReturnInterface;
}

export interface BillOfMaterialItemsInterface {
  componentProductName: string;
  componentProductQuantity: string;
  itemUnitValue: string;
  unit?: string;
  componentUnit?: string;
}

export interface BillOfMaterialInterface {
  name: string;
  quantity: string;
  items: BillOfMaterialItemsInterface[];
}

export interface ProductResourceItemInterface {
  lineTitle: string;
  groupName: string;
  hours: string;
}

export interface ResourcesInterface {
  items: ProductResourceItemInterface[];
  name: string;
  quantity: string;
}

export interface ProductInformationInterface {
  billOfMaterial: BillOfMaterialInterface;
  customers: string;
  productUnit: string;
  productUnitValue: string;
  resources: ResourcesInterface;
}

export interface OeePerformanceWorkOrderInterface {
  name: string;
  size: string;
  productName: string;
  siteName: string;
  lineName: string;
  overallOee: string;
}

export interface WorkOrderLineChangeInterface {
  resp: GetWorkOrderNumbersDataResponseInterface;
  workOrderInformationForm: Partial<WorkOrderInformationFormInterface>;
  genericOutput: WoSelectGenericOutputInterface;
  workOrderModal: NgbModalRef;
}

export interface WoSelectGenericOutputInterface {
  newWorkOrderHeader: WorkOrderHeader;
  workOrderProductText: string;
  predictedWorkOrderSplitInfo?: IPredictedWorkOrderForSplit;
}

export interface IPredictedWorkOrderForSplit {
  predictedWorkOrderName: string | null;
  predictedWorkOrderParentId: number | null;
}

export interface NewWoInformationFormSubmitInterface {
  id?: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  workOrderNo: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  woDueDate: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface | null;
    rules?: ScwMatDatepickerRule[];
  };
  quantityOrdered: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  productId: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  productName?: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  productRealId?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  siteId?: {
    isEnabled: boolean;
    value: IWorkOrderSiteDropdown[];
    rules?: ScwMatSelectRule[];
  };
  productionStage?: {
    isEnabled: boolean;
    value: { id: number; name: string }[] | number | string;
    rules?: ScwMatSelectRule[];
  };
  jobId: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatInputRule[];
  };
  releaseDate?: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface;
    rules?: ScwMatDatepickerRule[];
  };
  scheduledRunDate?: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface;
    rules?: ScwMatDatepickerRule[];
  };
  scheduledEndDate?: {
    isEnabled: boolean;
    value: ScwMatDatepickerReturnInterface;
    rules?: ScwMatDatepickerRule[];
  };
  scheduledLinePathId?: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  scheduledLine?: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatSelectRule[];
  };
  sequenceNumber?: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  completed?: {
    isEnabled: boolean;
    value: number;
    rules?: ScwMatInputRule[];
  };
  editableFields?: {
    isEnabled: boolean;
    value: string | string[];
    rules?: ScwMatInputRule[];
  };
  hasActivityHistory?: {
    isEnabled: boolean;
    value: boolean;
    rules?: ScwMatInputRule[];
  };
  hasOnGoingActivity?: {
    isEnabled: boolean;
    value: boolean;
    rules?: ScwMatInputRule[];
  };
  productDescription?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  scheduledPreDuration: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  scheduledPostDuration: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  scheduledRunDuration: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  processOrder: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  operationNumber: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  scheduledPreRunLaborHours: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  scheduledRunLaborHours: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  scheduledPostRunLaborHours: {
    isEnabled: boolean;
    value: number | string;
    rules?: ScwMatInputRule[];
  };
  parentWorkOrder?: {
    isEnabled: false;
    value: string;
    rules?: ScwMatInputRule[];
  };
  minimumWaitingDuration?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  maximumWaitingDuration?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  canceled?: {
    isEnabled: boolean;
    value: boolean;
    rules?: ScwMatInputRule[];
  };
  isSplitted?: {
    isEnabled: boolean;
    value: { id: number; name: string }[];
    rules?: ScwMatInputRule[];
  };
  productVersion?: {
    isEnabled: boolean;
    value: DropdownOptionsInterface[];
    rules?: ScwMatInputRule[];
  };
  matrixBasedPostRunDuration?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  quantityBasedRunDuration?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
  matrixBasedPreRunDuration?: {
    isEnabled: boolean;
    value: string;
    rules?: ScwMatInputRule[];
  };
}

export enum LinePathAssignmentMethods {
  onlyInitiateCurrentLine = 'onlyInitiateCurrentLine',
  initiateAll = 'initiateAll',
}

export interface IWorkOrderListItem extends WorkOrder {
  badge: IBadge | null;
}
