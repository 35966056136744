import { CustomColors } from 'src/app/shared/service/color/color.model';
import { ManualCountEntrySettingsInterface } from '../line/model';
import { IUserMenuPermissions } from '../user/model';
import { TDecimalSeparator, TThousandSeparator } from '../../../constants.model';
import { IRole } from '../../shared/service/roles/roles.model';

export interface App {
  loading: boolean;
  topLoading: boolean;
  loadingText: string;
  cicoAccess: boolean;
  showTour: boolean;
  activityListUpdate: boolean;
  masking: boolean;
  maintenanceModeData: IMaintenanceModeData | null;
}

export interface GetCurrentUserResponseInterface {
  data: GetCurrentUserResponseDataInterface;
  success: boolean;
}

export interface GetCurrentUserResponseDataInterface {
  selectedLine?: GetCurrentUserResponseSelectedLineInterface;
  selectedSite?: GetCurrentUserResponseSelectedSiteInterface;
  manualCountEntrySettings: ManualCountEntrySettingsInterface;
  client: GetCurrentUserResponseClientInterface;
  userName: string;
  userId: number;
  levelId: number;
  dateFormat: string;
  locale: string;
  authorizedSites: string;
  authorizedLines: string;
  defaultSite: string;
  fullName: string;
  timezone: string;
  email: string;
  isVerifiedByAdmin: boolean;
  isVerifiedByUser: boolean;
  language: string;
  decimalScaleLimit: number;
  decimalSeparator: TDecimalSeparator;
  thousandSeparator: TThousandSeparator;
  defaultSiteInfo: GetCurrentUserResponseDefaultSiteInterface;
  menuPermissions: IUserMenuPermissions;
  level: Partial<IRole>;
}

export interface GetCurrentUserResponseClientInterface {
  client: string;
  clientCode: string;
  gettingDataInterval: number;
  oeeFormula: string;
  isNonVerifiedUserPassActive: boolean;
  configuration: GetCurrentUserClientConfigurationDto | null;
  logbookAccessUrl: string;
  logbookAccess: number;
  oeeTrackerAccess: number;
  schedulerAccess: number;
  appNotificationAccessUrl: string | null;
  inactivateUserWithoutValidation?: number;
  oeeCalculateType?: number;
}

export interface GetCurrentUserResponseSelectedLineInterface {
  manualCountEntrySettings: ManualCountEntrySettingsInterface;
  lineId: number;
  lineName: string;
}

export interface GetCurrentUserResponseSelectedSiteInterface {
  siteId: number;
  siteName: string;
  siteDecimalScaleLimit: number;
  isLaborTrackerActive: boolean;
  isAssetTrackerActive: boolean;
  isDigitalFormsActive: boolean;
  isStationTrackerActive: boolean;
  isOeeTrackerActive: boolean;
  weekStartDay?: number;
}

export interface GetCurrentUserResponseDefaultSiteInterface {
  siteId: number;
  siteName: string;
  decimalScaleLimit: number;
  weekStartDay: number;
}

export interface IMaintenanceModeData {
  isUnderMaintenance: boolean;
  originURL?: string;
}

export interface GetCurrentUserClientConfigurationDto {
  colors: CustomColors;
}

export interface IUserInfoForPageView {
  defaultSite: string;
  userName?: string;
  email?: string;
  userLevel?: string;
  requestBody?: string;
}

export enum EApplicationInsights {
  PAGE_VIEW = 'Microsoft.ApplicationInsights.{0}.Pageview',
  METRICS = 'Microsoft.ApplicationInsights.{0}.Metric',
  PAGE_VIEW_PERFORMANCE = 'Microsoft.ApplicationInsights.{0}.PageviewPerformance',
}
