import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { FilterCardComponent } from '../filter/filter-card/filter-card.component';
import {
  ActivityEventsDataInterface,
  IActivityHistoryTableData,
  IActivityHistoryWithLastComment,
  ILineViewParams,
  IWorkOrderFilter,
} from '../../../store/activity-history/activity-history.model';
import {
  ACTIVITY_HISTORY_BULK_EDIT_ENTRY_VISIBLE_PROPERTY,
  ACTIVITY_HISTORY_SINGLE_EDIT_ENTRY_VISIBLE_PROPERTY,
  EActivityHistoryTableTypes,
  IActivityHistoryTableConfiguration,
  IShownFieldsForActivity,
} from '../../../view/activity-history/activity-history.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { ETableModalTypes } from '../../service/datatable/datatable.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivityEditModalDataInterface } from '../../../view/activity-review/activity-review.model';
import * as ActivityHistoryActions from '../../../store/activity-history/activity-history.actions';
import * as AppActions from '../../../store/app/actions';
import { ActivityHistoryService } from '../../service/activity-history/activity-history.service';
import * as _ from 'lodash';
import { ECloseDialogMessage } from '../../../view/home/work-order/work-order.model';
import { ofType } from '@ngrx/effects';
import { filter, take } from 'rxjs/operators';
import { ActivityHistoryState } from '../../../store/activity-history/activity-history.reducer';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { User } from '../../../store/user/model';
import { MainStateInterface } from '../../../store/main/main.model';
import { HelperService } from '../../service/helper.service';
import { mysqlTimestampFormat } from '../../helper/date';
import { EActivityHistoryTableCalledFrom, ELanguages, ESortType, smallModal } from '../../../../constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatatableHeaderInterface } from '../datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { IAdvancedFilterStore } from '../../../store/advanced-filter/advanced-filter.model';
import { IAdvancedFilterOutput, TargetEndpoints } from '../filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../filter/advanced-filter/advanced-filter.service';
import moment from 'moment/moment';
import { ToastHelperService } from '../../service/toast/toast.helper.service';
import { App } from '../../../store/app/model';
import {
  LinesInterface,
  WorkOrderInterface,
  WorkOrderScheduleStateInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import { LineCRUDInterface, SiteCRUDInterface } from '../filter/filter.class';
import {
  ECommentLogsCommentType,
  ECommentLogsObjectType,
  ICommentCountWithLastCommentMessage,
  ICommentCountWithLastCommentMessageRequestedObject,
} from '../../../store/reports/comment-logs/comment-logs.model';
import * as CommentLogsActions from '../../../store/reports/comment-logs/comment-logs.actions';
import { BorderColors } from '../scw-mat-ui/scw-mat-border-coloring/scw-mat-border-coloring.model';
import { ActivityTypes } from '../../model/enum/activity-types';
import { ActivityFormEnabledFields } from '../activity-form/activity-form.model';

@Component({
  selector: 'app-activity-history-table',
  templateUrl: './activity-history-table.component.html',
  styleUrls: ['./activity-history-table.component.scss'],
})
export class ActivityHistoryTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(FilterCardComponent) filterCard: FilterCardComponent;
  @ViewChild('bulk_error_modal') bulkErrorModalTemplateRef: ViewChildDecorator;
  @Input() searchBoxText: string = '';
  @Input() hasMissingData: boolean = false;
  @Input() configuration: IActivityHistoryTableConfiguration = {
    workOrderId: null,
  };
  @Input() calledFrom: EActivityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom.ACTIVITY_HISTORY;
  @Input() reloadLineViewAndMissingDataEmitter: EventEmitter<EActivityHistoryTableTypes>;

  private readonly subscriptions: Subscription[] = [];
  private readonly successTitleKey: string = this.translate.instant('general.success');
  private readonly successMessageKey: string = this.translate.instant('general.changesSavedSuccessfully');
  private content: TemplateRef<any>;
  private siteId$: number;
  private lineId$: number;
  private activityHistoryEditModalRef: NgbModalRef;
  private activityHistoryDeleteModalRef: NgbModalRef;
  private editWorkOrderTemplateRef: NgbModalRef;
  private searchLineViewAndMissingDataTimeOut: NodeJS.Timeout;
  private lineViewAndMissingDataSearchDelay: number = 1000;

  public readonly ETableModalTypes: typeof ETableModalTypes = ETableModalTypes;
  public readonly activityHistoryTableCalledFromType: typeof EActivityHistoryTableCalledFrom =
    EActivityHistoryTableCalledFrom;
  public selectedHistoryItems: number[] = [];
  public modalType: ETableModalTypes;
  public selectedActivityData: ActivityEditModalDataInterface = {};
  public activityEditShownFields: IShownFieldsForActivity;
  public activityEditEnabledFields: Record<ActivityFormEnabledFields, boolean> = {
    line: true,
    site: true,
    workOrder: true,
  };
  public activityEventsMissingDataLoading$: boolean = false;
  public cancelButtonClicked: Subject<ECloseDialogMessage> = new Subject<ECloseDialogMessage>();
  public activityEventsDataLoading$: boolean = false;
  public errorModalType: string;
  public unsuccessfulActivityBulkOperationData: IActivityHistoryTableData[] = [];
  public bulkErrorModalSuccessfulCount: number = 0;
  public datetimeFormat$: string;
  public selectedActivityHistory: IActivityHistoryTableData[] = [];
  public isFirstTimeOpening: boolean = true;
  public initialLineViewAdvancedFilter: IAdvancedFilterOutput;
  public initialMissingDataAdvancedFilter: IAdvancedFilterOutput;
  public dateFormat$: string;
  public datetimeFormatWithSecond$: string;
  public language$: ELanguages;
  public activityHistoryTableCalledFrom: typeof EActivityHistoryTableCalledFrom = EActivityHistoryTableCalledFrom;

  public missingDataTableHeaders: DatatableHeaderInterface[] = [
    {
      value: null,
      name: '',
      sortable: false,
      width: '50px',
    },
    {
      value: 'workOrder.woNumber',
      name: this.translate.instant('activityHistory.form.workOrderNumber'),
      class: 'nowrap',
      tooltip: this.translate.instant('activityHistory.listView.headers.workOrderTooltip'),
    },
    {
      value: 'activity.name',
      name: this.translate.instant('activityHistory.listView.headers.activity'),
      class: 'nowrap',
    },
    {
      value: 'task.title',
      name: this.translate.instant('activityHistory.listView.headers.task'),
      class: 'nowrap',
      sortable: false,
    },
    {
      value: 'start',
      name: this.translate.instant('activityHistory.listView.headers.start'),
      class: 'nowrap',
    },
    {
      value: '',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds'),
      }),
      class: 'nowrap',
      sortable: false,
    },
  ];
  public missingDataTableData: IActivityHistoryWithLastComment[] = [];
  public missingDataCompletedWoCount: number = 0;
  public missingDataTableDataCount: number = 0;
  public missingDataTableCurrentPage: number = 1;
  public missingDataTableParams: ILineViewParams = {
    page: 1,
    pageSize: 25,
    search: '',
    advancedFilter: null,
    advancedFilterPage: null,
  };

  public lineViewTableHeaders: DatatableHeaderInterface[] = [
    {
      value: null,
      name: '',
      sortable: false,
      width: '50px',
    },
    {
      value: 'workOrder.woNumber',
      name: this.translate.instant('activityHistory.form.workOrderNumber'),
      tooltip: this.translate.instant('activityHistory.listView.headers.workOrderTooltip'),
    },
    {
      value: 'activity.name',
      name: this.translate.instant('activityHistory.listView.headers.activity'),
    },
    {
      value: 'task.title',
      name: this.translate.instant('activityHistory.listView.headers.task'),
      sortable: false,
    },
    {
      value: 'start',
      name: this.translate.instant('activityHistory.listView.headers.start'),
    },
    {
      value: '',
      name: this.translate.instant('general.duration', {
        durationType: this.translate.instant('durationType.hoursAndMinutesAndSeconds'),
      }),
      sortable: false,
    },
  ];
  public lineViewTableData: IActivityHistoryWithLastComment[] = [];
  public lineViewCompletedWOCount: number = 0;
  public lineViewTableDataCount: number = 0;
  public lineViewTableCurrentPage: number = 1;
  public lineViewTableParams: ILineViewParams = {
    page: 1,
    pageSize: 25,
    search: '',
    advancedFilter: null,
    advancedFilterPage: null,
  };
  public sites: SiteCRUDInterface[] = null;
  public lines: LineCRUDInterface[] = null;
  public tableStyle: { [p: string]: string } = { tableLayout: 'fixed' };
  public selectedCompletedWorkOrders: IActivityHistoryTableData[] = [];

  constructor(
    private readonly activityHistoryDeleteModal: NgbModal,
    private readonly activityHistoryBulkErrorModal: NgbModal,
    private readonly activityEditModalNg: NgbModal,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly checkBoxElementRef: ElementRef,
    private readonly toastHelperService: ToastHelperService,
    private store: Store<OeeAppState>,
    private homeActions: ActionsSubject,
    public translate: TranslateService,
    public helperService: HelperService,
  ) {}

  public ngOnInit(): void {
    this.reloadLineViewOrMissingData(
      this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
    );

    if (this.calledFrom === EActivityHistoryTableCalledFrom.ACTIVITY_HISTORY && !this.hasMissingData) {
      this.reloadLineViewOrMissingData(EActivityHistoryTableTypes.MISSING_DATA, true);
    }

    this.subscriptions.push(
      this.cancelButtonClicked.subscribe((cancelValue: ECloseDialogMessage): void => {
        if (cancelValue === ECloseDialogMessage.CLOSE_DIALOG) {
          this.editWorkOrderTemplateRef.close();
        }
      }),

      this.reloadLineViewAndMissingDataEmitter.subscribe((tableToReload: EActivityHistoryTableTypes): void => {
        this.reloadLineViewOrMissingData(tableToReload);
      }),

      this.store.select('user').subscribe((state: User): void => {
        this.datetimeFormat$ = state.dateTimeFormat;
        this.dateFormat$ = state.dateFormat;
        this.datetimeFormatWithSecond$ = state.dateTimeFormatWithSecond;
        this.lineId$ = state.lineId;
        this.siteId$ = state.siteId;
        this.language$ = state.language as ELanguages;
      }),

      this.store.select('app').subscribe((state: App): void => {
        if (state.activityListUpdate) {
          this.store.dispatch(new AppActions.SetActivityListUpdate(false));
          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }
      }),

      combineLatest([
        this.store.select('user').pipe(filter((value: User) => value.isUserLoaded)),
        this.store.select('mainStore').pipe(filter((value: MainStateInterface) => value.getActiveSitesLoaded)),
        this.store.select('activityHistoryStore'),
      ]).subscribe(([user, main, state]: [User, MainStateInterface, ActivityHistoryState]): void => {
        this.activityEventsMissingDataLoading$ = state.activityEventsMissingDataLoading;
        this.activityEventsDataLoading$ = state.activityEventsDataLoading;

        if (!state.activityEventsDataLoading && state.activityEventsDataLoaded) {
          this.lineViewTableData = HelperService.cloneDeep(state.activityEvents).map(
            (item: ActivityEventsDataInterface) => ({
              ...item,
              batchNumber: _.get(item, 'workOrder.woNumber', null),
              isMissingData: ActivityHistoryTableComponent.isMissingData(item),
              start: this.helperService.convertFromISOFormatToGivenTimezone(item.start),
              end: this.helperService.convertFromISOFormatToGivenTimezone(item.end),
              duration: ActivityHistoryTableComponent.getFormattedDuration(item.start, item.end),
              taskName: ActivityHistoryTableComponent.getActivityTaskName(item),
              withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
              activityName: item?.activity?.name,
              activityColor: this.getActivityColor(item.activity.activityType),
              isLine: 0,
            }),
          );

          this.lineViewCompletedWOCount = HelperService.cloneDeep(state.activityEvents).filter(
            (item: ActivityEventsDataInterface) => item.workOrder !== null && item.workOrder.completed,
          ).length;
          this.lineViewTableDataCount = state.activityEventsTotalResults;
          this.lineViewTableCurrentPage = state.activityEventsCurrentPage;

          this.store.dispatch(new AppActions.HideLoader());
          this.selectedActivityData = HelperService.cloneDeep(state.selectedActivityData);
        }

        if (!state.activityEventsMissingDataLoading && state.activityEventsMissingDataLoaded) {
          this.missingDataTableData = HelperService.cloneDeep(state.activityEventsMissingData).map(
            (item: ActivityEventsDataInterface) => ({
              ...item,
              batchNumber: _.get(item, 'workOrder.woNumber', null),
              isMissingData: ActivityHistoryTableComponent.isMissingData(item),
              start: this.helperService.convertFromISOFormatToGivenTimezone(item?.start),
              duration: ActivityHistoryTableComponent.getFormattedDuration(item.start, item.end),
              taskName: ActivityHistoryTableComponent.getActivityTaskName(item),
              withoutWorkOrder: item.task?.meta?.withoutWorkOrder === 1,
              activityName: item?.activity?.name,
              activityColor: this.getActivityColor(item.activity.activityType),
              isLine: 0,
            }),
          );
          this.missingDataCompletedWoCount = HelperService.cloneDeep(state.activityEventsMissingData).filter(
            (item: ActivityEventsDataInterface) => item.workOrder !== null && item.workOrder.completed,
          ).length;
          this.missingDataTableDataCount = state.activityEventsMissingDataTotalResults;
          this.missingDataTableCurrentPage = state.activityEventsMissingDataCurrentPage;
        }

        if (!state.selectedActivityDataLoading && state.selectedActivityDataLoaded) {
          this.selectedActivityData = HelperService.cloneDeep(state.selectedActivityData);
          this.selectedActivityData = {
            ...this.selectedActivityData,
            start: this.helperService.convertFromISOFormatToGivenTimezone(this.selectedActivityData.start),
            end: this.helperService.convertFromISOFormatToGivenTimezone(this.selectedActivityData.end)
          }
          this.activityHistoryEditModalRef = this.activityEditModalNg.open(this.content, {
            windowClass: 'scw-modal-xl scw-modal-all-scrollable',
            keyboard: false,
            backdrop: 'static',
          });
        }
      }),

      this.homeActions
        .pipe(
          ofType(
            ActivityHistoryActions.ActivityHistoryActionTypes.SaveUpdateOrDeleteActivityEventDataCompleted,
            ActivityHistoryActions.ActivityHistoryActionTypes.AcceptFixedVersionForActivityOverlapCompleted,
          ),
        )
        .subscribe((): void => {
          this.selectedHistoryItems = [];

          this.toastHelperService.showToastMessage(
            true,
            this.translate.instant(this.successTitleKey),
            this.translate.instant(this.successMessageKey),
          );

          if (this.activityHistoryDeleteModalRef || this.activityHistoryEditModalRef) {
            this.activityHistoryDeleteModalRef?.close();
            this.activityHistoryEditModalRef?.close();
          }
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.SaveOrUpdateBulkActivityEventDataCompleted))
        .subscribe((res: ActivityHistoryActions.SaveOrUpdateBulkActivityEventDataCompleted): void => {
          this.bulkErrorModalSuccessfulCount = 0;
          this.unsuccessfulActivityBulkOperationData = [];

          for (const [index, result] of Object.entries(res.response.data)) {
            if (result.success) {
              this.bulkErrorModalSuccessfulCount += 1;
            } else {
              this.unsuccessfulActivityBulkOperationData.push(
                _.find(this.lineViewTableData, { id: this.selectedHistoryItems[index] }),
              );
            }
          }

          this.selectedHistoryItems = [];

          if (this.unsuccessfulActivityBulkOperationData.length) {
            this.errorModalType = ETableModalTypes.bulkEdit;

            this.activityHistoryEditModalRef.close();
            this.showBulkErrorModal(this.bulkErrorModalTemplateRef);
          } else {
            this.activityHistoryEditModalRef.close();
            this.toastHelperService.showToastMessage(true, this.successTitleKey, this.successMessageKey);
          }

          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.DeleteManyActivityHistoryDataCompleted))
        .subscribe((response: ActivityHistoryActions.DeleteManyActivityHistoryDataCompleted): void => {
          this.selectedHistoryItems = [];
          this.unsuccessfulActivityBulkOperationData = [];
          this.bulkErrorModalSuccessfulCount = 0;

          for (const [index, result] of Object.entries(response.response.data)) {
            if (result.success) {
              this.bulkErrorModalSuccessfulCount += 1;
            } else {
              this.unsuccessfulActivityBulkOperationData.push(
                _.find(this.lineViewTableData, { id: this.selectedHistoryItems[index] }),
              );
            }
          }

          if (this.unsuccessfulActivityBulkOperationData.length) {
            this.errorModalType = ETableModalTypes.bulkDelete;
            this.activityHistoryDeleteModalRef.close();
            this.showBulkErrorModal(this.bulkErrorModalTemplateRef);
          } else {
            this.activityHistoryDeleteModalRef.close();

            this.toastHelperService.showToastMessage(
              true,
              this.translate.instant(this.successTitleKey),
              this.translate.instant(this.successMessageKey),
            );
          }

          this.reloadLineViewOrMissingData(
            this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
          );
        }),

      this.homeActions
        .pipe(
          ofType(
            ActivityHistoryActions.ActivityHistoryActionTypes.GetActivityEventMissingDataLoaded,
            ActivityHistoryActions.ActivityHistoryActionTypes.GetActivityEventsLoaded,
          ),
        )
        .subscribe((): void => {
          this.selectedHistoryItems = [];

          if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
            this.fetchActivityHistoryCommentCounts();
          }
        }),

      this.homeActions
        .pipe(ofType(ActivityHistoryActions.ActivityHistoryActionTypes.FetchError))
        .subscribe((): void => {
          this.activityHistoryDeleteModalRef?.close();
        }),
      this.homeActions
        .pipe(ofType(CommentLogsActions.COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADED))
        .subscribe((response: CommentLogsActions.CommentLogsCountWithLastMessageDataLoaded) =>
          this.mapActivityCommentsWithLastMessageToActivityLogs(response.payload),
        ),
    );

    if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.subscriptions.push(
        this.store.select('workOrderSchedule').subscribe((state: WorkOrderScheduleStateInterface): void => {
          if (!state.getSitesLoading && state.getSitesLoaded) {
            this.sites = state.sites.data;
          }

          if (!state.getLinesLoading && state.getLinesLoaded) {
            this.lines = state.lines.data;
          }
        }),
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('configuration') && _.get(changes, 'configuration.currentValue', null) !== null) {
      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.lineViewTableHeaders.splice(
          1,
          1,
          ...[
            {
              value: 'workOrder.woNumber',
              name: this.translate.instant('activityHistory.form.workOrderNumber'),
            },
            {
              value: 'line.title',
              name: this.translate.instant('general.line'),
            },
          ],
        );

        this.lineViewTableHeaders.push(
          {
            value: 'activityComment',
            name: this.translate.instant('activityLogs.tableHeaders.activityComments'),
            sortable: false,
          },
          {
            value: 'editAction',
            name: '',
            sortable: false,
          },
        );

        this.missingDataTableHeaders = this.lineViewTableHeaders;
      }

      this.lineViewTableParams.pageSize = 10;
    }

    if (changes.hasOwnProperty('searchBoxText')) {
      clearTimeout(this.searchLineViewAndMissingDataTimeOut);
      const searchBoxText: string = _.get(changes, 'searchBoxText.currentValue', '');
      const isFirstChange: boolean = !!_.get(changes, 'searchBoxText.firstChange', null);

      if (searchBoxText.trim() === '' && isFirstChange) {
        return;
      }

      this.searchLineViewAndMissingDataTimeOut = setTimeout(() => {
        this.lineViewTableParams = { ...this.lineViewTableParams, search: searchBoxText.trim().toLowerCase() };
        this.missingDataTableParams = { ...this.missingDataTableParams, search: searchBoxText.trim().toLowerCase() };

        this.reloadLineViewOrMissingData(
          this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
        );
      }, this.lineViewAndMissingDataSearchDelay);
    }
  }

  public activityEditModal(activityHistoryIds: number[], content: TemplateRef<any>, type: string): void {
    this.content = content;
    const isCalledFromWorkOrders: boolean = this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS;
    this.activityEditShownFields = ACTIVITY_HISTORY_SINGLE_EDIT_ENTRY_VISIBLE_PROPERTY;

    this.activityEditEnabledFields = {
      workOrder: !isCalledFromWorkOrders,
      line: !isCalledFromWorkOrders,
      site: !isCalledFromWorkOrders,
    };

    this.activityHistoryService.timeEntryModalWorkOrdersSubject.next(null);

    this.store.dispatch(new ActivityHistoryActions.FlushActivityEventData());

    if (type === ETableModalTypes.add) {
      this.modalType = ETableModalTypes.add;
      this.selectedActivityData = null;

      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.store
          .select('workOrderSchedule')
          .pipe(take(1))
          .subscribe((state: WorkOrderScheduleStateInterface) => {
            const selectedWorkOrder: WorkOrderInterface = this.getSelectedWorkOrder(state.workOrderScheduleList);
            this.activityHistoryService.timeEntryModalWorkOrdersSubject.next({
              workOrder: {
                ...selectedWorkOrder,
                quantityOrdered: Number(selectedWorkOrder.quantityOrdered),
                canceled: Number(selectedWorkOrder.canceled),
              },
              lineId: selectedWorkOrder?.lastLine ?? this.configuration?.workOrder?.lineId,
              siteId: selectedWorkOrder.siteId,
              batchNumber: selectedWorkOrder.woNumber,
            });
          });
      }

      this.activityHistoryEditModalRef = this.activityEditModalNg.open(content, {
        windowClass: 'scw-modal-xl scw-modal-all-scrollable',
        keyboard: false,
        backdrop: 'static',
      });
    } else if (type === ETableModalTypes.edit) {
      this.modalType = ETableModalTypes.edit;

      if (activityHistoryIds.length === 1) {
        const selectedActivityHistoryId: number = activityHistoryIds[0];

        this.store.dispatch(new AppActions.ShowLoader());
        this.store.dispatch(new ActivityHistoryActions.GetActivityEventData(selectedActivityHistoryId));

        if (this.hasMissingData) {
          this.selectedActivityData = _.find(this.missingDataTableData, { id: selectedActivityHistoryId });
        } else {
          this.selectedActivityData = _.find(this.lineViewTableData, { id: selectedActivityHistoryId });
        }

        return;
      }
      this.modalType = ETableModalTypes.bulkEdit;
      const [woMissingData, activityWithoutWo, taskMissingData] =
        this.activityHistoryService.checkMissingDataConditions(
          activityHistoryIds,
          this.hasMissingData ? this.missingDataTableData : this.lineViewTableData,
        );
      const { lineId, siteId }: { lineId: number; siteId: number } = (_.find(this.lineViewTableData, {
        workOrderId: this.configuration.workOrderId,
      }) as IActivityHistoryTableData) ?? { lineId: this.lineId$, siteId: this.siteId$ };
      this.selectedActivityData = {
        lineId,
        siteId,
        bulkEdit: {
          woMissingData,
          taskMissingData,
          activityWithoutWo,
          selectedBulkActivities: this.selectedHistoryItems,
        },
      };

      if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
        this.store
          .select('workOrderSchedule')
          .pipe(take(1))
          .subscribe((state: WorkOrderScheduleStateInterface) => {
            const selectedWorkOrder: WorkOrderInterface = this.getSelectedWorkOrder(state.workOrderScheduleList);

            this.selectedActivityData.workOrder = {
              id: selectedWorkOrder.id,
              woNumber: selectedWorkOrder.woNumber,
              quantityOrdered: Number(selectedWorkOrder.quantityOrdered),
              product: {
                id: _.get(selectedWorkOrder, 'productTableId', null),
                productId: _.get(selectedWorkOrder, 'productId', null),
                description: _.get(selectedWorkOrder, 'productDescription', null),
              },
            };
          });
      }

      this.activityEditShownFields = {
        ...ACTIVITY_HISTORY_BULK_EDIT_ENTRY_VISIBLE_PROPERTY,
        workOrderNumber: this.calledFrom !== EActivityHistoryTableCalledFrom.WORK_ORDERS,
      };
      this.activityHistoryEditModalRef = this.activityEditModalNg.open(content, {
        windowClass: 'scw-modal-xl scw-modal-all-scrollable',
        keyboard: false,
        backdrop: 'static',
      });
    }
  }

  private getSelectedWorkOrder(workOrderScheduleList: WorkOrderInterface[]): WorkOrderInterface {
    const selectedWorkOrder: WorkOrderInterface = HelperService.cloneDeep(
      _.find(workOrderScheduleList, {
        id: this.configuration.workOrderId,
      }),
    );

    if (selectedWorkOrder) {
      Object.assign(selectedWorkOrder, {
        product: {
          id: _.get(selectedWorkOrder, 'productTableId', null),
          productId: _.get(selectedWorkOrder, 'productId', null),
          description: _.get(selectedWorkOrder, 'productDescription', null),
        },
      });
    }

    if (!selectedWorkOrder) {
      return this.configuration.workOrder as unknown as WorkOrderInterface;
    }

    return selectedWorkOrder;
  }

  public cancelClicked(eventValue: boolean): void {
    if (!eventValue) {
      return;
    }

    this.activityHistoryEditModalRef.close();

    if (this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.reloadLineViewOrMissingData(EActivityHistoryTableTypes.LINE_VIEW);
      this.selectOrUnselectAll(false);
    }
  }

  public deleteActivityHistoryModal(content: TemplateRef<any>, isBulk: boolean): void {
    this.modalType = isBulk ? ETableModalTypes.bulkDelete : ETableModalTypes.delete;
    this.activityHistoryDeleteModalRef = this.activityHistoryDeleteModal.open(content, smallModal);
  }

  public deleteSelectedActivityHistoryData(): void {
    if (this.selectedHistoryItems.length === 1) {
      this.store.dispatch(
        new ActivityHistoryActions.SaveUpdateOrDeleteActivityEventData(
          ...HelperService.argumentClone(null, this.selectedHistoryItems[0]),
        ),
      );

      return;
    }

    this.store.dispatch(
      new ActivityHistoryActions.DeleteManyActivityHistoryData(
        ...HelperService.argumentClone(this.selectedHistoryItems),
      ),
    );
  }

  public selectOrUnselectAll(isSelectAll: boolean): void {
    if (this.checkBoxElementRef.nativeElement.querySelector('[id^="line-view-checkbox-"]') === null) {
      return;
    }

    (this.hasMissingData ? this.missingDataTableData : this.lineViewTableData).forEach(
      (item: IActivityHistoryTableData): void => {
        const checkbox = _.head(document.getElementById(`line-view-checkbox-${item.id}`).getElementsByTagName('input'));

        if ((isSelectAll && !checkbox.checked) || (!isSelectAll && checkbox.checked)) {
          checkbox.click();
        }
      },
    );
  }

  public onActivityHistoryCheckboxChange(event: MatCheckboxChange): void {
    const inputValue: number = Number(event.source.value);

    if (event.checked) {
      this.selectedHistoryItems.push(inputValue);
    } else {
      const index: number = this.selectedHistoryItems.indexOf(inputValue);

      this.selectedHistoryItems.splice(index, 1);
    }

    this.findSelectedCompletedWorkOrders(this.selectedHistoryItems);

    this.selectedActivityHistory = _.head(!this.hasMissingData ? this.lineViewTableData : this.missingDataTableData);
  }

  public findSelectedCompletedWorkOrders(workOrderIds: number[]): void {
    this.selectedCompletedWorkOrders = [];
    let workOrders: IActivityHistoryTableData[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    this.selectedCompletedWorkOrders = workOrders.filter(
      (workOrder: IActivityHistoryTableData) =>
        workOrderIds.includes(workOrder.id) && workOrder.workOrder.completed === 1,
    );
  }

  public onDataRequestHandler(params, table): void {
    let query: IWorkOrderFilter = {
      page: 0,
      pageSize: 0,
      search: '',
      advancedFilter: null,
      advancedFilterPage: null,
    };

    this.store
      .select('activityHistoryStore')
      .pipe(take(1))
      .subscribe((state) => {
        query.advancedFilter = _.get(state.filters, 'advancedFilter', null);
      });

    if (params.advancedFilter) {
      query.advancedFilter = params.advancedFilter;
    }

    if (params.page) {
      query.page = params.page;
    }

    if (params.pageSize || params.rowsPerPage) {
      query.pageSize = params.pageSize || Number(params.rowsPerPage);
    }

    if (this.searchBoxText) {
      query.search = this.searchBoxText;
    }

    if (params.sort && params.sort.type !== ESortType.NONE) {
      query.orderBy = params.sort.column;

      if (params.sort.type === ESortType.ASCENDING) {
        query.orderDesc = '';
      }

      if (params.sort.type === ESortType.DESCENDING) {
        query.orderDesc = 1;
      }
    }

    if (params.orderBy) {
      query.orderBy = params.orderBy;
      query.orderDesc = params.orderDesc;
    }

    switch (table) {
      case EActivityHistoryTableTypes.LINE_VIEW:
        this.lineViewTableParams = query;
        break;
      case EActivityHistoryTableTypes.MISSING_DATA:
        this.missingDataTableParams = query;
        break;
    }

    this.reloadLineViewOrMissingData(table);
  }

  public onClickRow($event): void {
    const { event, item } = $event;

    const index: number = Array.from(event.target.parentElement.children).indexOf(event.target);

    if (
      index !== 0 ||
      event.target.classList.contains('mat-checkbox-inner-container') ||
      event.target.tagName !== 'TD'
    ) {
      return;
    }

    _.head(document.getElementById(`line-view-checkbox-${item.id}`).getElementsByTagName('input')).click();
  }

  public onLastCommentModalClosed(): void {
    this.selectedHistoryItems = [];

    this.reloadLineViewOrMissingData(
      this.hasMissingData ? EActivityHistoryTableTypes.MISSING_DATA : EActivityHistoryTableTypes.LINE_VIEW,
    );
  }

  private static isMissingData(item: ActivityEventsDataInterface): boolean {
    return (
      (item.activity.activityType !== 'runTime' &&
        (item.taskId === null || item.taskId === '' || item.task.isMissingData === 1)) ||
      item.activityId === null ||
      item.activityId === '' ||
      item.start === null ||
      item.start === '' ||
      item.end === null ||
      item.end === '' ||
      (item.activity.activityType !== 'idleTime' && item.workOrderId === null)
    );
  }

  private static getActivityTaskName(item: ActivityEventsDataInterface): string {
    if (item.activity.activityType === 'runTime') {
      return _.get(item, 'workOrder.product.description', '');
    }

    return _.get(item, 'task.title', '');
  }

  private static getFormattedDuration(startDate: string, endDate: string): string {
    const start: moment.Moment = moment(startDate, mysqlTimestampFormat);
    const end: moment.Moment = moment(endDate, mysqlTimestampFormat);
    const duration: moment.Duration = moment.duration(end.diff(start));

    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm:ss');
  }

  public showBulkErrorModal(templateRef: ViewChildDecorator): void {
    this.activityHistoryDeleteModalRef = this.activityHistoryBulkErrorModal.open(templateRef, smallModal);
  }

  private reloadLineViewOrMissingData(
    tableToReload: EActivityHistoryTableTypes = null,
    getCountOnly: boolean = false,
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.isFirstTimeOpening && this.calledFrom !== EActivityHistoryTableCalledFrom.WORK_ORDERS) {
      this.performFirstTimeOperations(tableToReload);

      this.isFirstTimeOpening = false;

      return;
    }

    if (!tableToReload || tableToReload === EActivityHistoryTableTypes.LINE_VIEW) {
      this.store
        .select('activityHistoryStore')
        .pipe(take(1))
        .subscribe((state: ActivityHistoryState) => {
          this.lineViewTableParams.advancedFilter = _.get(state.filters, 'lineViewAdvancedFilter', null);
          this.lineViewTableParams.advancedFilterPage = _.get(state.filters, 'lineViewAdvancedFilterPage', null);
        });

      this.store.dispatch(
        new ActivityHistoryActions.GetActivityEvents(
          this.lineViewTableParams.page,
          !getCountOnly ? this.lineViewTableParams.pageSize : 1,
          this.lineViewTableParams.orderDesc,
          this.lineViewTableParams.orderBy,
          this.lineViewTableParams.search,
          this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
            ? null
            : this.lineViewTableParams.advancedFilter,
          this.configuration.workOrderId,
        ),
      );
    }

    if (!tableToReload || tableToReload === EActivityHistoryTableTypes.MISSING_DATA) {
      this.store
        .select('activityHistoryStore')
        .pipe(take(1))
        .subscribe((state: ActivityHistoryState) => {
          this.missingDataTableParams.advancedFilter = _.get(state.filters, 'missingDataAdvancedFilter', null);
          this.missingDataTableParams.advancedFilterPage = _.get(state.filters, 'missingDataAdvancedFilterPage', null);
        });

      this.store.dispatch(
        new ActivityHistoryActions.GetActivityEventMissingData(
          this.missingDataTableParams.page,
          !getCountOnly ? this.missingDataTableParams.pageSize : 1,
          this.missingDataTableParams.orderDesc,
          this.missingDataTableParams.orderBy,
          this.missingDataTableParams.search,
          this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
            ? null
            : this.missingDataTableParams.advancedFilter,
          this.configuration.workOrderId,
        ),
      );
    }
  }

  private performFirstTimeOperations(tableToReload: EActivityHistoryTableTypes = null): void {
    this.store
      .select('advancedFilterStore')
      .pipe(
        filter((state: IAdvancedFilterStore) => !state.isDefaultFiltersLoading && state.isDefaultFiltersLoaded),
        take(1),
      )
      .subscribe((state: IAdvancedFilterStore) => {
        const pageName: string = 'activity-history';

        this.lineViewTableParams.advancedFilter = {
          filters: [],
          target: TargetEndpoints.NestJSCrud,
          page: pageName,
        };

        this.missingDataTableParams.advancedFilter = {
          filters: [],
          target: TargetEndpoints.NestJSCrud,
          page: pageName,
        };

        const isPageNameEmpty: boolean = _.isEmpty(_.get(state.defaultFilters, pageName, null));

        if (!isPageNameEmpty) {
          this.lineViewTableParams.advancedFilterPage = pageName;
          this.lineViewTableParams.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
            state.defaultFilters[pageName],
          );

          this.missingDataTableParams.advancedFilterPage = pageName;
          this.missingDataTableParams.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
            state.defaultFilters[pageName],
          );

          this.initialLineViewAdvancedFilter = this.lineViewTableParams.advancedFilter;
          this.initialMissingDataAdvancedFilter = this.missingDataTableParams.advancedFilter;
          this.store.dispatch(
            new ActivityHistoryActions.SetSelectedFilters({
              lineViewAdvancedFilter: this.lineViewTableParams.advancedFilter,
              lineViewAdvancedFilterPage: this.lineViewTableParams.advancedFilterPage,
              missingDataAdvancedFilter: this.missingDataTableParams.advancedFilter,
              missingDataAdvancedFilterPage: this.missingDataTableParams.advancedFilterPage,
            }),
          );
        }

        if (!tableToReload || tableToReload === EActivityHistoryTableTypes.LINE_VIEW) {
          this.store.dispatch(
            new ActivityHistoryActions.GetActivityEvents(
              this.lineViewTableParams.page,
              this.lineViewTableParams.pageSize,
              this.lineViewTableParams.orderDesc,
              this.lineViewTableParams.orderBy,
              this.lineViewTableParams.search,
              this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
                ? null
                : this.lineViewTableParams.advancedFilter,
              this.configuration.workOrderId,
            ),
          );
        }

        if (!tableToReload || tableToReload === EActivityHistoryTableTypes.MISSING_DATA) {
          this.store.dispatch(
            new ActivityHistoryActions.GetActivityEventMissingData(
              this.missingDataTableParams.page,
              this.missingDataTableParams.pageSize,
              this.missingDataTableParams.orderDesc,
              this.missingDataTableParams.orderBy,
              this.missingDataTableParams.search,
              this.calledFrom === EActivityHistoryTableCalledFrom.WORK_ORDERS
                ? null
                : this.missingDataTableParams.advancedFilter,
            ),
          );
        }
      });
  }

  private fetchActivityHistoryCommentCounts(): void {
    const rawData: IActivityHistoryTableData[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    if (!rawData || rawData.length === 0) {
      return;
    }

    const objectsToFetchCommentCounts: ICommentCountWithLastCommentMessageRequestedObject[] = rawData.map(
      (activityHistory: IActivityHistoryTableData) => ({
        objectId: Number(activityHistory.id),
        objectType: ECommentLogsObjectType.activityHistory,
      }),
    );

    this.store.dispatch(
      new CommentLogsActions.CommentLogsCountWithLastMessageDataLoading({
        commentType: ECommentLogsCommentType.activityComment,
        requestedObjects: objectsToFetchCommentCounts,
        containAllCommentMessages: true,
      }),
    );
  }

  private mapActivityCommentsWithLastMessageToActivityLogs(
    commentCountsWithLastMessage: ICommentCountWithLastCommentMessage[],
  ): void {
    if (!commentCountsWithLastMessage) {
      return;
    }

    const targetTableData: IActivityHistoryWithLastComment[] = this.hasMissingData
      ? this.missingDataTableData
      : this.lineViewTableData;

    targetTableData.forEach((activityHistory: IActivityHistoryWithLastComment, index: number) => {
      const objectType: ECommentLogsObjectType = ECommentLogsObjectType.activityHistory;
      const objectId: number = Number(activityHistory.id);

      const commentCountWithLastMessage: ICommentCountWithLastCommentMessage | null =
        commentCountsWithLastMessage.find(
          (countWithLastCommentMessage: ICommentCountWithLastCommentMessage) =>
            Number(countWithLastCommentMessage.objectId) === Number(objectId) &&
            countWithLastCommentMessage.objectType === objectType,
        ) ?? null;

      targetTableData[index].commentCountWithLastCommentMessage = commentCountWithLastMessage
        ? commentCountWithLastMessage
        : { lastCommentMessage: '', totalCommentCount: 0, objectId, objectType };
    });
  }

  private getActivityColor(activityType: ActivityTypes): BorderColors {
    switch (activityType) {
      case ActivityTypes.RUN_TIME:
        return BorderColors.runtime;
      case ActivityTypes.IDLE_TIME:
        return BorderColors.idleTime;
      case ActivityTypes.DOWN_TIME:
        return BorderColors.unplannedDownTime;
      case ActivityTypes.DOWN_TIME_PLANNED:
        return BorderColors.plannedDownTime;
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
