<div class="site-line-card row" [class.select]="actionType === 'select'" [class.edit]="actionType === 'edit'">
  <div class="select-site col-lg-12">
    <h2 class="mb-4 scw-header site-line-header">
      <i class="fas fa-building"></i> {{ 'siteLineSelection.sites' | translate }}
    </h2>
    <div class="row">
      <div *ngFor="let site of sites" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
        <scw-mat-button
          size="lg"
          type="standard"
          [block]="true"
          [className]="{
            active:
              (selectedSiteId === null && defaultSite$ !== null && defaultSite$ !== site.id) ||
              selectedSiteId === site.id
          }"
          (onClick)="onClickSite(site.id, site.name)"
        >
          {{ site.name }}
        </scw-mat-button>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="linesOfSelectedSite.length > 0">
    <div class="select-line col-lg-12">
      <hr />
      <div class="row">
        <div
          class="col-xs-12"
          [ngClass]="
            selectedSiteHasFloorPlan ? 'col-sm-12 col-md-12 col-lg-6 col-xl-7' : 'col-sm-6 col-md-8 col-lg-9 col-xl-10'
          "
        >
          <h2 class="mb-4 scw-header site-line-header">
            <i class="fas fa-pallet"></i>
            {{
              selectedSiteHasFloorPlan
                ? ('siteLineSelection.linesAndLayoutPlans' | translate)
                : ('siteLineSelection.lines' | translate)
            }}
          </h2>
        </div>
        <div
          class="col-xs-12"
          [ngClass]="
            selectedSiteHasFloorPlan
              ? 'col-sm-12 col-md-12 col-lg-6 col-xl-5 d-flex justify-content-end'
              : 'col-sm-6 col-md-4 col-lg-3 col-xl-2'
          "
        >
          <div class="row" [class.w-100]="selectedSiteHasFloorPlan">
            <div *ngIf="selectedSiteHasFloorPlan" class="col-7 d-flex justify-content-end">
              <scw-mat-button-group
                *ngIf="selectedSiteHasFloorPlan"
                [buttons]="lineOrFloorPlanModeButtonGroup"
                [(inputModel)]="siteLineSelectionMode"
                (inputModelChange)="siteLineSelectionModeChange()"
              >
              </scw-mat-button-group>
            </div>
            <div class="pl-0" [ngClass]="selectedSiteHasFloorPlan ? 'col-5' : 'col-12'">
              <scw-mat-search
                [placeholder]="
                  siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS
                    ? ('siteLineSelection.searchLayoutPlan' | translate)
                    : ('general.searchLine' | translate)
                "
                [minLength]="0"
                [block]="true"
                [preventSameSearchString]="false"
                (onSearch)="onSearch()"
                [(inputModel)]="searchString"
                data-automation-id="line-search-field"
              ></scw-mat-search>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="siteLineSelectionMode === ESiteLineSelectionMode.LINES">
        <div *ngFor="let lineType of lineTypesOfSelectedSite">
          <h4 class="scw-header department">{{ lineType.lineType }}</h4>
          <div class="row">
            <ng-container *ngFor="let line of linesOfSelectedSite">
              <div
                *ngIf="
                  lineType.id === line.lineType &&
                  (searchString === '' || line.title.toLowerCase().includes(searchString.toLowerCase()))
                "
                class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15"
              >
                <scw-mat-button
                  size="lg"
                  type="standard"
                  [block]="true"
                  [className]="{ active: selectedLineId === line.id }"
                  (onClick)="onClickLine(line.id, line.title)"
                >
                  {{ line.title }}
                </scw-mat-button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS">
        <h4 class="scw-header department">{{ 'siteLineSelection.layoutPlans' | translate }}</h4>
        <div class="row">
          <div
            *ngFor="let floorPlan of floorPlansOfSelectedSite"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15 d-flex flex-column justify-content-center"
          >
            <scw-mat-button
              size="lg"
              type="standard"
              [block]="true"
              [className]="{ active: selectedFloorPlanId === floorPlan.id }"
              (onClick)="onClickFloorPlan(floorPlan.id, floorPlan.name)"
            >
              {{ floorPlan.name }}
            </scw-mat-button>
            <scw-mat-button class="f-14" type="ghost" [block]="true" (onClick)="openFloorPlanPreviewModal(floorPlan)">
              <div>
                <em class="fas fa-eye preview-color f-14"></em>
                <span class="preview-color text-decoration-underline pl-1 p-0 f-14">{{
                  'general.dataTable.header.preview' | translate
                }}</span>
              </div>
            </scw-mat-button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="errors.length > 0">
    <div class="col-12">
      <div class="alert alert-info">
        <ul *ngFor="let error of errors">
          <li>{{ error }}</li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="actionType === 'select'" class="bottom-confirmation-card">
  <scw-mat-button
    [disabled]="
      (siteLineSelectionMode === ESiteLineSelectionMode.LINES && !selectedLineId) ||
      (siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS && !selectedFloorPlanId) ||
      !selectedSiteId ||
      updateCurrentUserLoading$
    "
    (click)="selectDestination()"
    data-automation-id="select-site-and-line-btn"
  >
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {{
      siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS
        ? ('siteLineSelection.goToLayoutPlan' | translate)
        : ('siteLineSelection.start' | translate)
    }}
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </scw-mat-button>
</div>
