import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DeletePlanCRUDResponseInterface,
  LineAvailabilityPlanItemInterface,
  LineAvailabilityResponseInterface,
  SchedulerPlanCRUDResponseInterface,
  SchedulerShiftAlternatePlanInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { EventModel } from '@bryntum/schedulerpro';
import moment, { Moment } from 'moment';

export enum ItemTypeEnum {
  SHIFT_PLAN_ITEM = 'SHIFT_PLAN_ITEM',
  EXCEPTION_PLAN_ITEM = 'EXCEPTION_PLAN_ITEM',
}

@Injectable({
  providedIn: 'root',
})
export class PlanItemService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private SCHEDULER_PLAN_ITEM_BASE_URL = '/scheduler/shift-plan-items';
  private SCHEDULER_PLAN_ITEM = {
    GET: {
      GET_ALL_PLANS: `${this.baseUrl}${this.SCHEDULER_PLAN_ITEM_BASE_URL}`,
    },
    DELETE: {
      DELETE_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_ITEM_BASE_URL}`,
    },
    POST: {
      SAVE_PLAN_ITEMS: `${this.baseUrl}/scheduler/shift-plans`,
      SAVE_EXCEPTION_PLAN_ITEMS: `${this.baseUrl}/scheduler/shift-exceptions`,
    },
    PATCH: {
      EDIT_PLAN: `${this.baseUrl}${this.SCHEDULER_PLAN_ITEM_BASE_URL}`,
    },
  };

  public getData(
    planId: number,
    planType: ItemTypeEnum,
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ): Observable<LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>> {
    let searchString = '';
    const queryString: string[] = [`page=${query.page}`, `limit=${query.pageSize}`];
    const searchObj = {};

    if (query.hasOwnProperty('search') && query.search.length > 0) {
      _.set(searchObj, 'name.$cont', query.search);
    }

    if (query.hasOwnProperty('dateRange')) {
      _.set(searchObj, 'exceptionDate.$between', [
        query.dateRange.startDate.format('YYYY-MM-DD'),
        query.dateRange.endDate.format('YYYY-MM-DD'),
      ]);
    }

    if (planType === ItemTypeEnum.SHIFT_PLAN_ITEM) {
      _.set(searchObj, 'schedulerShiftPlanId.$eq', planId);
      _.set(searchObj, 'schedulerShiftExceptionId.$isnull', true);
      searchString += 'sort=weekDay,ASC&';
    }

    if (planType === ItemTypeEnum.EXCEPTION_PLAN_ITEM) {
      _.set(searchObj, 'schedulerShiftExceptionId.$eq', planId);
    }

    if (query.hasOwnProperty('orderDesc') && query.hasOwnProperty('orderBy')) {
      searchString += `&sort=${query.orderBy},${query.orderDesc ? 'DESC' : 'ASC'}`;
    }

    if (Object.keys(searchObj).length > 0) {
      queryString.push(`s=${JSON.stringify(searchObj)}`);
    }
    queryString.forEach((params: string) => (searchString += `${params}&`));

    return this.http.get<LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>>(
      `${this.SCHEDULER_PLAN_ITEM.GET.GET_ALL_PLANS}?${searchString}`,
    );
  }

  public deleteData(id: number): Observable<DeletePlanCRUDResponseInterface<LineAvailabilityPlanItemInterface>> {
    return this.http.delete<DeletePlanCRUDResponseInterface<LineAvailabilityPlanItemInterface>>(
      `${this.SCHEDULER_PLAN_ITEM.DELETE.DELETE_PLAN}${id}`,
    );
  }

  public savePlanItems(
    payload: EventModel[],
    planId: {
      planId: number;
      exceptionPlanId?: number;
    },
    type: ItemTypeEnum,
    alternatePlans?: SchedulerShiftAlternatePlanInterface[],
    newPlanName?: string,
  ): Observable<LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>> {
    let url: string = '';
    let body: any = {};
    const data = this.preparePlanItems(payload, type);
    if (type === ItemTypeEnum.SHIFT_PLAN_ITEM) {
      url = `${this.SCHEDULER_PLAN_ITEM.POST.SAVE_PLAN_ITEMS}/${planId.planId}/plan-items`;
      body = {
        newPlanName,
        schedulerShiftPlanItems: data,
        schedulerShiftAlternatePlans: alternatePlans,
      };
    }
    if (type === ItemTypeEnum.EXCEPTION_PLAN_ITEM) {
      url = `${this.SCHEDULER_PLAN_ITEM.POST.SAVE_EXCEPTION_PLAN_ITEMS}/${planId.exceptionPlanId}/plan-items`;
      body = {
        schedulerShiftPlanExceptionItems: data,
      };
    }
    return this.http.patch<LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>>(url, body);
  }

  public editData(
    id: number,
    payload,
  ): Observable<SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanItemInterface>> {
    return this.http.patch<SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanItemInterface>>(
      `${this.SCHEDULER_PLAN_ITEM.PATCH.EDIT_PLAN}${id}`,
      payload,
    );
  }

  private preparePlanItems(payload: EventModel[], type: ItemTypeEnum) {
    const returnValue = [];
    payload.forEach((item) => {
      // @ts-ignore
      if (!item.isDisabledZone) {
        const startDate = moment(item.startDate);
        const endDate = moment(item.endDate);

        const newPlanItem: Partial<LineAvailabilityPlanItemInterface> = {
          startHour: startDate.format('HH:mm'),
          endHour: endDate.format('HH:mm'),
          schedulerShiftId: +_.get(item, 'shiftId', null),
        };

        if (type === ItemTypeEnum.SHIFT_PLAN_ITEM) {
          if (_.inRange(Number(item.resourceId), 8, 15)) {
            newPlanItem.orders = 1;
            newPlanItem.weekDay = Number(item.resourceId) - 8;
          } else if (_.inRange(Number(item.resourceId), 15, 22)) {
            newPlanItem.orders = 2;
            newPlanItem.weekDay = Number(item.resourceId) - 15;
          } else if (_.inRange(Number(item.resourceId), 22, 29)) {
            newPlanItem.orders = 3;
            newPlanItem.weekDay = Number(item.resourceId) - 22;
          } else {
            newPlanItem.orders = 0;
            newPlanItem.weekDay = Number(item.resourceId) - 1;
          }

          returnValue.push(newPlanItem);
        }

        if (type === ItemTypeEnum.EXCEPTION_PLAN_ITEM) {
          const data = this.divideItemsToDays(startDate, endDate, newPlanItem);
          returnValue.push(...data);
        }
      }
    });

    return returnValue;
  }

  private divideItemsToDays(
    startDate: Moment,
    endDate: Moment,
    exceptionPlanItem: Partial<LineAvailabilityPlanItemInterface>,
  ): any {
    const diff = endDate.diff(startDate, 'h', true);
    const item: Partial<LineAvailabilityPlanItemInterface> = _.clone(exceptionPlanItem);
    item.exceptionDate = startDate.format('YYYY-MM-DD');
    item.weekDay = startDate.weekday();
    item.startHour = startDate.format('HH:mm');
    if (diff <= 24) {
      item.endHour = endDate.format('HH:mm');
      return [item];
    }
    item.exceptionDate = startDate.format('YYYY-MM-DD');
    item.endHour = '00:00';
    const duration = moment(item.exceptionDate).add(1, 'd').startOf('d').diff(startDate, 'h');
    return [item, ...this.divideItemsToDays(startDate.add(duration, 'h'), endDate, exceptionPlanItem)];
  }
}
