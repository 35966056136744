import { HttpErrorResponse } from '@angular/common/http';
import { PreparedFilterDataInterface } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import { IObjectWithEntityTranslations } from '../../shared/service/entity-translator/entity-translator.model';

export interface WorkOrdersSummaryStateInterface {
  shifts: Shift[];
  workOrdersSummaryData: WorkOrdersSummaryDataInterface[];
  totalDurations: WorkOrdersSummaryTotalDurationsInterface;
  taskGroups: TaskGroupsInterface[];
  shiftIsLoaded: boolean;
  shiftIsLoading: boolean;
  workOrdersSummaryIsLoaded: boolean;
  workOrdersSummaryIsLoading: boolean;
  commentsIsLoading: boolean;
  isFiltersLoading: boolean;
  isFiltersLoaded: boolean;
  isFiltersSaving: boolean;
  filters: PreparedFilterDataInterface;
  selectedFilters: SelectedFilters;
  setSelectedFiltersIsLoading: boolean;
  columnNames: ColumnNames;
  shiftComments: ShiftCommentsInterface[];
  errors: HttpErrorResponse[];
}

export interface SelectedFilters {
  filters: PreparedFilterDataInterface;
  shiftInformation: ShiftInformationInterface;
}

export interface Shift {
  client: string;
  id: number;
  shiftInfo: string;
  shiftName: string;
  weekDayEnd: number;
  weekDayStart: number;
  formattedEndDate: string;
  formattedStartDate: string;
}

export interface WorkOrdersSummaryDataInterface extends IObjectWithEntityTranslations{
  lineId: number;
  lineName: string;
  workOrderId: number;
  workOrder: string;
  productId: number;
  productDescription: string;
  checkedInOperators: string;
  laborHour: string;
  preRunPhaseDuration: string;
  runPhaseDuration: string;
  postRunPhaseDuration: string;
  commentId: number;
  comments: string;
  taskGroups: TaskGroupDataInterface[];
  commentDate: string;
  maxEndDate: string;
  minStartDate: string;
  workOrderPreRunPhaseComment: string;
  workOrderRunPhaseComment: string;
  workOrderPostRunPhaseComment: string;
  workOrderPreRunPhaseCommentCreatedAt: string;
  workOrderRunPhaseCommentCreatedAt: string;
  workOrderPostRunPhaseCommentCreatedAt: string;
  workOrderPreRunPhaseCommentChangedAt: string;
  workOrderRunPhaseCommentChangedAt: string;
  workOrderPostRunPhaseCommentChangedAt: string;
  dueDate: string | null;
  dueDateFormatted: string | null;
  quantityOrdered: string;
  woPlanned?:string;
  woActual?:string;
  woVar?:string;
  woPlannedSecond?:string;
  woActualSecond?:string;
  woVarSecond?:string;
  percentage?:string;
}

export interface WorkOrdersSummaryTotalDurationsInterface {
  laborHour: string;
  preRunPhaseDuration: string;
  runPhaseDuration: string;
  postRunPhaseDuration: string;
  woPlanned: string;
  woActual: string;
  woVar: string;
  percentage?: string;
  quantityOrdered: string;
  dueDate: string | null;
  groups: TaskGroupDataInterface[];
}

export interface TaskGroupDataInterface {
  id: number;
  name: string;
  value: number;
  seconds: number;
}

export interface TaskGroupsInterface {
  id: number;
  name: string;
}

export interface ColumnNames {
  preRunColumnName: string;
  postRunColumnName: string;
  runColumnName: string;
}

export interface ShiftCommentsInterface {
  commentId: number;
  commentMessage: string;
  shiftId: number;
  shiftDay: string;
  shiftName: string;
  commentTypeId: number;
}

export interface WorkOrdersSummaryResponseInterface {
  data : {
    totalDurations: WorkOrdersSummaryTotalDurationsInterface
    data: WorkOrdersSummaryDataInterface[];
    taskGroups: TaskGroupsInterface[];
    columnNames: ColumnNames;
    shiftComments: ShiftCommentsInterface[];
  };
  success: boolean;
}

export interface WorkOrdersSummaryFilterInterface {
  site: number[];
  department: number[];
  observation: number[];
}

export interface WorkOrdersSummaryShiftResponseDataInterface {
  disabled: boolean;
  shifts: Shift[];
}

export enum ShiftInformation {
  currentShift = 'current_shift',
  previousShift = 'previous_shift',
  other = 'other',
}

export type ShiftInformationInterface = {
  shiftInformation?: ShiftInformation;
  shiftDate?: string;
  shiftId?: number;
};
