import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IMicrostop,
  IMicrostopActiveLine,
  IMicrostopsActivityHistoriesElasticRequest,
  IMicrostopsActivityHistoriesElasticResponse,
  IMicrostopsActivityHistory,
  IMicrostopsActivityHistorySource,
  IMicrostopsAnalysisActivityData,
  IMicrostopsAnalysisSensorData,
  IMicrostopsAnalysisTableQuery,
  IMicrostopsDeviceMessage,
  IMicrostopsDeviceMessagesElasticRequest,
  IMicrostopsDeviceMessagesElasticResponse,
} from './microstops.model';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ChartsComponent } from '../../../view/reports/root-cause-analysis/charts/charts.component';
import { ILineData, IRequestBody } from '../root-cause-analysis/root-cause-analysis.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { mysqlTimestampFormat } from '../../../shared/helper/date';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { OeeAppState } from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { FilterLineState } from '../../filter/line/line.reducer';

@Injectable({
  providedIn: 'root',
})
export class MicrostopsService {
  private readonly routes: {
    deviceMessagesSearch: string;
    activityHistoriesSearch: string;
    lines: string;
    microstops: string;
    microstopActiveLines: string;
    sensorHistogram: string;
  } = {
    deviceMessagesSearch: '/device-messages/search',
    activityHistoriesSearch: '/activity-histories/search',
    lines: `${this.apiUrl}/lines`,
    microstops: `${this.apiUrl}/microstops`,
    microstopActiveLines: `${this.apiUrl}/lines/microstop-active-lines`,
    sensorHistogram: `${this.apiUrl}/sensor-data/sensor-histogram`,
  };

  constructor(
    @Inject('PROXY_URL') private readonly proxyUrl: string,
    @Inject('API_BASE_URL') private readonly apiUrl: string,
    private readonly http: HttpClient,
    private readonly store: Store<OeeAppState>,
  ) {}

  public static formatDeviceMessageData(
    data: IMicrostopsDeviceMessagesElasticResponse,
    timezone: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ): IMicrostopsAnalysisSensorData[] {
    const formattedSensorData: IMicrostopsAnalysisSensorData[] = [];

    data.aggregations.deviceStart.buckets.forEach((deviceMessage: IMicrostopsDeviceMessage) => {
      const sensorCount: number = deviceMessage.sensor_counts.sum_sensor_count.value;
      const zeroCount: number = deviceMessage.zero_counts.doc_count;
      const isMicroStop: boolean = !!zeroCount;
      const isAlive: boolean = !!(sensorCount || zeroCount);
      const createdAt: number = moment(
        moment(deviceMessage.key_as_string).utc().tz(timezone).format(mysqlTimestampFormat),
      ).valueOf();
      let count: number | null = isAlive ? sensorCount : null;
      const microstopAndCountExists: boolean = !!count && isMicroStop;
      count = isMicroStop ? 0 : count;

      formattedSensorData.push({
        count,
        createdAt,
      });

      if (microstopAndCountExists) {
        formattedSensorData.push({
          createdAt,
          count: sensorCount,
        });
      }
    });

    this.fillGapsBetweenFilterAndDataStartDate(startDate, formattedSensorData);
    this.fillGapsBetweenDataAndFilterEndDate(endDate, formattedSensorData);

    return formattedSensorData.sort(
      (a: IMicrostopsAnalysisSensorData, b: IMicrostopsAnalysisSensorData) => Number(a.createdAt) - Number(b.createdAt),
    );
  }

  public static formatActivityData(
    activityHistoryData: IMicrostopsActivityHistoriesElasticResponse,
    ongoingActivityData: ILineData[],
    timezone: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ): IMicrostopsAnalysisActivityData[] {
    const startDateInMs: number = moment(startDate.format(mysqlTimestampFormat)).valueOf();
    const endDateInMs: number = moment(endDate.format(mysqlTimestampFormat)).valueOf();
    const formattedActivityData: IMicrostopsAnalysisActivityData[] = activityHistoryData.hits.hits.map(
      (activityHistory: IMicrostopsActivityHistory) => {
        return MicrostopsService.formatActivityDataForGanttChart(
          _.get(activityHistory, '_source') as IMicrostopsActivityHistorySource,
          timezone,
          startDateInMs,
          endDateInMs,
        );
      },
    );

    if (ongoingActivityData[0]) {
      formattedActivityData.push(
        MicrostopsService.formatActivityDataForGanttChart(
          MicrostopsService.formatOngoingActivityData(ongoingActivityData[0]),
          timezone,
          startDateInMs,
          endDateInMs,
        ),
      );
    }

    return formattedActivityData
      .sort(
        (a: IMicrostopsAnalysisActivityData, b: IMicrostopsAnalysisActivityData) => Number(a.start) - Number(b.start),
      )
      .filter((datum: IMicrostopsAnalysisActivityData) => datum);
  }

  private static formatActivityDataForGanttChart(
    activityHistorySource: IMicrostopsActivityHistorySource,
    timezone: string,
    minStartDateInMs: number,
    maxEndDateInMs: number,
  ): IMicrostopsAnalysisActivityData | undefined {
    const activityStartDateInMs: number = activityHistorySource.isOngoing
      ? moment(activityHistorySource.start).valueOf()
      : moment(moment(activityHistorySource.start).tz(timezone).format(mysqlTimestampFormat)).valueOf();
    const activityEndDateInMs: number = moment(
      moment(activityHistorySource.end).tz(timezone).format(mysqlTimestampFormat),
    ).valueOf();
    const oneSecondInMs: number = 1000;
    const start: number = minStartDateInMs > activityStartDateInMs ? minStartDateInMs : activityStartDateInMs;
    const end: number = maxEndDateInMs < activityEndDateInMs ? maxEndDateInMs + oneSecondInMs : activityEndDateInMs;

    if (activityStartDateInMs > maxEndDateInMs || activityEndDateInMs < minStartDateInMs) {
      return undefined;
    }

    return {
      start,
      end,
      id: activityHistorySource.id,
      name: activityHistorySource.activity.name,
      type: activityHistorySource.activity.type as ActivityTypes,
      isMissingData: MicrostopsService.isActivityMissingData(activityHistorySource),
    };
  }

  private static formatOngoingActivityData(ongoingActivityData: ILineData): IMicrostopsActivityHistorySource {
    return {
      id: ongoingActivityData.id,
      start: moment(ongoingActivityData.timer).format(mysqlTimestampFormat),
      end: moment().format(mysqlTimestampFormat),
      activity: {
        id: ongoingActivityData.currentActivity?.id,
        name: ongoingActivityData.currentActivity?.name,
        type: ongoingActivityData.currentActivity?.activityType,
      },
      task: {
        id: ongoingActivityData.currentTask?.id,
        title: ongoingActivityData.currentTask?.title,
        isMissingData: ongoingActivityData.currentTask?.isMissingData,
      },
      workOrderSchedule: {
        id: ongoingActivityData.currentWorkOrder?.id,
        woNumber: ongoingActivityData.currentWorkOrder?.woNumber,
      },
      isOngoing: true,
    };
  }

  private static fillGapsBetweenFilterAndDataStartDate(
    startDate: moment.Moment,
    formattedSensorData: IMicrostopsAnalysisSensorData[],
  ): void {
    if (!formattedSensorData[0]) {
      return;
    }

    const startDateRangeGap: moment.Moment[] = ChartsComponent.divideDatesToDaysWeeksOrMonths(
      moment(startDate.format(mysqlTimestampFormat)),
      moment(formattedSensorData[0].createdAt),
      'minutes',
    );

    if (startDateRangeGap.length > 1) {
      startDateRangeGap.reverse().forEach((date: moment.Moment) => {
        formattedSensorData.unshift({ count: null, createdAt: date.valueOf() });
      });
    }
  }

  private static fillGapsBetweenDataAndFilterEndDate(
    endDate: moment.Moment,
    formattedSensorData: IMicrostopsAnalysisSensorData[],
  ): void {
    if (!formattedSensorData[0]) {
      return;
    }

    const endDateRangeGap: moment.Moment[] = ChartsComponent.divideDatesToDaysWeeksOrMonths(
      moment(_.last(formattedSensorData).createdAt),
      moment(endDate.format(mysqlTimestampFormat)),
      'minutes',
    );

    if (endDateRangeGap.length > 1) {
      endDateRangeGap.forEach((date: moment.Moment) => {
        formattedSensorData.push({ count: null, createdAt: date.valueOf() });
      });
    }
  }

  private static isActivityMissingData(activityHistorySource: IMicrostopsActivityHistorySource): boolean {
    const isMissingTask: boolean =
      _.isNil(activityHistorySource.task?.id) && activityHistorySource.activity?.type !== ActivityTypes.RUN_TIME;

    return (
      isMissingTask ||
      (_.isNil(activityHistorySource.workOrderSchedule?.id) &&
        activityHistorySource.activity?.type !== ActivityTypes.IDLE_TIME) ||
      (!_.isNil(activityHistorySource.task?.id) && !!activityHistorySource.task?.isMissingData)
    );
  }

  private static generateDeviceMessagesRequestParams(
    filters: IMicrostopsAnalysisTableQuery,
    timezone: string,
  ): IMicrostopsDeviceMessagesElasticRequest {
    const endOfDayOffset: number = 2;

    return {
      search: {
        device: {
          id: String(filters.sensorId[0]),
          start: {
            gte: moment(filters.dateRange.startDate).toISOString(),
            timeZone: timezone,
          },
          end: {
            lte: moment(filters.dateRange.endDate).add(endOfDayOffset, 'minute').toISOString(),
            timeZone: timezone,
          },
        },
        site: {
          ids: filters.siteId,
        },
        line: {
          ids: filters.lineId,
        },
      },
      aggregation: {
        groupBy: 'deviceStart',
        attributes: {
          dateHistogram: {
            field: 'deviceStart',
            interval: 'minute',
          },
        },
      },
    };
  }

  private static generateActivityHistoriesRequestParams(
    filters: IMicrostopsAnalysisTableQuery,
    timezone: string,
  ): IMicrostopsActivityHistoriesElasticRequest {
    const shiftDayOffset: number = 1.1;

    return {
      search: {
        shiftDay: {
          gte: moment(filters.dateRange.startDate).subtract(shiftDayOffset, 'day').toISOString(),
          lte: moment(filters.dateRange.endDate).toISOString(),
          timeZone: timezone,
        },
        site: {
          ids: filters.siteId,
        },
        line: {
          ids: filters.lineId,
        },
      },
    };
  }

  private static generateOngoingActivityRequestParams(filters: IMicrostopsAnalysisTableQuery): IRequestBody {
    return {
      fields: ['timer', 'title'],
      search: {
        $and: [
          {
            id: {
              $in: filters.lineId,
            },
          },
          {
            siteId: {
              $in: filters.siteId,
            },
          },
          {
            timer: {
              $lte: moment(filters.dateRange.endDate).toISOString(),
            },
          },
        ],
      },
      join: [
        { field: 'currentActivity', select: ['name', 'activityType'] },
        { field: 'currentWorkOrder', select: ['woNumber'] },
        { field: 'currentTask', select: ['title', 'isMissingData'] },
      ],
    };
  }

  public getDeviceMessages(
    filters: IMicrostopsAnalysisTableQuery,
    timezone: string,
  ): Observable<BaseOneResponseInterface<IMicrostopsDeviceMessagesElasticResponse>> {
    const body: IMicrostopsDeviceMessagesElasticRequest = MicrostopsService.generateDeviceMessagesRequestParams(
      filters,
      timezone,
    );

    return this.http.post<BaseOneResponseInterface<IMicrostopsDeviceMessagesElasticResponse>>(
      `${this.proxyUrl}${this.routes.deviceMessagesSearch}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getActivityHistories(
    filters: IMicrostopsAnalysisTableQuery,
    timezone: string,
  ): Observable<BaseOneResponseInterface<IMicrostopsActivityHistoriesElasticResponse>> {
    const body: IMicrostopsActivityHistoriesElasticRequest = MicrostopsService.generateActivityHistoriesRequestParams(
      filters,
      timezone,
    );

    return this.http.post<BaseOneResponseInterface<IMicrostopsActivityHistoriesElasticResponse>>(
      `${this.proxyUrl}${this.routes.activityHistoriesSearch}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getOngoingActivity(filters: IMicrostopsAnalysisTableQuery): Observable<ResponseArrayInterface<ILineData>> {
    const body: IRequestBody = MicrostopsService.generateOngoingActivityRequestParams(filters);
    return this.http.post<ResponseArrayInterface<ILineData>>(`${this.routes.lines}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getMicrostops(params: HttpParams): Observable<GetManyResponseInterface<IMicrostop>> {
    return this.http.get<GetManyResponseInterface<IMicrostop>>(`${this.routes.microstops}`, { params });
  }

  public getSensorHistogramData(params: HttpParams): Observable<{ data: IMicrostopsDeviceMessage[] }> {
    return this.http.get<{ data: IMicrostopsDeviceMessage[] }>(`${this.routes.sensorHistogram}`, { params });
  }

  public getMicrostopCalculationActiveLines(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IMicrostopActiveLine>> {
    return this.http.get<GetManyResponseInterface<IMicrostopActiveLine>>(`${this.routes.microstopActiveLines}`, {
      params,
    });
  }

  public checkIfAllLinesUseMicrostopCalculation(
    selectedSiteIds: number[],
    selectedLineIds: number[] | -1,
    microstopActiveLines: IMicrostopActiveLine[],
  ): boolean {
    const microstopActiveLineIds: number[] = microstopActiveLines.map(
      (microstopActiveLine: IMicrostopActiveLine) => microstopActiveLine.id,
    );

    if (Array.isArray(selectedLineIds) && selectedLineIds?.length && selectedLineIds[0] !== -1) {
      return selectedLineIds.every((selectedLineId: number) => microstopActiveLineIds.includes(selectedLineId));
    }

    let result: boolean = false;

    this.store
      .select('lineFilter')
      .pipe(take(1))
      .subscribe((state: FilterLineState) => {
        const lineIds: number[] = state.data.reduce((sitesLineIds: number[], line: LineCRUDInterface) => {
          if (selectedSiteIds?.includes(line.siteId)) {
            sitesLineIds.push(line.id);
          }

          return sitesLineIds;
        }, []);

        result = lineIds.every((lineId: number) => microstopActiveLineIds.includes(lineId));
      });

    return result;
  }
}
