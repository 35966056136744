import * as Actions from './iot-device-report.actions';
import { IIotDeviceReportState } from './iot-device-report.model';

export const iotDeviceReportState: IIotDeviceReportState = {
  tableSettings: [],
};

export function iotDeviceReportReducer(state: any = iotDeviceReportState, action: Actions.IotDeviceReportActions): any {
  switch (action.type) {
    case Actions.IOT_DEVICE_PORTAL_SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
