import { LineCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ScwMatFormInputInterface } from '../../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.model';
import { ScwMatRulesInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { DropdownOptionInterface } from '../../sensor-management/sensor-management.model';

export enum ProductResourceGroupTypes {
  LINE_GROUP = 1,
  LINE = 2,
}

export interface IProductResourceGroup {
  id: number;
  type: ProductResourceGroupTypes;
  name: string | null;
  resourceId: number | null;
  siteId: number;
  line: LineCRUDInterface;
  parent: number | null;
  parentResourceGroup: IProductResourceGroup;
  site: Pick<SiteCRUDInterface, 'name'>;
  resourceGroupChildren: IProductResourceGroup[];
}

export type IProductResourceGroupCreateRequest = Pick<IProductResourceGroup, 'name' | 'siteId' | 'parent'>;
export type IProductResourceGroupUpdateRequest = Partial<Pick<IProductResourceGroup, 'name' | 'parent'>>;

export interface IProductResourceGroupForm {
  name: ScwMatFormInputInterface<string>;
  parentId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  siteId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
}

export interface IProductResourceGroupFormData {
  form: IProductResourceGroupForm;
  rules: ScwMatRulesInterface;
  dropdownOptions: {
    parentId: DropdownOptionInterface[];
    siteId: DropdownOptionInterface[];
  };
}

export enum ProductResourceGetManyPurpose {
  pageData = 0,
  parents = 1,
  treeChart = 2,
}

export interface IProductResourceGroupsFilters {
  siteId: number[];
  search: string;
}

export enum EProductResourceGroupsResourceType {
  resourceGroup = 'resourceGroup',
  line = 'line',
}

export enum EProductResourceGroupsResourceTypeValue {
  resourceGroup = 1,
  line = 2,
}
