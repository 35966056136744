import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionsSubject, Store } from '@ngrx/store';
import * as AppActions from '../../../store/app/actions';
import * as HomeActions from '../../../store/home/home.actions';
import { HomeActionTypes } from '../../../store/home/home.actions';
import { OeeAppState } from '../../../store/oee.reducer';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../service/helper.service';
import * as _moment from 'moment';
import { InputLimit } from '../../model/enum/input-limit';
import { FormPatterns } from '../../model/enum/form-patterns';
import {
  WoInformationFormSubmitInterface,
  WorkOrderInformationFormInterface,
} from '../select-work-order/select-work-order.model';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { IUpdateLineInformationCompleteResponse, ProductSearchDispatchAreas } from '../../../store/home/home.model';
import { ActionTypes } from '../../model/enum/action-types';
import { mysqlTimestampFormat } from '../../helper/date';
import { ProductCRUDResponseInterface } from '../../service/filter/service.class';
import { ProductCRUDInterface } from '../filter/filter.class';
import * as _ from 'lodash';
import {
  DECIMAL_MAX_PRECISION,
  DECIMAL_NUMERIC_SCALE,
  DEFAULT_DECIMAL_INPUT_RULE,
  EProductTransitionMatrixFromToType,
  OWL_DATE_TIME_PROVIDERS,
  smallModal,
} from '../../../../constants';
import { WorkOrderFormLoadingStatusInterface, WorkOrderFormResourcesInterface } from './work-order-infirmation.model';
import { ISelectWorkOrderModalConfigurations } from '../../../view/home/work-order/work-order.model';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { User } from '../../../store/user/model';
import { ScwMatInputRule } from '../scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { WorkOrderProductVersionModalComponent } from './work-order-product-version-modal/work-order-product-version-modal.component';
import { IProductVersion } from 'src/app/store/settings/products/products.model';
import { TWorkOrderProductVersionHeader } from './work-order-product-version-modal/work-order-product-version-modal.model';
import { IJob } from '../../../store/settings/jobs/jobs.model';
import { TDecimalSeparator } from 'src/constants.model';
import {
  IGetProductTransitionMatrix,
  IGetProductTransitionMatrixParams,
} from '../../../store/settings/product-transition-matrix/product-transition-matrix.model';
import { IActivityHistory, IWorkOrderOfActivityHistory } from '../../service/activity-history/activity-history.model';

@OnDestroyDecorator
@Component({
  selector: 'app-work-order-information',
  templateUrl: './work-order-information.component.html',
  styleUrls: [
    './work-order-information.component.scss',
    '../../../view/home/home.component.scss',
    '../../../../../node_modules/sweetalert2/src/sweetalert2.scss',
  ],
  providers: OWL_DATE_TIME_PROVIDERS,
})
export class WorkOrderInformationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('work_order_select_product_version_modal')
  workOrderSelectProductVersionModal: WorkOrderProductVersionModalComponent;

  @Input() actionType: ActionTypes;
  @Input() editWorkOrderInProgress: boolean = false;
  @Input() layoutType: 'home' | 'workOrderSchedule' = 'home';
  @Input() formResources: WorkOrderFormResourcesInterface = {
    sites: [],
    lines: [],
    products: [],
    productionStages: [],
  };
  @Input() formResourcesLoadingStatus: WorkOrderFormLoadingStatusInterface = {
    sitesLoading: false,
    linesLoading: false,
    productsLoading: false,
    productionStagesLoading: false,
  };
  @Input() selectWorkOrderModalConfigurations: ISelectWorkOrderModalConfigurations;
  @Input() workOrderDetail: Partial<WorkOrderInformationFormInterface>;
  @Input() workOrderNo: string;
  @Input() lineId: number;
  @Input() withoutWorkOrder: boolean;
  @Input() isOpenedFromHome: boolean = false;

  @Output() setWorkOrderInformation = new EventEmitter<Partial<WorkOrderInformationFormInterface>>();
  @Output() cancelButtonClickEmitter = new EventEmitter();
  @Output() onSiteChangedEmitter = new EventEmitter<number>();
  @Output() onProductChangedEmitter = new EventEmitter<number>();
  @Output() onProductSearchEmitter = new EventEmitter<string>();
  @Output() goToNextStepWizard = new EventEmitter<string>();
  @Output() closeModal = new EventEmitter<string>();

  public workOrderForm: WoInformationFormSubmitInterface = {
    actionType: '',
    productId: null,
    productName: '',
    productDescription: '',
    productRealId: '',
    productTableId: '',
    quantityOrdered: null,
    scheduledPostDuration: null,
    scheduledPreDuration: null,
    scheduledRunDuration: null,
    scheduledPreRunLaborHours: null,
    scheduledRunLaborHours: null,
    scheduledPostRunLaborHours: null,
    versionId: null,
    versionName: '',
    woDueDate: '',
    workOrderDueDateForNative: '',
    workOrderNo: '',
    siteId: null,
    scheduledStartDateForNative: '',
    scheduledStartDate: '',
    scheduledEndDate: undefined,
    scheduledEndDateForNative: '',
    scheduledLineId: null,
    sequenceNumber: null,
    jobId: null,
    completed: null,
    editableFields: [],
    hasActivityHistory: false,
    hasOnGoingActivity: false,
    productIdDescription: '',
    processOrder: null,
    operationNumber: null,
    productInfo: null,
    productVersionID: null,
    formattedWoDueDate: null,
    formattedWorkOrderDueDateForNative: null,
    matrixBasedPostRunDuration: '',
    quantityBasedRunDuration: '',
    matrixBasedPreRunDuration: '',
  };
  public products: ProductCRUDInterface[];
  public selectedProduct: any = {};
  public productSelectionModalRef: any;
  public selectedVersion: any = {};
  public productSearchText: string;
  public subscriptionProduct: any;
  public searchProductProgress: boolean = false;
  public isWorkOrderSubmitButtonClicked: boolean = false;
  public removeConfirmationModalRef: NgbModalRef;

  public lazyLoadingProgress: boolean = false;
  public lazyLoadingPage: number = 1;
  public userSubscription: Subscription;
  public productVersionModalHeader: TWorkOrderProductVersionHeader = {
    productName: undefined,
    productDescription: undefined,
    currentVersion: undefined,
  };
  public durationPattern: string;
  private dispatchedBy: ProductSearchDispatchAreas = null;
  private productSiteFilter: number;
  ActionTypes = ActionTypes;
  private productSearchCompletedActionSubscription: Subscription;
  private productSearchFinalizedActionSubscription: Subscription;
  private jobsSubscription: Subscription;
  private updateLineInformationCompletedHomeActionSubscription: Subscription;
  private getPreviousWorkOrderCompletedActionSubscription: Subscription;
  private getProductTransitionMatrixCompletedActionSubscription: Subscription;

  public productSelectionModalContent: any;

  InputLimit = InputLimit;
  public formPatterns = FormPatterns;
  public workOrdersEditModal: boolean = false;
  public editableStatuses = {
    siteId: {
      key: 'siteId',
      isDisabled: false,
    },
    workOrderNo: {
      key: 'woNumber',
      isDisabled: false,
    },
    productId: {
      key: 'productId',
      isDisabled: false,
    },
    woDueDate: {
      key: 'woDueDate',
      isDisabled: false,
    },
    releaseDate: {
      key: 'releaseDate',
      isDisabled: false,
    },
    quantityOrdered: {
      key: 'quantityOrdered',
      isDisabled: false,
    },
    scheduledPreDuration: {
      key: 'scheduledPreDuration',
      isDisabled: false,
    },
    scheduledPostDuration: {
      key: 'scheduledPostDuration',
      isDisabled: false,
    },
    scheduledRunDuration: {
      key: 'scheduledRunDuration',
      isDisabled: false,
    },
    scheduledPreRunLaborHours: {
      key: 'scheduledPreRunLaborHours',
      isDisabled: false,
    },
    scheduledRunLaborHours: {
      key: 'scheduledRunLaborHours',
      isDisabled: false,
    },
    scheduledPostRunLaborHours: {
      key: 'scheduledPostRunLaborHours',
      isDisabled: false,
    },
    productionStage: {
      key: 'productionStage',
      isDisabled: false,
    },
    jobId: {
      key: 'jobId',
      isDisabled: false,
    },
    scheduledStartDate: {
      key: 'scheduledRunDate',
      isDisabled: false,
    },
    scheduledEndDate: {
      key: 'scheduledEndDate',
      isDisabled: false,
    },
    scheduledLineId: {
      key: 'scheduledLine',
      isDisabled: false,
    },
    sequenceNumber: {
      key: 'sequenceNumber',
      isDisabled: false,
    },
    productVersion: {
      key: 'productVersion',
      isDisabled: true,
    },
  };
  public hasActivity: boolean = false;
  readonly layoutTypes = {
    home: 'home',
    workOrderSchedule: 'workOrderSchedule',
  };
  private decimalSeparator$: TDecimalSeparator;
  private dateTimeFormat$: string;
  private dateFormat$: string;
  public dateFormatPlaceholder: string;
  public dateTimeFormatPlaceholder: string;

  public formattedScheduledStartDateForNative: string;
  public formattedScheduledEndDateForNative: string;
  public formattedWorkOrderDueDateForNative: string;
  public formattedReleaseDateForNative: string;
  public timezone$: string;
  public workOrderInformationInputRules = {
    site: [{ required: true }],
    workOrderNumber: this.getWorkOrderNoRules(),
    job: [{ required: false }],
    quantityOrdered: this.getQuantityOrderedRules(),
    product: this.layoutType === this.layoutTypes.home && this.hasActivity ? [] : [{ required: true }],
    workOrderDueDate: [],
    sequenceNumber: [{ minValue: 0 }, { maxValue: InputLimit.WORKORDER_SEQUENCE_NUMBER_MAX_VALUE }],
    scheduledRunDuration: [DEFAULT_DECIMAL_INPUT_RULE],
    scheduledPreDuration: [DEFAULT_DECIMAL_INPUT_RULE],
    scheduledPostDuration: [DEFAULT_DECIMAL_INPUT_RULE],
    processOrder: [{ maxLength: InputLimit.WORK_ORDER_PROCESS_ORDER_MAX_LENGTH }],
    operationNumber: [{ maxLength: InputLimit.WORK_ORDER_OPERATIONAL_NUMBER_MAX_LENGTH }],
    scheduledPreRunLaborHours: [DEFAULT_DECIMAL_INPUT_RULE],
    scheduledRunLaborHours: [DEFAULT_DECIMAL_INPUT_RULE],
    scheduledPostRunLaborHours: [DEFAULT_DECIMAL_INPUT_RULE],
    productVersion: [],
  };
  public jobs$: IJob[] = [];
  public weekStartDay$: number = null;
  public previousWorkOrder: IWorkOrderOfActivityHistory | null = null;
  public productTransitionMatrix: IGetProductTransitionMatrix | null = null;

  constructor(
    public ngbModal: NgbModal,
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    public helperService: HelperService,
    private readonly toast: ToastrService,
    private readonly homeActions: ActionsSubject,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.productSearchText = '';
    this.durationPattern = this.formPatterns.DECIMAL_WITH_COMMA;
  }

  public openProductModal(content): void {
    this.productSelectionModalContent = content;
    this.dispatchedBy = ProductSearchDispatchAreas.OpenProductModal;
    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(
      new HomeActions.ProductSearch(ProductSearchDispatchAreas.OpenProductModal, this.productSiteFilter),
    );
  }

  public openProductVersionModal(): void {
    this.workOrderSelectProductVersionModal.showModal();
  }

  public searchRequest(searchString: string): void {
    if (this.subscriptionProduct) {
      this.subscriptionProduct.unsubscribe();
    }

    this.productSearchText = searchString;
    this.lazyLoadingPage = 1;
    this.searchProductProgress = true;
    this.dispatchedBy = ProductSearchDispatchAreas.SearchProductRequest;

    this.store.dispatch(
      new HomeActions.ProductSearch(
        ProductSearchDispatchAreas.SearchProductRequest,
        this.productSiteFilter,
        this.productSearchText,
        this.lazyLoadingPage,
      ),
    );
  }

  public saveInformation(isValid: boolean): void {
    this.isWorkOrderSubmitButtonClicked = true;

    if (!isValid) {
      return;
    }

    this.workOrderForm.workOrderNo = this.workOrderForm.workOrderNo.trim();

    if (this.helperService.isTouchDevice) {
      const momentScheduledStartDate: _moment.Moment = _moment(this.workOrderForm.scheduledStartDateForNative);
      const momentScheduledEndDate: _moment.Moment = _moment(this.workOrderForm.scheduledEndDateForNative);
      const momentReleaseDate: _moment.Moment = _moment(this.workOrderForm.releaseDateForNative);

      this.workOrderForm.woDueDate = _moment(this.workOrderForm.workOrderDueDateForNative);

      if (momentScheduledStartDate.isValid()) {
        this.workOrderForm.scheduledStartDate = momentScheduledStartDate;
      }

      if (momentScheduledEndDate.isValid()) {
        this.workOrderForm.scheduledEndDate = momentScheduledEndDate;
      }

      if (momentReleaseDate.isValid()) {
        this.workOrderForm.releaseDate = momentReleaseDate;
      }
    }

    let postWoDueDate: string | null = null;

    if (this.workOrderForm.formattedWoDueDate?.startDate) {
      postWoDueDate = this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(
        this.workOrderForm.formattedWoDueDate?.startDate,
      );
    }

    this.workOrderForm.woDueDate = postWoDueDate;

    this.workOrderForm.scheduledRunDuration = this.workOrderForm.scheduledRunDuration
      ? _.replace(this.workOrderForm.scheduledRunDuration, ',', '.')
      : null;

    this.workOrderForm.scheduledPreDuration = this.workOrderForm.scheduledPreDuration
      ? _.replace(this.workOrderForm.scheduledPreDuration, ',', '.')
      : null;

    this.workOrderForm.scheduledPostDuration = this.workOrderForm.scheduledPostDuration
      ? _.replace(this.workOrderForm.scheduledPostDuration, ',', '.')
      : null;

    this.workOrderForm.scheduledPreRunLaborHours = this.workOrderForm.scheduledPreRunLaborHours
      ? _.replace(this.workOrderForm.scheduledPreRunLaborHours, ',', '.')
      : null;

    this.workOrderForm.scheduledRunLaborHours = this.workOrderForm.scheduledRunLaborHours
      ? _.replace(this.workOrderForm.scheduledRunLaborHours, ',', '.')
      : null;

    this.workOrderForm.scheduledPostRunLaborHours = this.workOrderForm.scheduledPostRunLaborHours
      ? _.replace(this.workOrderForm.scheduledPostRunLaborHours, ',', '.')
      : null;

    this.workOrderForm.quantityOrdered = this.workOrderForm.quantityOrdered
      ? _.replace(this.workOrderForm.quantityOrdered, ',', '.')
      : null;

    this.workOrderForm.processOrder = this.workOrderForm?.processOrder === '' ? null : this.workOrderForm.processOrder;
    this.workOrderForm.operationNumber =
      this.workOrderForm?.operationNumber === '' ? null : this.workOrderForm.operationNumber;
    this.workOrderForm.jobId = this.workOrderForm.jobSelect?.[0]?.id ?? null;

    this.workOrderForm.productVersionID = this.workOrderForm.productVersionInfo.value;

    this.workOrderForm.matrixBasedPostRunDuration = this.decimalHelper.sanitizeString(
      this.decimalHelper.removeThousandSeparator(this.workOrderForm.matrixBasedPostRunDuration),
    );
    this.workOrderForm.quantityBasedRunDuration = this.decimalHelper.sanitizeString(
      this.decimalHelper.removeThousandSeparator(this.workOrderForm.quantityBasedRunDuration),
    );
    this.workOrderForm.matrixBasedPreRunDuration = this.decimalHelper.sanitizeString(
      this.decimalHelper.removeThousandSeparator(this.workOrderForm.matrixBasedPreRunDuration),
    );

    this.setWorkOrderInformation.emit(this.workOrderForm);

    if (this.workOrderForm.versionId !== null) {
      this.goToNextStepWizard.emit();
    }
  }

  public selectProduct(data: ProductCRUDInterface): void {
    this.store.dispatch(new AppActions.ShowLoader());
    this.selectedProduct = data;
    this.workOrderForm.productName = data.productId;
    this.workOrderForm.productId = data.id;
    this.workOrderForm.productDescription = data.description;
    this.workOrderForm.productIdDescription = `${data.productId} - ${data.description}`;
    this.workOrderForm.productInfo = {
      text: this.workOrderForm.productIdDescription,
      value: this.workOrderForm.productId,
    };

    this.selectedVersion = {};
    this.workOrderForm.versionId = null;
    this.workOrderForm.versionName = '';

    this.resetCurrentProductVersion();
    this.productVersionModalHeader.productName = this.workOrderForm.productName;
    this.productVersionModalHeader.productDescription = this.workOrderForm.productDescription;

    if (data.productVersion) {
      if (
        data.productVersion.length === 0 ||
        data.productVersion.every((productVersion: IProductVersion) => productVersion.active === 0)
      ) {
        this.editableStatuses.productVersion.isDisabled = true;
      } else if (data.productVersion.length === 1) {
        this.editableStatuses.productVersion.isDisabled = false;
        this.setCurrentProductVersion(data.productVersion[0]);
      } else if (data.productVersion.length > 1) {
        this.editableStatuses.productVersion.isDisabled = false;
        data.productVersion.forEach((productVersion: IProductVersion) => {
          if (productVersion.default === 1) {
            this.setCurrentProductVersion(productVersion);
          }
        });
      }
    }

    setTimeout(() => {
      this.store.dispatch(new AppActions.HideLoader());
      this.productSelectionModalRef.dismiss();
    }, 200);
  }

  public onProductVersionSelectionChanged(productVersion: IProductVersion): void {
    this.productVersionModalHeader.currentVersion = productVersion.name;

    this.workOrderForm.productVersionInfo = {
      text: productVersion.name,
      value: productVersion.id,
    };
  }

  public onScroll(): void {
    this.lazyLoadingPage = this.lazyLoadingPage + 1;
    this.lazyLoadingProgress = true;
    this.dispatchedBy = ProductSearchDispatchAreas.OnScroll;

    this.store.dispatch(
      new HomeActions.ProductSearch(
        ProductSearchDispatchAreas.OnScroll,
        this.productSiteFilter,
        this.productSearchText,
        this.lazyLoadingPage,
      ),
    );
  }

  public ngOnInit(): void {
    this.workOrdersEditModal = this.actionType === ActionTypes.EDIT;

    if (this.actionType === ActionTypes.CREATE) {
      this.workOrderForm.workOrderNo = this.workOrderNo;
    }

    this.userSubscription = this.store.select('user').subscribe((state: User) => {
      this.decimalSeparator$ = state.decimalSeparator;
      this.dateTimeFormat$ = state.dateTimeFormat;
      this.dateFormat$ = state.dateFormat;
      this.timezone$ = state.timezone;
      this.weekStartDay$ = state.weekStartDay;

      if (state.decimalSeparator === '.') {
        this.durationPattern = this.formPatterns.DECIMAL_WITH_DOT;
      }

      this.productSiteFilter = _.isNil(_.get(this.selectWorkOrderModalConfigurations, 'siteId', null))
        ? state.siteId
        : this.selectWorkOrderModalConfigurations.siteId;

      this.store.dispatch(new HomeActions.GetPreviousWorkOrder(state.siteId, state.lineId));
    });

    if (this.actionType === ActionTypes.EDIT) {
      let woDueDate = null;

      if (!_.isNil(this.workOrderDetail.woDueDate) && typeof this.workOrderDetail.woDueDate === 'string') {
        woDueDate = _moment(this.helperService.convertFromISOFormatToGivenTimezone(this.workOrderDetail.woDueDate));
      }

      const scheduledStartDate: string = this.workOrderDetail.scheduledStartDate as string;
      const scheduledEndDate: string = this.workOrderDetail.scheduledEndDate as string;
      const releaseDate: string = this.workOrderDetail.releaseDate as string;

      this.selectedProduct = { id: this.workOrderDetail.productId };
      this.productVersionModalHeader = {
        productName: this.workOrderDetail.productName,
        productDescription: this.workOrderDetail.productDescription,
      };

      this.editableStatuses.productVersion.isDisabled = true;

      if (this.workOrderDetail.productVersionName) {
        this.productVersionModalHeader.currentVersion = this.workOrderDetail.productVersionName;
        this.editableStatuses.productVersion.isDisabled = false;
      }

      this.workOrderForm = {
        ...this.workOrderDetail,
        woDueDate,
        workOrderDueDateForNative: _moment(woDueDate).format(mysqlTimestampFormat),
        scheduledStartDateForNative: !_.isEmpty(scheduledStartDate) ? scheduledStartDate.replace(' ', 'T') : '',
        scheduledEndDateForNative: !_.isEmpty(scheduledEndDate) ? scheduledEndDate.replace(' ', 'T') : '',
        releaseDateForNative: !_.isEmpty(releaseDate) ? releaseDate.replace(' ', 'T') : '',
        // @ts-ignore
        siteId: String(this.workOrderDetail.siteId),
        // @ts-ignore
        productId: this.workOrderDetail.productId,
        productInfo: {
          text: this.workOrderDetail.productIdDescription,
          value: this.workOrderDetail.productId,
        },
        // @ts-ignore
        versionId: String(this.workOrderDetail.versionId),
        scheduledLineId: String(this.workOrderDetail.scheduledLineId),
        formattedWoDueDate: { startDate: woDueDate, endDate: woDueDate },
        formattedWorkOrderDueDateForNative: { startDate: woDueDate, endDate: woDueDate },
        scheduledRunDuration: this.workOrderDetail.scheduledRunDuration
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledRunDuration)
          : null,
        scheduledPreDuration: this.workOrderDetail.scheduledPreDuration
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledPreDuration)
          : null,
        scheduledPostDuration: this.workOrderDetail.scheduledPostDuration
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledPostDuration)
          : null,
        scheduledPreRunLaborHours: this.workOrderDetail.scheduledPreRunLaborHours
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledPreRunLaborHours)
          : null,
        scheduledRunLaborHours: this.workOrderDetail.scheduledRunLaborHours
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledRunLaborHours)
          : null,
        scheduledPostRunLaborHours: this.workOrderDetail.scheduledPostRunLaborHours
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.scheduledPostRunLaborHours)
          : null,
        quantityOrdered: this.workOrderDetail.quantityOrdered
          ? this.decimalHelper.replaceDecimalSeparator(this.workOrderDetail.quantityOrdered, this.decimalSeparator$)
          : null,
        productVersionID: this.workOrderDetail.productVersionID,
        productVersionInfo: {
          text: this.workOrderDetail?.productVersionName ?? null,
          value: this.workOrderDetail?.productVersionID ?? null,
        },
        jobSelect: this.workOrderDetail.jobId ? [this.workOrderDetail.job] : undefined,
        matrixBasedPreRunDuration: this.workOrderDetail.matrixBasedPreRunDuration
          ? this.decimalHelper.toFixedValue(
              this.decimalHelper.removeTrailingZeros(this.workOrderDetail.matrixBasedPreRunDuration),
              DECIMAL_NUMERIC_SCALE,
            )
          : null,
        quantityBasedRunDuration: this.workOrderDetail.quantityBasedRunDuration
          ? this.decimalHelper.toFixedValue(
              this.decimalHelper.removeTrailingZeros(this.workOrderDetail.quantityBasedRunDuration),
              DECIMAL_NUMERIC_SCALE,
            )
          : null,
        matrixBasedPostRunDuration: this.workOrderDetail.matrixBasedPostRunDuration
          ? this.decimalHelper.toFixedValue(
              this.decimalHelper.removeTrailingZeros(this.workOrderDetail.matrixBasedPostRunDuration),
              DECIMAL_NUMERIC_SCALE,
            )
          : null,
      };
    }

    if (this.workOrdersEditModal) {
      for (const [, value] of Object.entries(this.editableStatuses)) {
        // TODO: Refactor this block with #Phase 2
        if (value.key === 'productVersion') {
          continue;
        }

        value.isDisabled = !this.workOrderForm.editableFields.includes(value.key);
      }
    }

    this.hasActivity = this.workOrderForm.hasActivityHistory || this.workOrderForm.hasOnGoingActivity;
    this.workOrderForm.actionType = this.actionType;

    this.workOrderInformationInputRules.quantityOrdered = this.getQuantityOrderedRules(
      this.workOrderForm.hasParentWorkOrder,
    );
    this.workOrderInformationInputRules.workOrderNumber = this.getWorkOrderNoRules(
      this.workOrderForm.hasParentWorkOrder,
    );

    this.productSearchCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.ProductSearchCompleted))
      .subscribe((response: { payload: ProductCRUDResponseInterface }) => {
        switch (this.dispatchedBy) {
          case ProductSearchDispatchAreas.OpenProductModal:
            this.products = response.payload.data;

            if (response.payload.success) {
              this.productSearchText = '';
            }

            this.productSelectionModalRef = this.ngbModal.open(this.productSelectionModalContent, {
              windowClass: 'scw-modal-xl scw-modal-all-scrollable',
              keyboard: false,
              backdrop: 'static',
            });
            break;

          case ProductSearchDispatchAreas.SearchProductRequest:
            this.products = response.payload.data;
            this.searchProductProgress = false;
            break;

          case ProductSearchDispatchAreas.OnScroll:
            this.products = this.products.concat(response.payload.data);
            break;

          default:
            break;
        }
      });

    this.productSearchFinalizedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.ProductSearchFinalized))
      .subscribe(() => {
        switch (this.dispatchedBy) {
          case ProductSearchDispatchAreas.OpenProductModal:
            this.store.dispatch(new AppActions.HideLoader());
            break;

          case ProductSearchDispatchAreas.OnScroll:
            this.lazyLoadingProgress = false;
            break;

          default:
            break;
        }
      });

    this.jobsSubscription = this.homeActions
      .pipe(
        ofType<HomeActions.GetJobsCompleted | HomeActions.CreateJobCompleted>(
          HomeActionTypes.GetJobsCompleted,
          HomeActionTypes.CreateJobCompleted,
        ),
      )
      .subscribe((action) => {
        switch (action.type) {
          case HomeActionTypes.GetJobsCompleted:
            this.jobs$ = _.cloneDeep(action.payload.data);
            break;
          case HomeActionTypes.CreateJobCompleted:
            const job = _.cloneDeep(action.job);
            this.jobs$.push(job);
            this.workOrderForm.jobSelect = [job];
            this.onJobsSearch('');
            break;
          default:
            return;
        }
      });
    this.dateTimeFormatPlaceholder = this.helperService.getPlaceholderForDate(true);
    this.dateFormatPlaceholder = this.helperService.getPlaceholderForDate(false);

    if (this.layoutType === this.layoutTypes.home && this.actionType === ActionTypes.CREATE) {
      this.formattedWorkOrderDueDateForNative = _moment().format(this.dateTimeFormat$);
    }

    if (this.actionType === ActionTypes.EDIT) {
      const momentScheduledStartDate: _moment.Moment = _moment(this.workOrderForm.scheduledStartDateForNative);
      const momentScheduledEndDate: _moment.Moment = _moment(this.workOrderForm.scheduledEndDateForNative);

      const momentWorkOrderDueDate: _moment.Moment = _moment(this.workOrderForm.workOrderDueDateForNative);
      const momentReleaseDate: _moment.Moment = _moment(this.workOrderForm.releaseDateForNative);

      if (momentWorkOrderDueDate.isValid()) {
        this.formattedWorkOrderDueDateForNative = momentWorkOrderDueDate.format(this.dateTimeFormat$);
      }

      if (momentScheduledStartDate.isValid()) {
        this.formattedScheduledStartDateForNative = momentScheduledStartDate.format(this.dateTimeFormat$);
      }

      if (momentScheduledEndDate.isValid()) {
        this.formattedScheduledEndDateForNative = momentScheduledEndDate.format(this.dateTimeFormat$);
      }

      if (momentReleaseDate.isValid()) {
        this.formattedReleaseDateForNative = momentReleaseDate.format(this.dateFormat$);
      }
    }

    this.updateLineInformationCompletedHomeActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.UpdateLineInformationCompleted))
      .subscribe((payload: IUpdateLineInformationCompleteResponse) => {
        if (payload.response.success && this.removeConfirmationModalRef) {
          this.removeConfirmationModalRef.close();
          this.cancel();
        }
      });

    this.getPreviousWorkOrderCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetPreviousWorkOrderCompleted))
      .subscribe((payload: { payload: IActivityHistory[] }) => {
        if (payload.payload.length) {
          this.previousWorkOrder = payload.payload[0].workOrder;
        }
      });

    this.getProductTransitionMatrixCompletedActionSubscription = this.homeActions
      .pipe(ofType(HomeActions.HomeActionTypes.GetProductTransitionMatrixCompleted))
      .subscribe((payload: { payload: IGetProductTransitionMatrix }) => {
        if (payload.payload) {
          this.productTransitionMatrix = payload.payload;
          this.workOrderForm.matrixBasedPreRunDuration = this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.toFixedValue(payload.payload.toPreTarget, DECIMAL_MAX_PRECISION),
          );
          this.workOrderForm.matrixBasedPostRunDuration = this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.toFixedValue(payload.payload.fromPostTarget, DECIMAL_MAX_PRECISION),
          );
          this.workOrderQuantityOrderedChanged(this.workOrderForm?.quantityOrdered);
        }
      });
  }

  public ngAfterViewInit(): void {
    if (this.layoutType === this.layoutTypes.home || this.actionType === ActionTypes.EDIT) {
      document.querySelector("[name='woDueDate']")?.classList?.add('has-value');
    }

    if (this.layoutType === this.layoutTypes.workOrderSchedule && this.actionType === ActionTypes.EDIT) {
      if (!_.isEmpty(this.workOrderForm.scheduledStartDateForNative)) {
        document.querySelector("[name='scheduledStartDate']")?.classList?.add('has-value');
      }

      if (!_.isEmpty(this.workOrderForm.scheduledEndDateForNative)) {
        document.querySelector("[name='scheduledEndDate']")?.classList?.add('has-value');
      }

      if (!_.isEmpty(this.workOrderForm.releaseDateForNative)) {
        document.querySelector("[name='releaseDate']")?.classList?.add('has-value');
      }
    }
  }

  public decimalInputKeypress($event): boolean {
    return !isNaN($event.key) || $event.key === this.decimalSeparator$;
  }

  private getWorkOrderNoRules(hasParentWorkOrder: boolean = false): ScwMatInputRule[] {
    return hasParentWorkOrder
      ? [
          {
            required: true,
          },
          {
            maxLength: 50,
          },
        ]
      : [
          {
            required: true,
          },
          {
            maxLength: 45,
          },
          {
            custom: true,
            validator: (value) => {
              return typeof value === 'string' && !value?.includes('^');
            },
            message: this.translate.instant('workOrderSchedule.validation.shouldNotContain', { character: '^' }),
          },
        ];
  }

  private getQuantityOrderedRules(hasParentWorkOrder: boolean = false): ScwMatInputRule[] {
    return [
      {
        required: true,
      },
      this.decimalHelper.getDecimalNonZeroInputRule(hasParentWorkOrder ? '0' : undefined),
    ];
  }

  public cancel(): void {
    this.closeModal.emit();
  }

  private setCurrentProductVersion(productVersion: IProductVersion): void {
    this.productVersionModalHeader.currentVersion = productVersion.name;

    this.workOrderForm.productVersionInfo = {
      text: productVersion.name,
      value: productVersion.id,
    };

    this.workOrderForm.productVersionID = productVersion.id;
    this.workOrderForm.productVersionName = productVersion.name;
  }

  private resetCurrentProductVersion(): void {
    this.editableStatuses.productVersion.isDisabled = true;

    this.workOrderForm.productVersionInfo = {
      value: undefined,
      text: undefined,
    };

    this.workOrderForm.productVersionID = undefined;
    this.workOrderForm.productVersionName = undefined;
  }

  public ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.productSearchCompletedActionSubscription) {
      this.productSearchCompletedActionSubscription.unsubscribe();
    }

    if (this.productSearchFinalizedActionSubscription) {
      this.productSearchFinalizedActionSubscription.unsubscribe();
    }

    if (this.updateLineInformationCompletedHomeActionSubscription) {
      this.updateLineInformationCompletedHomeActionSubscription.unsubscribe();
    }

    if (this.getPreviousWorkOrderCompletedActionSubscription) {
      this.getPreviousWorkOrderCompletedActionSubscription.unsubscribe();
    }

    if (this.getProductTransitionMatrixCompletedActionSubscription) {
      this.getProductTransitionMatrixCompletedActionSubscription.unsubscribe();
    }

    this.jobsSubscription?.unsubscribe();
  }

  public onJobsSearch($event: string): void {
    const siteId = this.workOrderDetail?.siteId ?? this.selectWorkOrderModalConfigurations.siteId;
    this.store.dispatch(new HomeActions.GetJobs(siteId, $event, this.workOrderForm.jobSelect?.[0]?.id));
  }

  public onJobCreate($event: string): void {
    const siteId = this.workOrderDetail?.siteId ?? this.selectWorkOrderModalConfigurations.siteId;
    const job: Partial<IJob> = {
      siteId,
      jobName: ($event ?? '').trim(),
    };

    this.store.dispatch(new HomeActions.CreateJob(job));
  }

  public confirmationModal(content: TemplateRef<any>): void {
    this.removeConfirmationModalRef = this.ngbModal.open(content, smallModal);
  }

  public removeWorkOrder(): void {
    this.store.dispatch(
      new HomeActions.UpdateLineInformation(this.lineId, {
        workOrderId: null,
        isRemoveWorkOrder: true,
      }),
    );
  }

  public workOrderProductChanged(): void {
    this.loadProductTransitionMatrix();
  }

  public loadProductTransitionMatrix(): void {
    if (_.isNil(this.workOrderForm?.productId) || this.actionType === ActionTypes.EDIT) {
      return;
    }

    const productTransitionMatrixParams: IGetProductTransitionMatrixParams = {
      siteId: this.productSiteFilter,
      lineId: this.lineId,
      fromWorkOrderId: this.previousWorkOrder?.id,
      fromObjectType: EProductTransitionMatrixFromToType.PRODUCT,
      fromObjectId:
        this.previousWorkOrder?.product?.id === undefined
          ? this.workOrderForm.productId
          : this.previousWorkOrder.product?.id,
      toObjectType: EProductTransitionMatrixFromToType.PRODUCT,
      toObjectId: this.workOrderForm.productId === undefined ? null : this.workOrderForm.productId,
    };

    this.store.dispatch(new HomeActions.GetProductTransitionMatrix(productTransitionMatrixParams));
  }

  public workOrderQuantityOrderedChanged(quantityOrdered: string): void {
    if (this.actionType === ActionTypes.EDIT) {
      return;
    }

    const productSpeedInHours: string = this.decimalHelper.multiply(
      this.productTransitionMatrix?.toProductExpectedSpeed,
      '60',
    );

    this.workOrderForm.quantityBasedRunDuration = this.decimalHelper.removeTrailingZeros(
      this.decimalHelper.toFixedValue(
        this.decimalHelper.divide(
          this.decimalHelper.sanitizeString(quantityOrdered),
          productSpeedInHours,
          DECIMAL_NUMERIC_SCALE,
        ),
        DECIMAL_NUMERIC_SCALE,
      ),
    );
  }
}
