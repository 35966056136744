import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { forkJoin, from, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ActivityTimelineService } from '../../../shared/service/reports/activity-timeline/activity-timeline.service';
import * as ActivityTimelineActions from '../../reports/activity-timeline/activity-timeline.actions';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../../shared/service/activity-history/activity-history.model';
import { ICurrentLine } from '../../line/model';
import { User } from '../../user/model';
import { ActivityTimelineInterface } from '../../../view/reports/activity-timeline/activity-timeline.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as AppActions from '../../app/actions';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { IObjectWithEntityTranslations } from '../../../shared/service/entity-translator/entity-translator.model';
import { IActivityTimeLineLineResponse } from './activity-timeline.model';

@Injectable()
export class ActivityTimelineEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly activityTimelineService: ActivityTimelineService,
    private translatorService: EntityTranslatorService,
  ) {}

  private timezone$: string;

  getActivityTimelineData = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTimelineActions.ActivityTimelineActionTypes.ActivityTimeLineDataLoading),
      switchMap((objectData: ActivityTimelineActions.ActivityTimeLineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((user: User) => {
            this.timezone$ = user.timezone;
          });

        return from(
          this.activityTimelineService.loadActivityHistoriesActivityTimeline(objectData.activityTimeline, 1, 1),
        ).pipe(
          switchMap((response: ResponseArrayInterface<IActivityHistory>) => {
            return of(
              new ActivityTimelineActions.ActivityTimeLineFetchData(objectData.activityTimeline, response.total),
            );
          }),
          catchError((error) => {
            return of(new ActivityTimelineActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  fetchData = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTimelineActions.ActivityTimelineActionTypes.ActivityTimeLineFetchData),
      switchMap((objectData: ActivityTimelineActions.ActivityTimeLineFetchData) => {
        const observables: Observable<
          | ActivityTimelineInterface[]
        | ResponseArrayInterface<IActivityTimeLineLineResponse>
          | ResponseArrayInterface<LineCRUDInterface>
          | ResponseArrayInterface<IActivityHistory>
        >[] = this.activityTimelineService.getActivityTimelineObservables(
          objectData.activityTimeline,
          objectData.totalCount,
        );

        return forkJoin(observables).pipe(
          switchMap(
            (
              response: (
                | ResponseArrayInterface<IActivityHistory>
                | ResponseArrayInterface<IActivityTimeLineLineResponse>
                | ResponseArrayInterface<LineCRUDInterface>
              )[],
            ) => {
              response.forEach(
                (
                  responseArray:
                    | ResponseArrayInterface<IActivityHistory>
                    | ResponseArrayInterface<ICurrentLine>
                    | ResponseArrayInterface<LineCRUDInterface>,
                ) => {
                  responseArray.data.forEach((responseObject: IActivityHistory | ICurrentLine | LineCRUDInterface) => {
                    this.translatorService.translate(responseObject);
                  });
                },
              );

              let activityHistories: IActivityHistory[] = [];
              let lines: IActivityTimeLineLineResponse[] = [];
              let allLines: LineCRUDInterface[] = [];

              response.map(
                (
                  value:
                    | ResponseArrayInterface<IActivityHistory>
                    | ResponseArrayInterface<IActivityTimeLineLineResponse>
                    | ResponseArrayInterface<LineCRUDInterface>,
                  index: number,
                ) => {
                  switch (index) {
                    case 0:
                      lines = value.data as IActivityTimeLineLineResponse[];
                      break;
                    case 1:
                      allLines = value.data as LineCRUDInterface[];
                      break;
                    default:
                      activityHistories = activityHistories.concat(value.data as IActivityHistory[]);
                  }
                },
              );

            const formattedActivityHistoryData: ActivityTimelineInterface[] = this.activityTimelineService.getModifiedActivityHistories(
              activityHistories,
              allLines,
            );
            const formattedLineData: ActivityTimelineInterface[] = this.activityTimelineService.getModifiedLines(
              lines,
              this.timezone$,
            );

              const concatData: ActivityTimelineInterface[] = formattedActivityHistoryData.concat(formattedLineData);

              return of(
                new ActivityTimelineActions.ActivityTimeLineDataLoaded(concatData),
                new AppActions.HideLoader(),
              );
            },
          ),
          catchError((error) => {
            return of(new ActivityTimelineActions.FetchError(error));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ActivityTimelineActions.FetchError(errorRes));
      }),
    ),
  );
}
