<scw-mat-form (scwMatFormSubmit)="saveInformation($event)">
  <div class="row fixed-modals-long">
    <div class="col-12 cannot-edit-message" *ngIf="workOrderForm.completed === 1 && actionType == 'edit'">
      {{ 'main.workOrder.cannotEditMessage' | translate }}
    </div>
    <div class="col-6">
      <scw-mat-input
        label="{{ 'main.workOrder.workOrderForm.workOrderNo.label' | translate }}*"
        [rules]="workOrderInformationInputRules.workOrderNumber"
        [disabled]="editableStatuses.workOrderNo.isDisabled"
        [(inputModel)]="workOrderForm.workOrderNo"
        data-automation-id="work-order-number-field"
      ></scw-mat-input>
    </div>
    <div class="col-6">
      <scw-mat-datepicker
        label="{{ 'main.workOrder.workOrderForm.workOrderDueDate.label' | translate }}"
        [rules]="workOrderInformationInputRules.workOrderDueDate"
        [disabled]="editableStatuses.woDueDate.isDisabled"
        [singleDatePicker]="true"
        [autoApply]="true"
        [showCustomRangeLabel]="false"
        [ranges]="null"
        [showClearButton]="true"
        [(inputModel)]="workOrderForm.formattedWoDueDate"
        [isCustomWeekStart]="true"
        [weekStartDay]="weekStartDay$"
        [timePicker]="true"
        data-automation-id="due-date-field"
      ></scw-mat-datepicker>
    </div>
    <div class="col-6">
      <scw-mat-picker
        label="{{ 'main.workOrder.workOrderForm.workOrderProduct.label' | translate }}*"
        [rules]="hasActivity ? [] : [{ required: true }]"
        [disabled]="editableStatuses.productId.isDisabled"
        [(inputModel)]="workOrderForm.productInfo"
        (onClick)="openProductModal(work_order_select_product)"
        (inputModelChange)="workOrderProductChanged()"
        data-automation-id="product-field"
      ></scw-mat-picker>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <scw-mat-input
            label="{{ 'main.workOrder.workOrderForm.quantityOrdered.label' | translate }}*"
            [rules]="workOrderInformationInputRules.quantityOrdered"
            [disabled]="editableStatuses.quantityOrdered.isDisabled"
            [(inputModel)]="workOrderForm.quantityOrdered"
            (inputModelChange)="workOrderQuantityOrderedChanged($event)"
            data-automation-id="ordered-quantity-field">
          </scw-mat-input>
        </div>
        <div class="col-6">
          <scw-mat-picker
            label="{{ 'main.workOrder.workOrderForm.productVersion.label' | translate }}{{ editableStatuses.productVersion.isDisabled ? '' : '*' }}"
            [rules]="workOrderInformationInputRules.productVersion" [disabled]="editableStatuses.productVersion.isDisabled"
            [(inputModel)]="workOrderForm.productVersionInfo" (onClick)="openProductVersionModal()"
            data-automation-id="product-version-field">
          </scw-mat-picker>
        </div>
      </div>
    </div>
    <div class="col-6">
      <scw-mat-select
        class="scw_mat-add_edit_modal-item-input"
        label="{{ 'main.workOrder.workOrderForm.job.label' | translate }}"
        searchBehavior="delayed"
        [disabled]="editableStatuses.jobId.isDisabled"
        [searchPreventSameSearchString]="false"
        [enableSearchResultList]="true"
        [data]="jobs$"
        labelKey="jobName"
        [singleSelection]="true"
        [enableSearchFilter]="true"
        [enableCheckAll]="false"
        [showCheckbox]="false"
        [badgeShowLimit]="1"
        [searchMaxLength]="45"
        [(inputModel)]="workOrderForm.jobSelect"
        createItemLabel="{{ 'job.create.dropdown.label' | translate }}"
        [createComponent]="true"
        [clearSearchOnOpen]="true"
        (onSearch)="onJobsSearch($event)"
        (onCreate)="onJobCreate($event)"
        #jobIdInput
      >
        <ng-template let-item>
          <div class="d-flex option-row">
            <label
              class="d-flex align-items-center mb-0 text-dark max-width-85"
              [ngClass]="item.disabled === true ? 'text-color-light-gray' : 'text-dark'"
            >{{ item.jobName }}</label
            >
          </div>
        </ng-template>
      </scw-mat-select>
    </div>
    <div class="col-6">
      <scw-mat-input
        [label]="'main.workOrder.workOrderForm.sequenceNumber.label' | translate"
        [rules]="workOrderInformationInputRules.sequenceNumber"
        [disabled]="editableStatuses.sequenceNumber.isDisabled"
        [(inputModel)]="workOrderForm.sequenceNumber"
      ></scw-mat-input>
    </div>
    <div class="col-6">
      <scw-mat-input
        [label]="'main.workOrder.workOrderForm.processOrder.label' | translate"
        [rules]="workOrderInformationInputRules.processOrder"
        [(inputModel)]="workOrderForm.processOrder"
      ></scw-mat-input>
    </div>
    <div class="col-6">
      <scw-mat-input
        [label]="'main.workOrder.workOrderForm.operationNumber.label' | translate"
        [rules]="workOrderInformationInputRules.operationNumber"
        [(inputModel)]="workOrderForm.operationNumber"
      ></scw-mat-input>
    </div>
    <div class="col-6">
      <label>{{ 'main.workOrder.workOrderForm.scheduledPhaseDuration.label' | translate }}</label>
      <div class="d-flex input-group">
        <div class="col-4 input-group-element">
          <scw-mat-input
            class="work-order-page-placeholder"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.preRun' | translate"
            [rules]="workOrderInformationInputRules.scheduledPreDuration"
            [disabled]="editableStatuses.scheduledPreDuration.isDisabled"
            [(inputModel)]="workOrderForm.scheduledPreDuration"
            [placeholder]="workOrderForm?.matrixBasedPreRunDuration"
          ></scw-mat-input>
        </div>
        <div class="col-4 input-group-element">
          <scw-mat-input
            class="work-order-page-placeholder"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.run' | translate"
            [rules]="workOrderInformationInputRules.scheduledRunDuration"
            [disabled]="editableStatuses.scheduledRunDuration.isDisabled"
            [(inputModel)]="workOrderForm.scheduledRunDuration"
            [placeholder]="workOrderForm?.quantityBasedRunDuration"
          ></scw-mat-input>
        </div>
        <div class="col-4 input-group-element">
          <scw-mat-input
            class="work-order-page-placeholder"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.postRun' | translate"
            [rules]="workOrderInformationInputRules.scheduledPostDuration"
            [disabled]="editableStatuses.scheduledPostDuration.isDisabled"
            [(inputModel)]="workOrderForm.scheduledPostDuration"
            [placeholder]="workOrderForm?.matrixBasedPostRunDuration"
          ></scw-mat-input>
        </div>
      </div>
    </div>
    <div class="col-6">
      <label>{{ 'main.workOrder.workOrderForm.scheduledLaborDuration.label' | translate }}</label>
      <div class="d-flex input-group">
        <div class="col-4 input-group-element">
          <scw-mat-input
            [disabled]="editableStatuses.scheduledPreRunLaborHours.isDisabled"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.preRun' | translate"
            [rules]="workOrderInformationInputRules.scheduledPreRunLaborHours"
            [(inputModel)]="workOrderForm.scheduledPreRunLaborHours"
          data-automation-id="scheduled-pre-run-labor-hours-field"></scw-mat-input>
        </div>
        <div class="col-4 input-group-element">
          <scw-mat-input
            [disabled]="editableStatuses.scheduledRunLaborHours.isDisabled"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.run' | translate"
            [rules]="workOrderInformationInputRules.scheduledRunLaborHours"
            [(inputModel)]="workOrderForm.scheduledRunLaborHours"
          data-automation-id="scheduled-run-labor-hours-field"></scw-mat-input>
        </div>
        <div class="col-4 input-group-element">
          <scw-mat-input
            [disabled]="editableStatuses.scheduledPostRunLaborHours.isDisabled"
            [label]="'main.workOrder.workOrderForm.scheduledLaborDuration.postRun' | translate"
            [rules]="workOrderInformationInputRules.scheduledPostRunLaborHours"
            [(inputModel)]="workOrderForm.scheduledPostRunLaborHours"
            data-automation-id="scheduled-post-run-labor-hours-field"
          ></scw-mat-input>
        </div>
      </div>
    </div>
  </div>

  <div class="select-wo-modal-footer">
    <scw-mat-button
      *ngIf="actionType === ActionTypes.EDIT"
      type="danger"
      (onClick)="confirmationModal(doYouWantToRemoveWorkOrder)"
      class="buttons-left"
      [ngClass]="[withoutWorkOrder && isOpenedFromHome ? 'buttons-left' : 'd-none']"
    >
      {{ 'doYouWantToRemoveWorkOrder.title' | translate }}
    </scw-mat-button>
    <scw-mat-button type="standard" (onClick)="cancel()" class="float-left m-r-24">
      {{ 'general.cancel' | translate }}
    </scw-mat-button>
    <scw-mat-button
      [isSubmitButton]="true"
      [className]="['float-right']"
      *ngIf="actionType === ActionTypes.CREATE && layoutType === layoutTypes.home"
      data-automation-id="save-work-order-btn"
    >
      {{ 'general.save' | translate }}
    </scw-mat-button>
    <div class="buttons-right">
      <scw-mat-button
        [isSubmitButton]="true"
        *ngIf="actionType == ActionTypes.EDIT"
        [type]="layoutType === layoutTypes.home ? 'cta' : 'standard'"
        [class.active]="layoutType === layoutTypes.workOrderSchedule"
        [disabled]="workOrderForm.completed === 1 || editWorkOrderInProgress"
        data-automation-id="save-work-order-btn"
      >
        {{ 'general.save' | translate }}
      </scw-mat-button>
    </div>
  </div>
</scw-mat-form>

<ng-template #work_order_select_product let-modal let-c="close" let-d="dismiss">
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'main.workOrder.selectProduct' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-spinner *ngIf="searchProductProgress"></app-spinner>
    <div class="row">
      <div class="col-sm-8">
        <h3 *ngIf="workOrderForm.productId" class="m-b-15">
          {{ workOrderForm.productName + ' - ' + workOrderForm.productDescription }}
        </h3>
      </div>
      <div class="col-sm-12">
        <scw-mat-search
          behavior="delayed"
          placeholder="{{ 'main.workOrder.searchProduct' | translate }}"
          [minLength]="0"
          [maxLength]="255"
          [(inputModel)]="productSearchText"
          (onSearch)="searchRequest($event)"
        ></scw-mat-search>
      </div>
    </div>
    <div
      class="row lazy-loading-results m-t-20"
      infiniteScroll
      [infiniteScrollDistance]="0"
      [infiniteScrollThrottle]="0"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
    >
      <div *ngFor="let product of products" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 m-b-15">
        <scw-mat-button
          type="standard"
          size="xl"
          [block]="true"
          [className]="['button-group']"
          (onClick)="selectProduct(product)"
        >
          <h5 class="product-button-text product-button-product-text">{{ product.productId }}</h5>
          <div class="product-button-text product-button-info-text">
            {{ product.description }}
          </div>
        </scw-mat-button>
      </div>
      <div *ngIf="lazyLoadingProgress" class="lazy-loading">
        <svg id="loader2" viewBox="0 0 100 100">
          <circle id="circle-loader2" class="stroke-white loader-primary" cx="50" cy="50" r="45"></circle>
        </svg>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.back' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<app-work-order-product-version-modal
  [productID]="selectedProduct.id ?? null"
  [modalHeader]="productVersionModalHeader ?? undefined"
  (selectedProductVersionEvent)="onProductVersionSelectionChanged($event)"
  #work_order_select_product_version_modal
></app-work-order-product-version-modal>

<ng-template #doYouWantToRemoveWorkOrder let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'doYouWantToRemoveWorkOrder.title' | translate }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'doYouWantToRemoveWorkOrder.description' | translate: { workOrderName: workOrderNo } }}
        </h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button type="danger" (onClick)="removeWorkOrder()">
        {{ 'general.remove' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
