import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { OfflineSensorStatusDataInterface, OfflineSensorStatusResponseInterface } from './offline-sensor-status.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineSensorStatusService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  public getOfflineSensorStatus(lineId: number): Observable<OfflineSensorStatusResponseInterface> {
    const observables = {
      fromEnvUrl: this.http.get<OfflineSensorStatusResponseInterface>(
        environment.sensorStatusUrl.replace('{{lineId}}', lineId.toString()),
      ),
      fromDfApi: this.http.get<OfflineSensorStatusResponseInterface>(`${this.baseUrl}/lines/${lineId}/sensor-statuses`),
    };
    return forkJoin(observables).pipe(
      map(({ fromDfApi, fromEnvUrl }) => {
        const formattedData = fromDfApi.data.map((apiSensor: OfflineSensorStatusDataInterface) => {
          if (apiSensor.offlineStatus === null) {
            return fromEnvUrl.data.find((envSensor) => apiSensor.sensorId === envSensor.sensorId) ?? apiSensor;
          }

          return apiSensor;
        });

        return { ...fromDfApi, data: formattedData };
      }),
    );
  }
}
