import { SchedulerActions, SchedulerActionTypes } from './scheduler.actions';
import { ScenarioItemInterface, ScheduleStateInterface } from './scheduler.model';
import * as _ from 'lodash';
import { HelperService } from '../../shared/service/helper.service';

export const schedulerState: ScheduleStateInterface = {
  unplannedWorkOrders: [],
  scenario: null,
  scenarioItems: null,
  deployedItems: null,
  actualItems: null,
  uncreatedWorkOrders: null,
  scenarioResources: {},
  plannedDownTimeActivities: [],
  scenarioLoading: false,
  scenarioLoaded: false,
  scenarioItemsLoading: false,
  scenarioItemsLoaded: false,
  isScenarioItemsLoadedToGanttChart: false,
  applyData: {
    scenarioId: null,
    horizon: null,
    resourceId: null,
    workOrderFilters: null,
  },
  scenarioItemsSaveLoading: false,
  scenarioItemsSaveLoaded: false,
  scenarioItemsDeployLoading: false,
  scenarioItemsDeployLoaded: false,

  capacityUtilizationKpi: [],
  capacityUtilizationKpiLoaded: false,
  capacityUtilizationKpiLoading: false,
  scheduledQualityKpi: [],
  scheduledQualityKpiLoaded: false,
  scheduledQualityKpiLoading: false,
  workOrderKpi: [],
  workOrderKpiLoaded: false,
  workOrderKpiLoading: false,
  errorKpi: [],
  errorKpiLoaded: false,
  errorKpiLoading: false,
  scenarioGanttData: null,
  fromDate: null,
  lastFromDate: null,
  toDate: null,
  lastToDate: null,
  showApplyButton: false,
  downTimeActivities: [],
  unplannedWorkOrdersIsLoading: false,
  downTimeActivitiesIsLoading: false,
  unplannedWorkOrdersIsLoaded: false,
  downTimeActivitiesIsLoaded: false,
  scenarioPrepared: false,
  ganttFilterInitiated: false,
  shouldShowRefreshButton: false,
  scenarioPlansLoading: false,
  scenarioPlansLoaded: false,
  scenarioShiftPlans: [],
  scenarioWorkOrderRulesLoading: false,
  scenarioWorkOrderRulesLoaded: false,
  scenarioAdvancedFilterLoading: false,
  scenarioAdvancedFilterLoaded: false,
  workOrderModalIsOpen: false,
  checkWorkOrderLoading: false,
  checkWorkOrderLoaded: false,
  kpiMetricInfoLoading: false,
  kpiMetricInfoLoaded: false,
  kpiMetricInfo: null,
  shouldSaveKpiCardData: false,
  kpiCardData: null,
  schedulerOptimizationsLoading: false,
  schedulerOptimizationsLoaded: false,
  isShowSchedulerOptimizationMode: false,
  schedulerAllOptimizationsData: null,
  isOnHoldSaveKpiCardData: false,
  viewStatus: 'gantt-view',
  isSavingSchedulerKpiCardData: false,
};

export function schedulerReducer(
  state: ScheduleStateInterface = schedulerState,
  action: SchedulerActions,
): ScheduleStateInterface {
  let buttonStatus;

  switch (action.type) {
    case SchedulerActionTypes.LoadScenario:
      return {
        ...state,
        scenarioLoading: true,
        scenarioLoaded: false,
      };

    case SchedulerActionTypes.ScenarioLoaded:
      return {
        ...state,
        scenario: action.payload.data,
        scenarioLoading: false,
        scenarioLoaded: true,
        ganttFilterInitiated: false,
        scenarioPrepared: false,
      };

    case SchedulerActionTypes.ClearSelectedScenarioData:
      return {
        ...state,
        scenario: null,
        scenarioLoading: true,
        scenarioLoaded: false,
      };

    case SchedulerActionTypes.LoadScenarioItems:
      return {
        ...state,
        scenarioItemsLoading: true,
        scenarioItemsLoaded: false,
        isScenarioItemsLoadedToGanttChart: false,
      };

    case SchedulerActionTypes.ScenarioItemsLoaded:
      const scenarioItems: ScenarioItemInterface[] = action.payload.scenarioItems.data.filter(
        (item: ScenarioItemInterface) => {
          return item.scenarioId !== null && item.id > 0;
        },
      );

      const deployedItems: ScenarioItemInterface[] = action.payload.scenarioItems.data.filter(
        (item: ScenarioItemInterface) => {
          return item.scenarioId === null && item.id > 0;
        },
      );

      const actualItems: ScenarioItemInterface[] = action.payload.scenarioItems.data.filter(
        (item: ScenarioItemInterface) => {
          return item.id < 0;
        },
      );
      return {
        ...state,
        scenarioItems,
        deployedItems,
        actualItems,
        uncreatedWorkOrders: action.payload.uncreatedWorkOrders.data,
        scenarioItemsLoading: false,
        scenarioItemsLoaded: true,
      };

    case SchedulerActionTypes.ScenarioItemsLoadedToGanttChart:
      return {
        ...state,
        isScenarioItemsLoadedToGanttChart: action.isLoaded,
      };

    case SchedulerActionTypes.UpdateApplyData:
      return {
        ...state,
        applyData: { ...HelperService.cloneDeep(state.applyData), ...HelperService.cloneDeep(action.applyData) },
      };

    case SchedulerActionTypes.ClearApplyData:
      return {
        ...state,
        applyData: {
          ...HelperService.cloneDeep(state.applyData),
          ...{
            horizon: null,
            resourceId: null,
            workOrderFilters: null,
          },
        },
      };

    case SchedulerActionTypes.SaveScenarioItems:
      return {
        ...state,
        scenarioItemsSaveLoading: true,
        scenarioItemsSaveLoaded: false,
      };

    case SchedulerActionTypes.ScenarioItemsSaved:
      return {
        ...state,
        scenarioItemsSaveLoading: false,
        scenarioItemsSaveLoaded: true,
        shouldSaveKpiCardData: true,
        kpiCardData: null,
      };

    case SchedulerActionTypes.DeployScenarioItems:
      return {
        ...state,
        scenarioItemsDeployLoading: true,
        scenarioItemsDeployLoaded: false,
      };

    case SchedulerActionTypes.ScenarioItemsDeployed:
      return {
        ...state,
        scenarioItemsDeployLoading: false,
        scenarioItemsDeployLoaded: true,
        shouldSaveKpiCardData: true,
        kpiCardData: null,
      };

    case SchedulerActionTypes.UpdateFromDate:
      buttonStatus = state.lastFromDate !== action.payload;
      return {
        ...state,
        fromDate: action.payload,
        showApplyButton: buttonStatus,
      };

    case SchedulerActionTypes.UpdateToDate:
      buttonStatus = state.lastToDate !== action.payload;
      return {
        ...state,
        toDate: action.payload,
        showApplyButton: buttonStatus,
      };

    case SchedulerActionTypes.HideShowApplyButton:
      return {
        ...state,
        lastFromDate: state.fromDate,
        lastToDate: state.toDate,
        showApplyButton: action.payload,
      };

    case SchedulerActionTypes.LoadCapacityUtilizationData:
      return {
        ...state,
        capacityUtilizationKpiLoading: true,
      };

    case SchedulerActionTypes.CapacityUtilizationDataLoaded:
      return {
        ...state,
        capacityUtilizationKpiLoading: false,
        capacityUtilizationKpiLoaded: true,
        capacityUtilizationKpi: action.payload,
      };

    case SchedulerActionTypes.LoadScheduledQualityData:
      return {
        ...state,
        scheduledQualityKpiLoading: true,
      };

    case SchedulerActionTypes.ScheduledQualityDataLoaded:
      return {
        ...state,
        scheduledQualityKpiLoading: false,
        scheduledQualityKpiLoaded: true,
        scheduledQualityKpi: action.payload,
      };

    case SchedulerActionTypes.LoadWorkOrderData:
      return {
        ...state,
        workOrderKpiLoading: true,
      };

    case SchedulerActionTypes.WorkOrderDataLoaded:
      return {
        ...state,
        workOrderKpiLoading: false,
        workOrderKpiLoaded: true,
        workOrderKpi: action.payload,
      };

    case SchedulerActionTypes.LoadErrorData:
      return {
        ...state,
        errorKpiLoading: true,
      };

    case SchedulerActionTypes.ErrorDataLoaded:
      return {
        ...state,
        errorKpiLoading: false,
        errorKpiLoaded: true,
        errorKpi: action.payload,
      };

    case SchedulerActionTypes.LoadScenarioGanttData:
      let ganttData = state.scenarioGanttData;
      ganttData = {
        ...ganttData,
        isGanttDataLoaded: false,
        isGanttDataLoading: true,
      };
      return {
        ...state,
        scenarioGanttData: ganttData,
      };

    case SchedulerActionTypes.ScenarioGanttDataLoaded:
      return {
        ...state,
        scenarioGanttData: {
          columns: action.columns,
          resources: action.resources,
          events: action.events,
          startDate: action.startDate,
          endDate: action.endDate,
          viewPreset: action.viewPreset,
          groupFeature: action.groupFeature,
          isGanttDataLoaded: true,
          isGanttDataLoading: false,
        },
      };

    case SchedulerActionTypes.LoadDownTimeActivitiesData:
      return {
        ...state,
        downTimeActivitiesIsLoading: true,
        downTimeActivitiesIsLoaded: false,
      };

    case SchedulerActionTypes.LoadUnplannedWorkOrderData:
      return {
        ...state,
        unplannedWorkOrdersIsLoading: true,
        unplannedWorkOrdersIsLoaded: false,
      };

    case SchedulerActionTypes.DownTimeActivitiesDataLoaded:
      return {
        ...state,
        downTimeActivitiesIsLoaded: true,
        downTimeActivitiesIsLoading: false,
        downTimeActivities: action.payload,
      };

    case SchedulerActionTypes.UnplannedWorkOrderDataLoaded:
      return {
        ...state,
        unplannedWorkOrdersIsLoading: false,
        unplannedWorkOrdersIsLoaded: true,
        unplannedWorkOrders: action.payload,
      };

    case SchedulerActionTypes.ScenarioPrepared:
      return {
        ...state,
        scenarioPrepared: action.status,
      };

    case SchedulerActionTypes.ClearSchedulerData:
      return {
        ...state,
        downTimeActivitiesIsLoading: false,
        downTimeActivitiesIsLoaded: false,
        scenarioItemsLoading: false,
        scenarioItemsLoaded: false,
        isScenarioItemsLoadedToGanttChart: false,
        ganttFilterInitiated: false,
        scenarioItems: null,
        uncreatedWorkOrders: null,
        scenarioGanttData: {
          ...state.scenarioGanttData,
          isGanttDataLoading: false,
          isGanttDataLoaded: false,
        },
      };
    case SchedulerActionTypes.GanttWorkOrderFilterInitiated:
      return {
        ...state,
        ganttFilterInitiated: true,
      };

    case SchedulerActionTypes.UpdateScenarioDetails:
      return {
        ...state,
        scenario: {
          ...state.scenario,
          ...action.details,
        },
      };

    case SchedulerActionTypes.ShouldShowRefreshButton:
      return {
        ...state,
        shouldShowRefreshButton: action.shouldShowRefreshButton,
      };

    case SchedulerActionTypes.LoadScenarioShiftPlans:
      return {
        ...state,
        scenarioPlansLoading: true,
        scenarioPlansLoaded: false,
      };

    case SchedulerActionTypes.ScenarioShiftPlansLoaded:
      return {
        ...state,
        ...{
          scenarioShiftPlans: action.payload,
        },
        scenarioPlansLoading: false,
        scenarioPlansLoaded: true,
      };

    case SchedulerActionTypes.AddScenarioWorkOrderRules:
      return {
        ...state,
        scenarioWorkOrderRulesLoading: true,
        scenarioWorkOrderRulesLoaded: false,
      };

    case SchedulerActionTypes.ScenarioWorkOrderRulesAdded:
      return {
        ...state,
        scenarioWorkOrderRulesLoading: false,
        scenarioWorkOrderRulesLoaded: true,
      };

    case SchedulerActionTypes.AddScenarioAdvancedFilter:
      return {
        ...state,
        scenarioAdvancedFilterLoading: true,
        scenarioAdvancedFilterLoaded: false,
      };

    case SchedulerActionTypes.ScenarioAdvancedFilterAdded:
      return {
        ...state,
        scenario: {
          ...action.payload.data,
        },
        scenarioLoading: false,
        scenarioLoaded: true,
        scenarioAdvancedFilterLoading: false,
        scenarioAdvancedFilterLoaded: true,
      };

    case SchedulerActionTypes.CheckAddWhiteWorkOrder:
      return {
        ...state,
        checkWorkOrderLoading: true,
        checkWorkOrderLoaded: false,
      };

    case SchedulerActionTypes.AddWhiteWorkOrderChecked:
      return {
        ...state,
        checkWorkOrderLoading: false,
        checkWorkOrderLoaded: true,
      };

    case SchedulerActionTypes.OpenWorkOrdersModal:
      return {
        ...state,
        workOrderModalIsOpen: true,
      };

    case SchedulerActionTypes.CloseWorkOrdersModal:
      return {
        ...state,
        workOrderModalIsOpen: false,
      };

    case SchedulerActionTypes.ClearUnplannedWorkOrderData:
      return {
        ...state,
        unplannedWorkOrdersIsLoading: false,
        unplannedWorkOrdersIsLoaded: false,
        unplannedWorkOrders: [],
      };

    case SchedulerActionTypes.LoadSchedulerOptimizations:
      return {
        ...state,
        schedulerOptimizationsLoading: true,
        schedulerOptimizationsLoaded: false,
        schedulerAllOptimizationsData: null,
      };

    case SchedulerActionTypes.SchedulerOptimizationsLoaded:
      return {
        ...state,
        ...{
          schedulerAllOptimizationsData: action.payload,
        },
        schedulerOptimizationsLoading: false,
        schedulerOptimizationsLoaded: true,
      };

    case SchedulerActionTypes.KpiMetricInfoLoad:
      return {
        ...state,
        ...{
          kpiMetricInfoLoading: true,
          kpiMetricInfoLoaded: false,
        },
      };

    case SchedulerActionTypes.KpiMetricInfoLoaded:
      return {
        ...state,
        ...{
          kpiMetricInfoLoading: false,
          kpiMetricInfoLoaded: true,
          kpiMetricInfo: action.payload,
        },
      };

    case SchedulerActionTypes.SaveSchedulerKpiCardData:
      return {
        ...state,
        ...{
          shouldSaveKpiCardData: false,
          kpiCardData: null,
          isOnHoldSaveKpiCardData: false,
          isSavingSchedulerKpiCardData: true,
        },
      };

    case SchedulerActionTypes.PartialUpdateKpiCardData:
      const kpiCardData = _.cloneDeep(state.kpiCardData) ?? {};
      kpiCardData.components = {
        ...kpiCardData.components,
        [action.payload.component]: true,
      };
      kpiCardData.properties = {
        ...kpiCardData.properties,
        ...action.payload.properties,
      };

      return {
        ...state,
        ...{
          kpiCardData,
        },
      };

    case SchedulerActionTypes.SetViewStatus:
      return {
        ...state,
        viewStatus: action.viewStatus,
      };

    case SchedulerActionTypes.ShowSchedulerOptimizationMode:
      return {
        ...state,
        isShowSchedulerOptimizationMode: action.isShowSchedulerOptimizationMode,
      };

    case SchedulerActionTypes.OnHoldSaveKpiCardData:
      return {
        ...state,
        isOnHoldSaveKpiCardData: action.isOnHoldSaveKpiCardData,
      };

    case SchedulerActionTypes.SchedulerKpiCardDataSaved:
      return {
        ...state,
        isSavingSchedulerKpiCardData: false,
        scenario: {
          ...HelperService.cloneDeep(state.scenario),
          ...action.payload.data,
        },
      };

    default:
      return state;
  }
}
